import React, { useCallback, useEffect, useRef, useState } from 'react'
import Apiconnect from '../../services/Apiconnect';
import { GoogleMap, Marker, useJsApiLoader, InfoBox, StandaloneSearchBox, LoadScript } from '@react-google-maps/api';
import Geocode from "react-geocode";
import Modal from 'react-modal';
import Pagination from "react-js-pagination";
import {HiLocationMarker} from 'react-icons/hi';
import FullScreenLoading from 'react-fullscreen-loading';
import { useAlert } from "react-alert";

const Manage_attendance_address = () => {

    // const { isLoaded } = useJsApiLoader({id: 'google-map-script', googleMapsApiKey: 'AIzaSyDYhFbq3EBeezplKWoyD7BYBjLEFJ6cEUE'});
    const [defaultLocation, setDefaultLocation] = useState("Bhubaneswar")
    const [coordinates, setCoordinates] = useState({lat: 20.296059, lng: 85.824539})
    const [address, setAddress] = useState("")
    const [name, setName] = useState("")
    const markerRef = useRef(null);
    const inputRef = useRef()
    const alert = useAlert(); 

    function onDragEnd(...args) {
        setCoordinates({lat: markerRef.current.position.lat(), lng: markerRef.current.position.lng()});
      }

    const onMarkerLoad = useCallback(
        marker => {
            markerRef.current = marker;
            console.log(marker);
        },
        [onDragEnd]
    );

    const handlePlaceChanged = () => { 
        const [ place ] = inputRef.current.getPlaces();
        if(place) { 
            setCoordinates({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()});
        } 
    }
    const [ libraries ] = useState(['places']);

    Geocode.setApiKey("AIzaSyDYhFbq3EBeezplKWoyD7BYBjLEFJ6cEUE")
    Geocode.fromLatLng(String(coordinates.lat), String(coordinates.lng)).then(
        (response) => {
        setAddress(response.results[0].formatted_address);
        },
        (error) => {
            console.error(error);
        }
        );



        /* ======================employee list==================== */

        const [list, setList] = useState([])
    const [empStatus, setEmpStatus] = useState("");
    const [dept_id, setDept_id] = useState(null);
    const [grp_id, setGrp_id] = useState("")
    const [searchTerm, setSearchTerm] = useState('')
    const [fullScreenLoader, setFullScreenLoader] = useState(false);

    const getEmpList = () => {
        setFullScreenLoader(true)
        const inf_a = {search: "", dept_id: "", empStatus: "", group_id: "", company_id: Apiconnect.get_company_id(), limit: 50, offset: 0}
        Apiconnect.postData(`employee_management/get_employee_lists`, inf_a).then(res => {
          let _xtract = Apiconnect.decrypt_obj(res.data.data)
          setFullScreenLoader(false)
          setList(_xtract)
          let ob = {}
          _xtract.map((val,key) => {
            if(val.lpemp_emp_id === val.id){
              ob[val.lpemp_emp_id] = val.lpemp_emp_id
            }
          })
          // setSelectedEmp(ob)
        })
    }
    useEffect(() => {getEmpList()}, [])
    // console.log(list)

  
/* cat list */
    const [catList, setCategory] = useState([]);
    const getCategory = () => {
      var company_id = Apiconnect.get_company_id();
      setFullScreenLoader(true)
      Apiconnect.getData(`company_category/getall/${company_id}`).then(
        (response) => {
          let _xtract = Apiconnect.decrypt_obj(response.data.data);
          setCategory(_xtract.reverse());
          setFullScreenLoader(false)
        }
      );
    };
    useEffect(() => {getCategory()}, [])

  /* grp list */

  const [grp, setGrp] = useState([])
  const getAll = async () => {
    const inf_c = {company_id : Apiconnect.get_company_id()};
    await Apiconnect.postData(`leave_manage/leave_group_list`, inf_c).then(res => setGrp(res.data.data)) 
}
  useEffect(() => {getAll()}, [])

  /* -----------------------Pagination------------------------ */
  const [activePage, setActivePage] = useState(1);
  
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    let offset = (pageNumber - 1) * 50;
    let limit = 50;
    getEmployeeList_new(limit, offset, "", dept_id, empStatus, grp_id);
  };

  const getEmployeeList_new = (limit, offset, search, dept_id, empStatus, grp_id) => {
    const data = {
      limit: limit,
      offset: offset,
      search: search,
      company_id: Apiconnect.get_company_id(),
      dept_id:dept_id,
      empStatus:empStatus,
      group_id: grp_id
    };
    setFullScreenLoader(true)
    Apiconnect.postData(`employee_management/get_employee_lists`, data).then(
      (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setList(_xtract);
        setFullScreenLoader(false)
        let ob = {}
          _xtract.map((val,key) => {
            if(val.lpemp_emp_id === val.id){
              ob[val.lpemp_emp_id] = val.lpemp_emp_id
            }
          })
      }
    );
  };
  

  const [empCount, setempCount] = useState(0);
  const getEmpCount = (search,dept_id,empStatus) => {

    var dept_id1 = '';
    if(dept_id != '' && typeof dept_id != 'undefined' && dept_id != null){
       dept_id1 = dept_id;
    }

    const data = {
      search: search,
      company_id: Apiconnect.get_company_id(),
      dept_id:dept_id1,
      empStatus:empStatus
    };
    
    Apiconnect.postData(
      `employee/getEmpSearchcount`,data
    ).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setempCount(_xtract[0].cnt);
    });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      
      let offset = (activePage - 1) * 50;
      let limit = 50;
      let group_id = ""
     
      getEmployeeList_new(limit, offset, searchTerm, dept_id,empStatus, grp_id);

      var dept_id1 = '';
      if(dept_id != '' && typeof dept_id != 'undefined' && dept_id != null){
         dept_id1 = dept_id;
      }
  
      var dept_id1 = '';
      const data = {
        search: searchTerm,
        company_id: Apiconnect.get_company_id(),
        dept_id:dept_id1,
        empStatus:empStatus
      };
  
      Apiconnect.postData(
        `employee/getEmpSearchcount`,data
      ).then((response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setempCount(_xtract[0].cnt);
      });
  
    }, 2000)
  
    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])
  /*-------------------------------pagination end------------------------- */
  
//select dept
  const onDeptChange = (e) => {
    let dept_id = e.target.value;
    setDept_id(dept_id);
    getEmployeeList_new("50", "0", "", dept_id, empStatus, grp_id);
    getEmpCount('',dept_id,empStatus)
  };
//select grp
  const onGrpChange = (e) => {
    let grp_id = e.target.value;
    setGrp_id(grp_id)
    getEmployeeList_new("50", "0", "", dept_id, empStatus, grp_id);
    getEmpCount('',dept_id,empStatus)
  }
//select status
  const onStatusChange = (e) => {
    let empStatus = e.target.value;
    setEmpStatus(empStatus)
    getEmployeeList_new("50", "0", "", dept_id, empStatus, grp_id);
    getEmpCount('',dept_id,empStatus)
  }
  /* ---------------------------employee select------------------------------ */
  const [selectedEmp, setSelectedEmp] = useState([]);
  const onInputChange2 = (e) => {
    setallchecked(null);
    if (e.target.checked === true) {
      setSelectedEmp({
        ...selectedEmp,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    } else {
      setSelectedEmp({ ...selectedEmp, [e.currentTarget.name]: 0 }); 
    }
  };

  const [allchecked, setallchecked] = useState(null);

  const sellectAll = (e) => {
    console.log(e.target.checked);
    if (e.target.checked === true) { 
      setallchecked("checked");
      let ob = {};
      list.map((val, key) => {
        ob[val.id] = val.id;
      });
      setSelectedEmp(ob);
    } else {
      setallchecked(null);
      setSelectedEmp([]);
    }
  };


  /* =============Modal========== */
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const customStyles = {
    content: {
       top: "15%",
       width: "60%",
       left: "21%",
    }
 };

 const applyTemplate = () => {
    setModalIsOpen(true)
 }

const handleAssign = () => {
    let info = {employee_id:selectedEmp, address:address, latitude:coordinates.lat, longitude:coordinates.lng}
    if(info.employee_id.length === 0){
        alert.error("Please Select Employee")
    }else{
        Apiconnect.postData(`emp_default_punch_location/create`, info).then((res) => {
            console.log("Created Successfully")
            getEmpPunchLocation()
            setSelectedEmp([]);
            setModalIsOpen(false)
            alert.success("Employee Assigned Successfully")
        }).catch(err => console.log(err))
    }
}

const [empList, setEmpList] = useState([])
const getEmpPunchLocation = () => {
    setFullScreenLoader(true)
    Apiconnect.postData(`emp_default_punch_location/getall`).then(res => {
        setEmpList(res.data.data)
        setFullScreenLoader(false)
    }).catch(err => console.log(err))
}
useEffect(() => {getEmpPunchLocation()}, [])

console.log(empList)

const handleDelete = (id) => {
    setFullScreenLoader(true)
    Apiconnect.deleteData(`emp_default_punch_location/delete/${id}`).then(res => {
        console.log("Deleted Successfully")
        getEmpPunchLocation()
        setFullScreenLoader(false)
        alert.success("Deleted")
    })
}

/* ===========location modal============= */
const { isLoaded } = useJsApiLoader({
    id: 'google-map-script', googleMapsApiKey: 'AIzaSyDYhFbq3EBeezplKWoyD7BYBjLEFJ6cEUE'
 });

const [modalIsOpen2, setModalIsOpen2] = useState(false);
const [lat, setLat] = useState(0)
const [lng, setLng] = useState(0)
const [ad, setAd] = useState('')

const applyTemplate2 = (la, ln, add) => {
    setModalIsOpen2(true)
    setLat(la)
    setLng(ln)
    setAd(add)
}

const customStyles2 = {
    content: {
       marginTop: "50px",
       top: '50%', width: '40%',
       left: '50%',
       right: 'auto',
       bottom: 'auto',
       marginRight: '-50%',
       transform: 'translate(-50%, -50%)',
       height:'60%',
    },
 };


  return (
    <>
        <div id="content-page" className="content-page">
            <div className="container-fluid">
            {fullScreenLoader
                    ? (
                    <FullScreenLoading loading loaderColor="#3498db" />
                    )
                    
                    : null
                    }
                <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                <div className="row">
          <div className="col-sm-12 col-lg-12 col-md-12">
            <div className="iq-card">
                <div className="iq-card-body">
                <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Department</label>
                        <select className="form-control" 
                          required
                          name="dept_id"
                          value={dept_id}
                          onChange={(e) => onDeptChange(e)}
                        >
                          <option value="" disabled selected>Department</option>
                          {catList.map((val, key) => {
                            return (
                              <option key={key} value={val.id}>
                                {val.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Group</label>
                        <select className="form-control" name="grp" value={grp_id} onChange={e => onGrpChange(e)}>
                          <option value="" disabled selected>Group</option>
                          {grp.map((val, key) => {
                              return(
                                <option key={key} value={val.leavegroup_pk_id}>{val.leavegroup_name}</option>
                              )
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Status</label>
                        <select  className="form-control" onChange={e => onStatusChange(e)}>
                          <option value="" disabled selected>status</option> 
                          <option value="1" >Active</option> 
                          <option value="0" >Inactive</option> 
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-md-3 d-flex mt-5">
                        <input autocomplete="off" className="form-control d-flex end" style={{textAlign: "center"}} placeholder='Search Employee..' onChange={(e) => setSearchTerm(e.target.value)}/>
                    </div>
                    <div className="col-md-5 d-flex mt-5" >
                      <button className="btn btn-success" onClick={() => handleAssign()}>Assign</button>
                    </div>
                  </div>
                  <div className="row">
                      <table  className="table table-striped  mt-4 center grid" role="grid" >
                          <thead className="">
                            <tr className="clr">
                              <th> 
                                <input
                                  type="checkbox"
                                  name="select_all"
                                  checked={allchecked}
                                  onChange={(e) => sellectAll(e)} 
                                />
                              </th>
                              <th scope="col">#</th>
                              <th scope="col">Profile</th>
                              <th scope="col">Name</th>
                            </tr>
                          </thead>
                          <tbody>
                              {list.map((val, key) => {

                                  if (val.display_picture == null) {
                                    var profile_img = "/images/user/1.jpg";
                                  } else {
                                    const min = 1;
                                    const max = 1000;
                                    var rand = min + Math.random() * (max - min);
                                    var profile_img = process.env.React_App_BASE_URL_API + "/uploads/thumbdp/" + "?" + rand;
                                  }

                                  var ck = Object.keys(selectedEmp).find(function (element) {
                                    return selectedEmp[element] == val.id;
                                    });  
                                    var ck1 = "";
                                    if (ck !== undefined) {
                                        ck1 = true;
                                    }
                                return(
                                  <tr key={key}>
                                    <th scope="row"><input type="checkbox" name={val.id} value={val.id} checked={ck1} onChange={(e) => onInputChange2(e)}/></th>    
                                    <th scope="row">{key + 1}</th>
                                    <td style={{ width: "130px", textAlign: "center" }}>
                                      <div className="" style={{ display: "grid" }}>
                                        <img src={profile_img} alt="profile-img" className="prof"/>
                                      </div>
                                    </td>
                                    <td>{val.first_name} {val.last_name}</td>
                                  </tr>
                                )
                              })}
                          </tbody>
                      </table>
                      <Pagination
                      activePage={activePage}
                      itemsCountPerPage={50}
                      totalItemsCount={empCount}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                    />
                    </div>
                </div>
            </div>
          </div>
          </div>
                </Modal>

                <Modal isOpen={modalIsOpen2} style={customStyles2} onRequestClose={() => setModalIsOpen2(false)}>
                    <div className="iq-card">
                     <div className='row'>
                        <div className='col-md-12'>
                           {
                              isLoaded ? (
                                  <GoogleMap
                                    mapContainerStyle={{width: '100%',height: '500px'}}
                                    center={{lat: parseFloat(lat), lng: parseFloat(lng)}}
                                    zoom={14}
                                    // onClick={handleMapClick}
                                  >
                                    <Marker position={{lat: parseFloat(lat), lng: parseFloat(lng)}} title={ad} />
                                    <InfoBox position={{lat: parseFloat(lat), lng: parseFloat(lng)}}>
                                       <div class="card bg-light" style={{padding: "1px"}}>
                                          <div class="card-body" style={{width: "18rem"}}>
                                             {ad}
                                          </div>
                                       </div>
                                    </InfoBox>
                                  </GoogleMap>
                                ) : <></>
                           }
                        </div>
                     </div>
                  </div>
                </Modal>
                <div className="row">
                    <div className="col-sm-12 col-lg-12 col-md-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div style={{ float: "left" }} className="iq-header-title">
                                    <h4 className="card-title">Manage Attendance Address</h4>
                                </div>
                            </div>
                            <div className='row m-2 p-2'>
                              <div className='col-md-6'>
                                    <LoadScript googleMapsApiKey="AIzaSyDYhFbq3EBeezplKWoyD7BYBjLEFJ6cEUE" libraries={libraries}>
                                        <StandaloneSearchBox onLoad={ref => inputRef.current = ref} onPlacesChanged={handlePlaceChanged}>
                                            <input type="text" className='form-control' placeholder="Enter Location" value={defaultLocation} onChange={(e) => setDefaultLocation(e.target.value)}/>
                                        </StandaloneSearchBox>
                                        <GoogleMap
                                        mapContainerStyle={{width: "100%", height: "300px", margin: "5px"}}
                                        center={coordinates}
                                        zoom={14}
                                        // onClick={handleMapClick}
                                        >
                                            
                                            <Marker position={{lat: parseFloat(coordinates.lat), lng: parseFloat(coordinates.lng)}} draggable onDragEnd={onDragEnd}  onLoad={onMarkerLoad}/>
                                        </GoogleMap>
                                    </LoadScript>
                                </div>
                                <div className='col-md-6 bbox'>
                                    <div className='row'>
                                        <div className='col-md-12 form-group'>
                                            <label>Address</label>
                                            <input className='form-control' value={address}/>
                                        </div>
                                        <div className='col-md-12 form-group'>
                                            <div className='row'>
                                                <div className='col-md-6 form-group'>
                                                    <label>Latitude</label>
                                                    <input className='form-control' value={coordinates.lat}/>
                                                </div>
                                                <div className='col-md-6 form-group'>
                                                    <label>Longitude</label>
                                                    <input className='form-control' value={coordinates.lng}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 form-group'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <button type="button" className="btn btn-info" onClick={() => applyTemplate()}>Assign Employee</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row m-2'>
                                <div className='col-md-12'>
                                <table  className="table table-striped table-bordered mt-1 center" role="grid" >
                                    <thead className="">
                                        <tr className="clr">
                                            <th scope="col">#</th>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col">Employee Code</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">View</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            empList.map((val, key) => {
                                                return(
                                                    <tr>
                                                        <td>{key + 1}</td>
                                                        <td>{val.emp_name}</td>
                                                        <td>{val.emp_code}</td>
                                                        <td>{val.address}</td>
                                                        <td>
                                                            <button className='btn btn-sm btn-info' onClick={() => applyTemplate2(val.latitude, val.longitude, val.address)}><HiLocationMarker/></button>
                                                        </td>
                                                        <td>
                                                            <button type="button"  className="btn btn-sm btn-danger" onClick={() => handleDelete(val.edpl_id)} > Delete </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Manage_attendance_address