import React, { useState, useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Axios from 'axios';
import { CSVLink } from "react-csv";
import moment from 'moment';
import FullScreenLoading from 'react-fullscreen-loading'; 

function Employee_attendance_report() {
    const alert = useAlert();
    const localuser = JSON.parse(localStorage.getItem('user'));
    let emp = Apiconnect.decrypt(localuser.id);

    var date = new Date(); 
    const [from_date, setFromDate] = useState(null);
    const [to_date, setToDate] = useState(null);
    const [emp_id, setempid] = useState(null);
    const [dwnEmpAttendanceList, setDwnEmpAttendanceList] = useState([]);
    const [joining_date, setJDate] = useState(null);
    const [relieving_date, setRDate] = useState(null);
    const [fullScreenLoader, setFullScreenLoader] = useState(false);


    const onInputChange = e => {
        setempid(e.target.value);
    }
 
    useEffect(() => { empdata();  }, []);
    const [empList, setEmpList] = useState([]);
    const [dailyAttendanceList, setDailyAttendanceList] = useState([]);

 
    const empdata = () => {
        Apiconnect.getData(`employee/getAll/${Apiconnect.get_company_id()}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setEmpList(_xtract.reverse());
        });
    }

    const submit =async () => { 
        if (from_date != null) {
            var from = format(from_date, 'yyyy-MM-dd');
        }else{
            alert.error('Select from date');
            return
        } 
        if (to_date != null) {
            var to = format(to_date, 'yyyy-MM-dd');
        }else{
            alert.error('Select to date');
            return
        } 
        if (joining_date != null) {
            var jDate = format(joining_date, 'yyyy-MM-dd');
        } 
        if (relieving_date != null) {
            var rDate = format(relieving_date, 'yyyy-MM-dd');
        } 
        setFullScreenLoader('true');
        let info = { company_id: Apiconnect.get_company_id(), from_date: from, to_date: to, emp_id: emp_id ,jDate:jDate,rDate:rDate, group_id: selgrp};
        let info_enc = Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };

        await Apiconnect.postData(`employee_dailydata/getAttendanceInfo`, inf_a).then((response) => { 
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setDailyAttendanceList(_xtract.reverse());
            if(_xtract.length <= 0){
                alert.error('Data Not Found');
            } 
        }); 
        await Apiconnect.postData(`employee_dailydata/getAttendanceExcel`, inf_a).then((response) => { 
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            setDwnEmpAttendanceList(_xtract.reverse());
            setFullScreenLoader(false);
        });

        getemployee_leave_list(inf_a);
    }

    const shift_porduction = (num) => {

        var hours = num;
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        if(rhours.toString().length <= 1){
            rhours = '0'+rhours;
        }
        if(rminutes == 0){
            rminutes = '00';
        }
        return  rhours + ":" + rminutes;

    }
 
  const [grplist, setGrouplist] = useState([])
  const [selgrp, setSelgrp] = useState("")
  const getgrouplist = async () => {
    const inf_a = {company_id : Apiconnect.get_company_id()};
    await Apiconnect.postData(`leave_manage/leave_group_list`, inf_a).then(res => setGrouplist(res.data.data)) 
   }
    useEffect(() => {
        getgrouplist(); 
    }, []);

    const [listemp_leave, sel_listemp_leave] = useState([])
    const getemployee_leave_list = async (inf_a) => { 
        await Apiconnect.postData(`leave_manage/get_employee_leave_lists`, inf_a).then(response => 
            sel_listemp_leave(Apiconnect.decrypt_obj(response.data.data))
        ); 
    }

    // console.log(selgrp)

    let dailyAttendanceList1=[];
    let dailyAttendanceList2=[];

    dailyAttendanceList1=dailyAttendanceList;

    for (let icnt1 = 0; icnt1 < dailyAttendanceList1.length; icnt1++) {
        var dailyAttendanceList11=dailyAttendanceList1[icnt1];  
        let punch_date1=moment(new Date(dailyAttendanceList11.punch_date)).format("YYYY-MM-DD");
        var the_id=dailyAttendanceList11.id;
        var emp_id1=dailyAttendanceList11.emp_id;

        dailyAttendanceList2[emp_id1+'#'+punch_date1]=dailyAttendanceList11;  
    }
    
    for (let icnt2 = 0; icnt2 < listemp_leave.length; icnt2++) {
        var listemp_leave11=listemp_leave[icnt2];  

        let leave_start_date1=moment(new Date(listemp_leave11.leave_start_date)).format("YYYY-MM-DD");
        let leave_end_date1=moment(new Date(listemp_leave11.leave_end_date)).format("YYYY-MM-DD");
        var the_id=listemp_leave11.id;
        var emp_id1=listemp_leave11.emp_id;

        dailyAttendanceList2[emp_id1+'#'+leave_start_date1]=listemp_leave11;
        dailyAttendanceList2[emp_id1+'#'+leave_end_date1]=listemp_leave11;
    }
    
    console.log(dailyAttendanceList2); 

    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">

                {fullScreenLoader
                    ? (
                    <FullScreenLoading loading loaderColor="#3498db" />
                    )
                    
                    : null
                    }

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card">
                                <div>
                                    <h4 className="card-title hd">Employee Attendance</h4>
                                </div>
                                <div className="iq-card-body">

                                    <div className="row p_t_15">
                                        <div className="col-md-3">
                                            From :  <br />
                                            <DatePicker
                                                selected={from_date}
                                                onChange={e => setFromDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="From Date"
                                                name="from_date"
                                                required=""
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                scrollableMonthDropdown
                                                yearDropdownItemNumber={100}
                                                maxDate={new Date()}
                                                dropdownMode="select"
                                                autoComplete='off'
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            TO :  <br /> 
                                            <DatePicker
                                                selected={to_date}
                                                onChange={e => setToDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="To Date"
                                                name="to_date"
                                                required=""
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                scrollableMonthDropdown
                                                yearDropdownItemNumber={100}
                                                maxDate={new Date()}
                                                dropdownMode="select"
                                                autoComplete='off'
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            User : <br />
                                            <select className="form-control" name="emp_id" value={emp_id} onChange={e => onInputChange(e)}>
                                                <option value="">Select User</option>
                                                {empList.map((val, key) => {
                                                    return (
                                                        <option key={key} value={val.id}>{val.first_name} {val.last_name}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            Group : <br/>
                                            <select className="form-control" name="selgrp" value={selgrp} onChange={(e) => setSelgrp(e.target.value)}>
                                                <option value="" disabled selected>select group</option>
                                                {
                                                    grplist.map((item, index) => (
                                                        <option key={index} value={item.leavegroup_pk_id}>{item.leavegroup_name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>

                                    </div>
                                    <div className="row p_t_15">
                                        <div className="col-md-3">
                                        Joining Date :  <br />
                                            <DatePicker
                                                selected={joining_date}
                                                onChange={e => setJDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="Joining Date"
                                                name="from_date"
                                                required=""
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                scrollableMonthDropdown
                                                yearDropdownItemNumber={100}
                                                dropdownMode="select"
                                                autoComplete='off'
                                            />
                                        </div>
                                        <div className="col-md-3">
                                        Relieving Date :  <br /> <DatePicker
                                                selected={relieving_date}
                                                onChange={e => setRDate(e)}
                                                className="form-control"
                                                dateFormat='yyyy-MM-dd'
                                                placeholderText="Relieving Date"
                                                name="to_date"
                                                required=""
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                scrollableMonthDropdown
                                                yearDropdownItemNumber={100}
                                                dropdownMode="select"
                                                autoComplete='off'
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <input type="button" className="btn btn-success" value="Submit" style={{ marginTop: "23px" }} onClick={e => submit(e)} />
                                        </div>

                                    </div>

                                    <div className="row" style={{ marginTop: '25px' }} >

                                        <div className="col-lg-12">
                                            <div className="iq-card">
                                                <div className="row">
                                                    <div className="col-md-6" >
                                                    </div>
                                                    <div className="col-md-6" >
                                                        <CSVLink filename={"employee-attendance.csv"} style={{ marginTop: '10px', marginRight: '20px', float: 'right' }} className="btn btn-warning" data={dwnEmpAttendanceList}  >
                                                            <i class="fa fa-download"></i>Employee Attendance CSV
                                                        </CSVLink>
                                                    </div>
                                                </div>
                                                <div class="iq-card-body">
                                                    <div className="table-responsive">

                                                        <table id="user-list-table" className="table table-striped table-bordered" role="grid" aria-describedby="user-list-page-info">
                                                            <thead>
                                                                <tr className="clr">
                                                                    <th>#</th>
                                                                    <th>Date</th>
                                                                    <th>Name</th>
                                                                    <th>Department</th>
                                                                    <th>Shift</th>
                                                                    <th>In time</th>
                                                                    <th>Out time</th>
                                                                    <th>Break Hour</th>
                                                                    <th>Working Hour</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {dailyAttendanceList1.length > 0
                                                                    ?
                                                                    dailyAttendanceList1.map((val, key) => {
                                                                     var msg = '';
                                                                    var shift_tot = val.shift_working_hour;
                                                                    if(shift_tot == null){
                                                                        var shift_tot = 8;
                                                                    }
                                                                    var shift_tot2 = shift_tot/2;
                                                                    var shift_tot3 = 3/4*shift_tot;

                                                                   var shift_h =  shift_porduction(shift_tot);
                                                                   var shift_half =  shift_porduction(shift_tot2);
                                                                   var shift_half3 =  shift_porduction(shift_tot3);


                                                                    if (val.production_hour1 != '00:00' && val.production_hour1 >= shift_h) {
                                                                        var production_hour = <div className=" badge badge-pill badge-success">{val.production_hour1}</div>;
                                                                         msg = 'PRESENT';
                                                                    } else if (val.production_hour1 != '00:00' && val.production_hour1 <= shift_h && val.production_hour1 >= shift_half) {
                                                                        var production_hour = <div className=" badge badge-pill badge-warning">{val.production_hour1}</div>;
                                                                    } else {
                                                                        var production_hour = <div className=" badge badge-pill badge-danger">{val.production_hour1}</div>;
                                                                        msg = 'ABSENT';
                                                                    }
                                                                    if(shift_h >  val.production_hour1 && val.production_hour1 > shift_half3){
                                                                        msg = 'SHORT LEAVE';
                                                                    }

                                                                    if(shift_half3 >  val.production_hour1 && val.production_hour1 > shift_half){
                                                                        msg = 'HALF DAY';
                                                                    }
                                                                    if (val.punch_count != null) {
                                                                        if (val.punch_count == 1) {
                                                                            var msg = 'PUNCH MISS';
                                                                        }
                                                                    }

                                                                    if (val.production_hour1 == null) {
                                                                        var production_hour = 'NO PUNCH FOUND';
                                                                    }

                                                                    var date = '-';
                                                                    if (val.punch_date1 != null) {
                                                                        var date = val.punch_date1;
                                                                    }

                                                                    var start_time = '-';
                                                                    if (val.start_time != null) {
                                                                        var start_time = val.start_time;
                                                                    }
                                                                    var end_time = '-';
                                                                    if (val.end_time != null) {
                                                                        var end_time = val.end_time;
                                                                    }
                                                                    var break_hours1 = '-';
                                                                    if (val.break_hours1 != null) {
                                                                        var break_hours1 = val.break_hours1;
                                                                    }


                                                                    return (
                                                                        <tr key={key}>
                                                                            <td>{key + 1}</td>
                                                                            <td>{date}</td>
                                                                            <td>{val.first_name + ' ' + val.last_name}</td>
                                                                            <td>{val.department}</td>
                                                                            <td>{val.shift_name}</td>
                                                                            <td>{start_time}</td>
                                                                            <td>{end_time}</td>
                                                                            <td>{break_hours1}</td>
                                                                            <td>{production_hour}</td>
                                                                            <td>{msg}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            : 
                                                                <tr>
                                                                    <th colSpan={10} style={{textAlign:'center'}}> No Data Found.. </th>
                                                                </tr>
                                                            }

                                                            </tbody>
                                                        </table>
                                                    </div>






                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </>
    )

}
export default Employee_attendance_report