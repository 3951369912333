import React, { useState, useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { add, format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-modal';
import { GoogleMap, Marker, useJsApiLoader, InfoBox } from '@react-google-maps/api';
import {HiLocationMarker} from 'react-icons/hi';
import { useMemo } from 'react';
import Geocode from "react-geocode";
import FullScreenLoading from 'react-fullscreen-loading';


function Employee_punchtime_report() {

    const localuser = JSON.parse(localStorage.getItem('user'));
    let emp = Apiconnect.decrypt(localuser.id);

    var date = new Date();
    // const [from_date,setFromDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [from_date,setFromDate] = useState(new Date());
    const [to_date,setToDate] = useState(new Date());
    const [emp_id,setempid] = useState(null);
    const [emp_status, setEmp_status] = useState(null)
    const [fullScreenLoader, setFullScreenLoader] = useState(false);

    const alert = useAlert(); 

    const onInputChange = e => {
        setempid(e.target.value);
    }


    useEffect(() => { empdata();punchtime(from_date,to_date,emp_id, emp_status) }, []);
    const [empList, setEmpList] = useState([]);
    const [punchtimeList, setpunchtimeList] = useState([]);


    const empdata = () => {
        Apiconnect.getData(`employee/getAll/${Apiconnect.get_company_id()}`).then((response) => {
          let _xtract = Apiconnect.decrypt_obj(response.data.data);
          setEmpList(_xtract.reverse());
        });
      }

    const submit = () =>{
        let info = { company_id: Apiconnect.get_company_id(), from_date:format(from_date, 'yyyy-MM-dd') ,to_date:format(to_date, 'yyyy-MM-dd'),emp_id:emp_id, group_id: selgrp, emp_status:emp_status };
        let info_enc = Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };

          Apiconnect.postData(`employee_punchtime/getempdata`,inf_a).then((response) => {
          //  Axios.post(`http://localhost:3050/api/employee_punchtime/getempdata`,inf_a).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            console.log(_xtract)
             setpunchtimeList(_xtract.reverse());
          });
    }

    

      const punchtime = (from_date,to_date,emp_id,emp_status) =>{

        let info = { company_id: Apiconnect.get_company_id(), from_date:format(from_date, 'yyyy-MM-dd') ,to_date:format(to_date, 'yyyy-MM-dd'),emp_id:emp_id, emp_status:emp_status };
        let info_enc = Apiconnect.encrypt_obj(info);
        const inf_a = { enc: info_enc };

        Apiconnect.postData(`employee_punchtime/getempdata`,inf_a).then((response) => {
             let _xtract = Apiconnect.decrypt_obj(response.data.data);
             console.log(_xtract)
             setpunchtimeList(_xtract.reverse());
          });
      }

     console.log('punchtimeList',punchtimeList)

     //get group list
  const [grplist, setGrouplist] = useState([])
  const [selgrp, setSelgrp] = useState("")
  const getgrouplist = async () => {
    const inf_a = {company_id : Apiconnect.get_company_id()};
    await Apiconnect.postData(`leave_manage/leave_group_list`, inf_a).then(res => setGrouplist(res.data.data)) 
   }
    useEffect(() => {getgrouplist()}, [])

    /* ================================================Modal============================================== */
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const customStyles = {
      content: {
         marginTop: "50px",
         top: '50%', width: '40%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
         height:'60%',
      },
   };

   const [address, setAddress] = useState("")
   const [la, setLa] = useState(0)
   const [ln, setLn] = useState(0)
   const [dla, setDla] = useState(0)
   const [dln, setDln] = useState(0)


   Geocode.setApiKey("AIzaSyDYhFbq3EBeezplKWoyD7BYBjLEFJ6cEUE")
   Geocode.fromLatLng(String(la), String(ln)).then(
      (response) => {
       setAddress(response.results[0].formatted_address);
      },
      (error) => {
        console.error(error);
      }
    );

   
   const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      // googleMapsApiKey: 'AIzaSyCIFcp6gHlmIzmRWrFEAx70F2mDwt1m8Xs'
      googleMapsApiKey: 'AIzaSyDYhFbq3EBeezplKWoyD7BYBjLEFJ6cEUE' /* ==NEW== */
   });
   
   

   const applyTemplate = (lng, lat, dlng, dlat) => { 
      setLa(lat) 
      setLn(lng)
      setDla(dlat)
      setDln(dlng)
      setModalIsOpen(true)
    }
    useMemo(() => {}, [la, ln, dla, dln])

   const containerStyle = {width: '100%', height: '500px'};

//   console.log(emp_status)

  const handleApprove = (id) => {
   setFullScreenLoader(true)
   let inf = {is_approved : 1, id:id}
   let inf_e = Apiconnect.encrypt_obj(inf)
   const inf_d = { enc: inf_e };
   Apiconnect.postData(`employee_punchtime/manual_attendance_updatestatus`, inf_d).then(res => {
      console.log("Approved")
      punchtime(from_date,to_date,emp_id, emp_status)
      setFullScreenLoader(false)
      alert.success("Approved")
   }).catch(err =>{
      console.log(err)
      alert.error("Error")
   })
  }

  const handleReject = (id) => {
   setFullScreenLoader(true)
   let inf = {is_approved : 2, id:id}
   let inf_e = Apiconnect.encrypt_obj(inf)
   const inf_d = { enc: inf_e };
   Apiconnect.postData(`employee_punchtime/manual_attendance_updatestatus`, inf_d).then(res => {
      console.log("Disapproved")
      punchtime(from_date,to_date,emp_id, emp_status)
      setFullScreenLoader(false)
      alert.success("Disapproved")
   }).catch(err =>{
      console.log(err)
      alert.error("Error")
   })
  }

    
    return (
        <>
           <div id="content-page" className="content-page">
               <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                  <div className="iq-card">
                     <div className='row'>
                        <div className='col-md-12'>
                           {
                              isLoaded ? (
                                  <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={{lat: parseFloat(la), lng: parseFloat(ln)}}
                                    zoom={19}
                                    // onClick={handleMapClick}
                                  >
                                    <Marker position={{lat: parseFloat(la), lng: parseFloat(ln)}} title={address} label="Punch Location"/>
                                    <InfoBox position={{lat: parseFloat(la), lng: parseFloat(ln)}}>
                                       <div class="card bg-light" style={{padding: "1px"}}>
                                          <div class="card-body" style={{width: "18rem"}}>
                                             {address}
                                          </div>
                                       </div>
                                    </InfoBox>
                                    <Marker position={{lat: parseFloat(dla), lng: parseFloat(dln)}} label="Assigned Punch Location" />
                                  </GoogleMap>
                                ) : <></>
                           }
                        </div>
                     </div>
                  </div>
               </Modal>
              <div className="container-fluid">                    
               {fullScreenLoader ? ( <FullScreenLoading loading loaderColor="#3498db" />): null}
                 <div className="row">
                    <div className="col-sm-12">
                       <div className="iq-card">
                          <div>
                             <h4 className="card-title hd">Employee Attendance</h4>
                          </div>
                          <div className="iq-card-body">
  
                             <div className="row p_t_15">
  
                                <div className="col-md-3">
                                   From :  <br />
                                   <DatePicker
                                      selected={from_date}
                                      onChange={e => setFromDate(e)}
                                      className="form-control"
                                      dateFormat='yyyy-MM-dd'
                                      placeholderText="From Date"
                                      name="from_date"
                                      required=""
                                      showMonthDropdown
                                      showYearDropdown
                                      scrollableYearDropdown
                                      scrollableMonthDropdown
                                      yearDropdownItemNumber={100}
                                      dropdownMode="select"
                                   />
                                </div>
                                <div className="col-md-3">
                                   TO :  <br /> 
                                   <DatePicker
                                      selected={to_date}
                                      onChange={e => setToDate(e)}
                                      className="form-control"
                                      dateFormat='yyyy-MM-dd'
                                      placeholderText="To Date"
                                      name="to_date"
                                      required=""
                                      showMonthDropdown
                                      showYearDropdown
                                      scrollableYearDropdown
                                      scrollableMonthDropdown
                                      yearDropdownItemNumber={100}
                                      dropdownMode="select"
                                   />
                                </div>
                                <div className="col-md-3">
                                   User : <br />
                                   <select className="form-control" name="emp_id" value={emp_id} onChange={e => onInputChange(e)}>
                                      <option value="">Select User</option>
                                      {empList.map((val, key) => {
                                         return (
                                            <option key={key} value={val.id}>{val.first_name} {val.last_name}</option>
                                         );
                                      })}
                                   </select>
                                </div>
                                <div className="col-md-3">
                                    Group : <br/>
                                    <select className="form-control" name="selgrp" value={selgrp} onChange={(e) => setSelgrp(e.target.value)}>
                                       <option value="" disabled selected>select group</option>
                                       {
                                             grplist.map((item, index) => (
                                                <option key={index} value={item.leavegroup_pk_id}>{item.leavegroup_name}</option>
                                             ))
                                       }
                                    </select>
                                 </div>
                                <div className="col-md-3 mt-2">
                                    Status : <br/>
                                    <select className="form-control" name="emp_status" value={emp_status} onChange={(e) => setEmp_status(e.target.value)}>
                                       <option value="" disabled selected>select status</option>
                                       <option value="0">Pending</option>
                                       <option value="1">Approved</option>
                                       <option value="2">Rejected</option>
                                    </select>
                                 </div>
                                <div className="col-md-2">
                                   <input type="button" className="btn btn-success" value="Submit" style={{ marginTop: "23px" }} onClick={e => submit(e)} />
                                </div>
  
                             </div>
                           
                             <div className="row" style={{marginTop:'25px'}} >
                                 <div className="col-lg-12">
                                    <div className="iq-card">
                                       <div class="iq-card-body" style={{padding: '10px'}}>
                                          <div className='row'>
                                             {punchtimeList.map((val, key) => {

                                                if(val.thumb == null){
                                                   var img = '/images/user/1.jpg';
                                                }else{
                                                   var img = process.env.React_App_BASE_URL_API+'/uploads/thumb/'+val.thumb;
                                                }

                                                let distance = null
                                                let result = ""
                                                if(val.is_location_matched == 1){
                                                   result = <div className='badge badge-success mx-auto'>Location Matched</div>
                                                }else{
                                                   result = <div className='badge badge-danger mx-auto'>Punched Outside Assigned Location</div>
                                                }

                                                if(val.geo_latitude !== null && val.geo_latitude !== "" && val.geo_longitude !== null && val.geo_longitude !== ""){
                                                   if(val.edpl_emp_id !== null){
                                                      const R = 6371;
                                                      const dLat = (val.default_latitude - val.geo_latitude)*(Math.PI / 180);
                                                      const dLon = (val.default_longitude - val.geo_longitude)*(Math.PI / 180);
                                                      const lat1Radians = (val.geo_latitude)*(Math.PI / 180);
                                                      const lat2Radians = (val.default_latitude)*(Math.PI / 180);
                                                      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1Radians) * Math.cos(lat2Radians);
                                                      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                                                      distance = R * c * 1000; 

                                                      // if(distance < val.desire_attendance_accuracy){
                                                      //    result = <div className='badge badge-success mx-auto'>Location Matched</div>
                                                      // }else{
                                                      //    result = <div className='badge badge-danger mx-auto'>Punched Outside Assigned Location</div>
                                                      //    // approveBtn = <button className='btn btn-primary between'>Approve</button> 
                                                      // }
                                                   }else{
                                                      result = <div className='badge badge-sm badge-warning center'>Default Punch Location Not Assigned</div>
                                                   }
                                                }else{
                                                   result = <div className='badge badge-secondary center mx-auto'>Punch Location Not Available</div>
                                                }

                                               
                                                return (
                                             
                                                   <div className='col-md-4 col-sm-6 col-xs-12'>
                                                      <div className='card' style={{width: "20rem"}}>
                                                         <div className='row'>
                                                            <div className='col-md-12'>
                                                               <div className="media-support d-flex" style={{display: 'unset'}}>
                                                                  <div class="media-support-header mb-2 align-items-center mx-auto">
                                                                        <div class="media-support-user-img mr-3" >
                                                                           <img class="rounded-circle" style={{height: '50px',width: '50px'}} src={img} alt="" />
                                                                        </div>
                                                                        <div class="media-support-info mt-2" style={{lineHeight:'13px'}}>
                                                                           <h6 class="mb-0"><a href="#" class=""><strong>{val.first_name+' '+val.last_name}</strong></a></h6>
                                                                           <small className='text-muted'><strong>Punch Type :</strong> {val.punch_type}</small><br/>
                                                                           <small className='text-muted'><strong>Punched At :</strong> {val.punch_tm}</small><br/>
                                                                           <small className='text-muted'><strong>Date :</strong>{val.punch_dt}</small>
                                                                        </div>
                                                                  </div>
                                                               </div>
                                                               <hr/>
                                                            </div>
                                                            <div className='col-md-12 d-flex justify-content-around'>
                                                              <div className='row mx-auto'>
                                                               {distance !== null ? <div className='col-md-12 d-flex'><small className='mx-auto'><strong>Accuracy :</strong>{distance.toFixed(2)} meters</small></div> : ""}
                                                               <div className='col-md-12 d-flex align-items-center'>{result}</div>
                                                              </div>
                                                              <div className='row mx-auto'>
                                                                  {val.geo_longitude !== "" && val.geo_latitude !== "" && val.geo_longitude !== null && val.geo_latitude !== null ? <button className='btn btn-info' onClick={() => applyTemplate(val.geo_longitude, val.geo_latitude, val.default_longitude, val.default_latitude)}><HiLocationMarker/></button> : ""}
                                                              </div>
                                                            </div>
                                                            <div className='col-md-12 '>
                                                               <hr/>
                                                               <div className='row d-flex justify-content-around'>                                   
                                                                     {val.status == 0 ? <button className='btn btn-sm btn-primary between' onClick={() => handleApprove(val.emp_punch_id)}>Approve</button> : null}
                                                                     {val.is_manual == 1 ? <button className='btn btn-sm btn-danger between' onClick={() => handleReject(val.emp_punch_id)}>Reject</button> : null}                                  
                                                               </div>
                                                            </div>
                                                         </div> 
                                                      </div>
                                                   </div>
                                                );
                                                })}
                                          </div>
                                       </div>

                                    </div>
                                 </div>
  
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
  
              </div>
           </div>

        </>
     )

}
export default Employee_punchtime_report