import React, { useState, useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import {Link} from 'react-router-dom'
import Combine_view_emp_leave_apply from "../../components/combine_view_emp_leave_apply";
 

function Employee_leave_apply() {
    const alert = useAlert();
    let history = useHistory(); 

    const localuser = JSON.parse(localStorage.getItem('user'));
    let user_id = Apiconnect.decrypt(localuser.id); 
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);
    const AuthStr = localuser.accessToken; 

    let user_name=localuser.username;
      
     
    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <Link to="/LeaveEmployee"><button className="btn btn-primary pull-right">Back</button></Link> 
                        </div>
                    </div>
                    <div className="row" style={{marginTop:10}}>

                        {/*================strat_left_div==================*/}
                        <div className="col-lg-12">
                            <Combine_view_emp_leave_apply
                                options={{ 
                                    title: 'Welcome'
                                }}
                                sel_user_id={''} 
                                company_id={Apiconnect.get_company_id()} 
                                sel_user_name={user_name}
                                alert={alert}
                            />
                        </div>
                        {/*================end_left_div==================*/} 

                    </div>
                </div>
            </div>
        </>
    )

}
export default Employee_leave_apply