
import React, { useState,useEffect , useCallback} from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; 
import { format } from 'date-fns';
import Modal from 'react-modal';
import '../pages/pages.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


import Cropper from 'react-easy-crop'

import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";


const Company_holiday = () => {



   const [crop, setCrop] = useState({ x: 0, y: 0 });
   const [zoom, setZoom] = useState(1);
   const [thumb, setthumb] = useState('');
   const [rotation, setRotation] = useState(0)
   const [image, setImage] = useState(null);
   const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
   const [croppedImage, setCroppedImage] = useState('http://100dayscss.com/codepen/upload.svg');
   const [saveCroppedImage, setsaveCroppedImage] = useState(null);


    const alert = useAlert();
    let history = useHistory();  
    const locusr = JSON.parse(localStorage.getItem("user"));
    let user_typ = Apiconnect.decrypt(locusr.emp_typ);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [info, setInfo] = useState({
        name: "",
        year: (new Date()).getFullYear(),
        description: "",
        national_holiday: "0",
        company_id : Apiconnect.get_company_id(),
        id: null,
        start_date:format(new Date(), 'yyyy-MM-dd'),
        end_date:format(new Date(), 'yyyy-MM-dd'),
     });
     const { name, year, start_date, end_date, description, national_holiday,company_id,id } = info;
     const onInputChange = e => {
        console.log(e);
        setInfo({ ...info, [e.target.name]: e.target.value });
     };

  
     const onSubmit = async e => {
        e.preventDefault();

         //console.log(info);
  
        if(id>0){
           console.log('in edit function' +id);
           info.start_date = format(startDate, 'yyyy-MM-dd');
           info.end_date = format(endDate, 'yyyy-MM-dd');
           let info_enc =  Apiconnect.encrypt_obj(info);
           const inf_a = { enc: info_enc };
           Apiconnect.postData(`company_holiday/update/`, inf_a).then((response) => {
              history.push("/company_holiday"); 
              setInfo({  name: "",year:(new Date()).getFullYear(),start_date: "",end_date: "",description: "", national_holiday: "",id: ''});
              getList();
              alert.success('Holiday updated successfully.');  
           });
        }else{

      //    //  const croppedImg = await showCroppedImage();
      //    const data = new FormData();
      //    // data.append('thumb', croppedImg);
        

      //    let info_enc =  Apiconnect.encrypt_obj(info);           
      //    //data.append('enc', info_enc);

      //    data.append('name', name);
      //    data.append('year', year);
      //    data.append('start_date', format(startDate, 'yyyy-MM-dd'));
      //    data.append('end_date', format(endDate, 'yyyy-MM-dd'));
      //    data.append('description', description);
      //    data.append('national_holiday', national_holiday);
      //    // data.append('thumb', thumb+'.png');
      //    data.append('company_id', company_id);

      //    // console.log(national_holiday);
      //   //  console.log(thumb+'.png');

      //    // console.log(data);
      //    // return false;

      //    if(thumb != '' && thumb != null){
      //       Apiconnect.postData(`company_holiday/uploadcsv`, data).then((response) => {
             
      //          console.log(response);
      //          alert.success(response.data.message)
      //       });
      //    }else{
      //       alert.error('* feild mendatory')
      //    }

               //   console.log('in add function' );
           info.start_date = format(startDate, 'yyyy-MM-dd');
           info.end_date = format(endDate, 'yyyy-MM-dd');
           let info_enc =  Apiconnect.encrypt_obj(info);           
           const inf_a = { enc: info_enc };
           await Apiconnect.postData("company_holiday/create", inf_a);
           history.push("/company_holiday"); 
           setInfo({  name: "",year: "",start_date: "",end_date: "",description: "", national_holiday: "",id: ''});
           getList();
           alert.success('Holiday added successfully.'); 

         }
  
     };

     const ondlt = (id) => {
      confirmAlert({
        title: 'Are you sure to Delete?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => deleteItem(id), 
          },
          {
            label: 'No',
          }
        ]
      });
    };

     const deleteItem = (id) => {
      Apiconnect.deleteData(`company_holiday/delete/${id}`).then((response) => {
           history.push("/company_holiday");
           alert.success(response.data.message);      
           getList();
        });
     };
  
     const getItem = (id) => {
      Apiconnect.getData(`company_holiday/get/${id}`).then((response) => {
          
           let _xtract = Apiconnect.decrypt_obj(response.data.data); 
           console.log(_xtract);  
            setInfo({ name: _xtract[0].name, year: _xtract[0].year, 
            description: _xtract[0].description,national_holiday: _xtract[0].national_holiday, id: _xtract[0].id });             
            setStartDate(new Date(_xtract[0].start_date));
            setEndDate(new Date(_xtract[0].end_date));
        });
      };
  
      
     function refreshPage(){ 
        window.location.reload(); 
     }
  
  
     useEffect(() => { getList(); }, []);
     const [List, setList] = useState([]);
     const getList = () => {
      Apiconnect.getData(`company_holiday/getall/${Apiconnect.get_company_id()}`).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
            console.log(_xtract);
            setList(_xtract.reverse());
        });
       
     };

     const [viewList,setViewList] = useState({
      name1: "",
      year1: "",
      description1:"",
      national_holiday1:"",

   });

   const {name1, year1,description1, national_holiday1} = viewList;
   const [startDate1, setStartDate1] = useState(new Date());
   const [endDate1, setEndDate1] = useState(new Date());


   const viewItem = (id)=>{
      setModalIsOpen(true);

      Apiconnect.getData(`company_holiday/get/${id}`).then((response) => {
         console.log(response.data.data[0]);
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setViewList({
            name1: _xtract[0].name, year1: _xtract[0].year, 
            description1: _xtract[0].description,national_holiday1: _xtract[0].national_holiday });
            setStartDate1(new Date(_xtract[0].start_date));
            setEndDate1(new Date(_xtract[0].end_date));
      });

   }

   const customStyles = {
      content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
        
      },
   };
   const [modalIsOpen, setModalIsOpen] = useState(false);

   let n_h = 'YES';
   if(national_holiday1 == 0){
      let n_h = 'NO';
   }

   var minOffset = 0, maxOffset = 60;
   var thisYear = (new Date()).getFullYear()+2;
   const yr = [];
   for (var i = minOffset; i <= maxOffset; i++) {
      var year112 = thisYear - i;
      yr.push(year112);
   }


//////////////////////////////////////////////////////////////////////////////
//    const handleInputChange = e => {
//          const file = e.target.files[0]
//          const reader = new FileReader();  
//          reader.addEventListener('load', () => {
//          setImage(reader.result);
//          }, false);  
//          if (file) {
//          reader.readAsDataURL(file)
//          }  
//     }
    
//   const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
//    setCroppedAreaPixels(croppedAreaPixels)
//  }, [])


//  const showCroppedImage = useCallback(async () => {
//    try {
//      const croppedImage = await getCroppedImg(
//        image,
//        croppedAreaPixels,
//        rotation
//      )
//      //   console.log('donee', { croppedImage })
//      setCroppedImage(croppedImage)
//      //console.warn(croppedImage);
//      //   setImage(croppedImage);
//       return croppedImage;
//    } catch (e) {
//      console.error(e)
//    }
//  }, [croppedAreaPixels, rotation])


//  async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
//    const image = await createImage(imageSrc)
//    const canvas = document.createElement('canvas')
//    const ctx = canvas.getContext('2d')
//    const maxSize = Math.max(image.width, image.height)
//    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))
   
//    canvas.width = safeArea
//    canvas.height = safeArea
//    ctx.translate(safeArea / 2, safeArea / 2)
//    ctx.rotate(getRadianAngle(rotation))
//    ctx.translate(-safeArea / 2, -safeArea / 2)
//    ctx.drawImage(
//      image,
//      safeArea / 2 - image.width * 0.5,
//      safeArea / 2 - image.height * 0.5
//    )
//    const data = ctx.getImageData(0, 0, safeArea, safeArea)
//    canvas.width = pixelCrop.width
//    canvas.height = pixelCrop.height
//    ctx.putImageData(
//      data,
//      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
//      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
//    )

//    var imgnm =  Math.floor((Math.random() * 1000999) + 1);
//    setthumb(imgnm);

//    const blob = DataURLtoBlob(canvas.toDataURL('image/jpeg'))
//    let file = new File([blob], imgnm + '.png', {
//      lastModified: new Date(),
//      type: "image/png"
//    })


   
//    setsaveCroppedImage(file);
//    console.warn(file);
//    console.warn(saveCroppedImage);

//    return file;
  
//    // return new Promise(resolve => {
//    //   canvas.toBlob(file => {
//    //     resolve(URL.createObjectURL(file))
//    //   }, 'image/jpeg')
//    // })




//  }


//  const createImage = url =>
//    new Promise((resolve, reject) => {
//    const image = new Image()
//    image.addEventListener('load', () => resolve(image))
//    image.addEventListener('error', error => reject(error))
//    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
//    image.src = url
//  })

// function getRadianAngle(degreeValue) {
//    return (degreeValue * Math.PI) / 180
// }
// function DataURLtoBlob(dataURI) {
//    // convert base64 to raw binary data held in a string
//    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
//    var byteString = atob(dataURI.split(',')[1]);

//    // separate out the mime component
//    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

//    // write the bytes of the string to an ArrayBuffer
//    var ab = new ArrayBuffer(byteString.length);

//    // create a view into the buffer
//    var ia = new Uint8Array(ab);

//    // set the bytes of the buffer to the correct values
//    for (var i = 0; i < byteString.length; i++) {
//      ia[i] = byteString.charCodeAt(i);
//    }

//    // write the ArrayBuffer to a blob, and you're done
//    var blob = new Blob([ab], { type: mimeString });
//    return blob;

//  }
////////////////////////////////////////////////////////////////////////////////////////////
/*--------------------------------- excel------------------------- */
console.log(List)

  /* ---------------download excel--------- */
  function getSheetData(data, header) {
   var fields = Object.keys(data[0]);
   var sheetData = data.map(function (row) {
     return fields.map(function (fieldName) {
       return row[fieldName] ? row[fieldName] : "";
     });
   });
   sheetData.unshift(header);
   return sheetData;
 }

 const saveAsExcel = () => {
   let header = ["Id", "name", "year", "description", "start_date"]

   XlsxPopulate.fromBlankAsync().then(async (workbook) => {
     const sheet1 = workbook.sheet(0);
     const sheetData = getSheetData(List, header);
     const totalColumns = sheetData[0].length;

     sheet1.cell("A1").value(sheetData);
     const range = sheet1.usedRange();
     const endColumn = String.fromCharCode(64 + totalColumns);
     sheet1.row(1).style("bold", true);
     // sheet1.column("D").value("numberFormat")
     sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
     range.style("border", true);
     return workbook.outputAsync().then((res) => {
       saveAs(res, "holidayList.xlsx");
     });
   });
 }

    return (
        <>


<div id="content-page" className="content-page">
            <div className="container-fluid">


            <div>
                  <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between model_head">
                           <div className="iq-header-title">
                              <h4 className="card-title ">Category View</h4>
                           </div>
                        </div>

                        <div className="iq-card-body">
                           <div className="row">
                              <div className="col-md-6">
                              <div className="form-group">
                                    <span><b>Holiday Name : </b></span>
                                    <span>{name1}</span>
                              </div>
                              </div>

                              <div className="col-md-6">
                              <div className="form-group">
                                    <span><b>National Holiday : </b></span>
                                    <span>{n_h}</span>
                                 </div>
                              </div>
                              </div>

                              <div className="row">
                              <div className="col-md-6">
                              <div className="form-group">
                                    <span><b>Year : </b></span>
                                    <span>{year1}</span>
                                 </div>
                              </div>


                              <div className="col-md-6">
                              <div className="form-group">
                                    <span><b>Start Date : </b></span>
                                    <span>{format(startDate1, 'yyyy-MM-dd')}</span>
                                 </div>
                              </div>
                              </div>
                              <div className="row">
                              <div className="col-md-6">
                              <div className="form-group">
                                    <span><b>End Date : </b></span>
                                    <span>{format(endDate1, 'yyyy-MM-dd')}</span>
                                 </div>
                              </div>

                              <div className="col-md-6">
                                 <div className="form-group">
                                    <span><b>Description : </b></span>
                                    <span>{description1}</span>
                                 </div>
                              </div>

                              
                             
                           </div>
                        </div>
                        <div className="text_center">
                        <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>close</button>
                        </div>
                     </div>
                  </Modal>
               </div>


               <div className="row">
                  <div className="col-sm-12 col-lg-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">Holiday</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                           <form onSubmit={e => onSubmit(e)}>
                              <div className="row">


                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Holiday Name <strong className="required"> *</strong></label>
                                                        <input type="text" className="form-control validate[required]" name="name"
                                                            value={name}
                                                            onChange={e => onInputChange(e)}
                                                            placeholder="Holiday Name" required />
                                                    </div>
                                                </div>
                                              
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                    <label>National Holiday</label>
                                                    <select className="form-control" name="national_holiday" 
                                                        value={national_holiday}
                                                        onChange={e => onInputChange(e)}>
                                                        <option value="0">NO</option>
                                                        <option value="1">YES</option>
                                                    </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                    <label>Year</label>
                                                    <select className="form-control" name="year" 
                                                        value={year}
                                                        onChange={e => onInputChange(e)}>
                                                           {
                                                              yr.map((val,key)=>{
                                                               return(
                                                               <option key={key} value={val}>{val}</option>
                                                               )
                                                              })
                                                           }
                                                    </select>
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-6">
                                                </div> */}
                                           
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Start Date</label>
                                                        <DatePicker 
                                                      selected={startDate}
                                                      onChange={e => setStartDate(e)}
                                                      className  ="form-control"
                                                      dateFormat = 'yyyy-MM-dd'
                                                      placeholderText="Start Date" 
                                                      name="start_date"
                                                      showMonthDropdown
                                                      showYearDropdown
                                                      scrollableYearDropdown
                                                      scrollableMonthDropdown
                                                      yearDropdownItemNumber={100}
                                                      dropdownMode="select"
                                                      />  
                                                    </div>
                                                </div>
                                              
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>End Date</label>
                                                        <DatePicker 
                                                         selected={endDate}
                                                         onChange={e => setEndDate(e)}
                                                         className  ="form-control"
                                                         dateFormat = 'yyyy-MM-dd'
                                                         placeholderText="End Date" 
                                                         minDate={startDate}
                                                         name="end_date"
                                                         showMonthDropdown
                                                         showYearDropdown
                                                         scrollableYearDropdown
                                                         scrollableMonthDropdown
                                                         yearDropdownItemNumber={100}
                                                         dropdownMode="select"
                                                         />  
                                                    </div>
                                                </div>


                                            
                                                <div className="col-md-10">
                                                    <div className="form-group">
                                                        <label>Description</label>
                                                        <textarea rows="4" className="form-control validate[required]" name="description"
                                                          value={description}
                                                          onChange={e => onInputChange(e)}
                                                           placeholder="Description">
                                                        </textarea>
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-6">
                                                   <div className="form-group">
                                                      <span><b>Image :  <strong className="required"> *</strong></b></span>
                                                      <input type="file" accept="image/*" id="file" className="form-control" name="csvfile" onChange={e => handleInputChange(e)} />                                                      
                                                   </div>
                                                </div>
                                                <div className="col-md-6" style={{height:250, width:250,}}>
                                                   <Cropper style={{ borderRadius: '10px', marginBottom: '10px', width: '98%' }}
                                                   image={image}
                                                   crop={crop}
                                                   zoom={zoom}
                                                   aspect={4 / 3}
                                                   onCropChange={setCrop}
                                                   onCropComplete={onCropComplete}
                                                   onZoomChange={setZoom}
                                                   />
                                                </div> */}
                                               
                                               
                                                

                                 <div className="col-md-12 mt-2">
                                    <button type="submit"  name="id" value={id} className="btn btn-primary mr-2"> {id ? 'Update' : 'Submit'} </button>
                                    {(() => {
                                       if (id>0) {
                                          return (
                                             <button type="button" className="btn" onClick={ refreshPage }> Return </button> 
                                          )
                                       } 
                                    })()}     
                                 </div>
                              </div>

                           </form>
                           
                       
                        </div>
                     </div>
                  </div>
                  
                  <div className="col-sm-12 col-lg-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">List Of Holiday</h4>
                           </div>
                        </div>
                        <div className="iq-card-body">
                        <div className='row'>
                           <div className='col-md-6'></div>
                              <div className='col-md-6 mb-2'>
                                 <button className='btn btn-warning float-right' onClick={() => saveAsExcel()}><i class="fa fa-download" aria-hidden="true"></i>Holiday list Excel</button>
                              </div>
                           </div>
                           <table className="table table-striped table-hover">
                              <thead className="">
                              <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Start Date</th>
                                                        <th scope="col">End Date</th>
                                                        <th scope="col">National Holiday</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                              </thead>
                              <tbody className="">
                                 {List.map((val, key) => {

                                    var national_h = 'NO';
                                    if(val.national_holiday === 1){
                                       var national_h = 'YES';
                                    }
                                    return (
                                       <tr key={key}>
                                          <th scope="row">{key + 1}</th>
                                          <td>{val.name}</td>
                                          <td>{val.start_date}</td>
                                          <td>{val.end_date}</td>
                                          <td>{national_h}</td>
                                          <td>
                                          {
                                             user_typ === '3' 
                                             ? (
                                                <button onClick={() => {ondlt(val.id);}}
                                                className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button>
                                             ) 
                                             : null
                                          }
                                         
                                             
                                             {/* <button onClick={() => { deleteItem(val.id); }}
                                                className="btn btn-warning mr-2"><i className="fa fa-trash" aria-hidden="true"></i></button> */}
                                             <button onClick={() => {getItem(val.id);}}
                                             className="btn btn-success mr-2"><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                               <button onClick={() => viewItem(val.id)}
                                                className="btn btn-info mr-2"><i className="fa fa-eye" aria-hidden="true"></i></button>  

                                          </td>
                                       </tr>
                                    );
                                 })}

                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
                                                                                       
               </div>
            </div>
         </div>

        </>
    )
}

export default Company_holiday