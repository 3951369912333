import React, { useState, useEffect,Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import Apiconnect from "../services/Apiconnect.js";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import UserService from "../services/user.service";
import DatePicker from "react-datepicker"; 
import Helper from "../services/HelperCodebase.js";
import { format } from "date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import moment from 'moment'
import Emplyee_leave_list_table from "./Emplyee_leave_list_table.component"

import "react-datepicker/dist/react-datepicker.css";

export default class Combine_view_emp_leave_apply_short extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      list_employees:[],
      list_categorys:[],
      data_empinfo:[],
      data_leave_summary:[],
      fromDate: new Date(),
      toDate: new Date(),
      minDate:new Date(),
      minToDate:new Date(),
      mxDate: new Date( Helper.add_days(new Date(), null)), 
      lpcategory_min_working_day1:'',
      btn_hideshow:1,
      sel_user_id2:'',
      sel_full_half_day:'',
      sel_category:'',
      sel_policy_category_id:'',
      sel_date_from:new Date(),
      sel_date_to:new Date(),
      sel_reason:'',
      sel_is_half_type:'',
      leavecategory_count_min:'',
      leavecategory_count_max:'',
      sel_leave_without_credit:'',
      is_admin:0,
      lpcategory_maxcredit:'',
      is_refresh:0,
      sel_shortleave_type:"Yes",
      list_attendance:[],
    }; 
    
    
  } 
  
  getUserDetails = async (company_id,sel_user_id,is_admin,sel_shortleave_type) => { 
    var data_post={
      company_id:company_id,
      employee_id:(sel_user_id!='')?sel_user_id:0,
      is_admin:is_admin,
      sel_shortleave_type:sel_shortleave_type,
    };  

    // console.log('== 2');
    // console.log(data_post);

    var lists_categorys = new Promise( (resolve, reject) => { 
      Apiconnect.postData(`leave_manage/get_employee_policy_category_list`,data_post).then((response) => {
        // console.log('cat_data',Apiconnect.decrypt_obj(response.data.data));
        this.setState({list_categorys:((typeof response.data.data!='undefined')?Apiconnect.decrypt_obj(response.data.data):0)});
        this.setState({is_refresh:Math.random()});
      });
    });

    await Apiconnect.postData(`leave_manage/get_employee_details_by_id`,data_post).then((response) => { 
      this.setState({data_empinfo:((typeof response.data.data!='undefined')?Apiconnect.decrypt_obj(response.data.data):0)});
    });

    await Apiconnect.getData(`employee/getall/${company_id}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      this.setState({list_employees:_xtract}); 
    });

  
  }

  get_employee_attendance_by_id = async (company_id,sel_user_id) => { 
    let data_att={company_id:company_id,employee_id:sel_user_id,current_date:new Date()}; 
    await Apiconnect.postData(`leave_manage/get_employee_attendances_by_id`,data_att).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      this.setState({list_attendance:_xtract}); 
    });
  }


  async componentDidMount() {
   
    var company_id=this.props.company_id;
    var sel_user_id=this.props.sel_user_id;
    let is_adm = this.state.is_admin;
    this.setState({sel_user_id2:sel_user_id});
    if(this.props.sel_user_id==''){
      is_adm = 1;
      this.setState({is_admin:is_adm});
      
    }
    
    let sel_shortleave_type = this.state.sel_shortleave_type;
    await this.getUserDetails(company_id,sel_user_id,is_adm,sel_shortleave_type);
    await this.get_employee_attendance_by_id(company_id,sel_user_id);
  }
 

  render () {   
   
    // console.log(this.state.data_leave_summary.results);
    var sel_user_id=this.props.sel_user_id;
    let sel_user_name=this.props.sel_user_name;
    let select_category_id='';
    var current_date1 = new Date(); 

    let list_employees=((this.state.list_employees.length>0)?this.state.list_employees:[]);
    let list_attendance=((this.state.list_attendance.length>0)?this.state.list_attendance:[]);
    // let data_leave_summary1=((this.state.data_leave_summary.results!='undefined')?this.state.data_leave_summary:[]);

    let leavepolicy_pk_id=(this.state.list_categorys.length>0)?this.state.list_categorys[0].leavepolicy_pk_id:'';
    let leavepolicy_title=(this.state.list_categorys.length>0)?this.state.list_categorys[0].leavepolicy_title:'';
    
    let lists_categorys1=((this.state.list_categorys.length>0)?this.state.list_categorys:[]);
    
    let data_empinfo=((this.state.data_empinfo.length>0)?this.state.data_empinfo:[]);
    
    let employee_gender=((typeof data_empinfo[0]!='undefined')?data_empinfo[0].gender:'');
    let employee_joining_date1=((typeof data_empinfo[0]!='undefined')?data_empinfo[0].joining_date:'');

    var employee_joining_date2=(employee_joining_date1!=null && employee_joining_date1!='0000-00-00')?new Date(employee_joining_date1):new Date();

    var cal_diff1 = Math.abs(employee_joining_date2 - current_date1); 
    let cal_date_diff1 = Math.floor(cal_diff1/86400000);
     
    // console.log(cal_date_diff1);

    let lists_categorys2={};
    let totalLeaveCredited =0;
    let totalLeaveTaken =0;
    lists_categorys1.map((val_cat,key)=>{
      
      lists_categorys2[val_cat.lpcategory_pk_id]=val_cat;

      totalLeaveCredited = (parseFloat (totalLeaveCredited) + parseFloat(val_cat.cal_total_credit)).toFixed(2);
      totalLeaveTaken = (parseFloat (totalLeaveTaken) + parseFloat(val_cat.cal_total_apply)).toFixed(2);
    
    });
     
      // this.setState({totalLeaveTaken:cal_total_apply});
    

    const onInputChange_category = evnt => {  
      
      const idx = evnt.target.selectedIndex;
      const option = evnt.target.querySelectorAll('option')[idx];
      const policy_cat_id = option.getAttribute('data-attr-policy_cat_id');      
      this.setState({ sel_policy_category_id:policy_cat_id });
      
      let selcategory_id=evnt.currentTarget.value; 
      this.setState({ btn_hideshow:0 });
      this.setState({ sel_category:selcategory_id });
      
      // console.log('Category List',lists_categorys2);
      // console.log('Category List',selcategory_id);

      if(typeof lists_categorys2[policy_cat_id]!='undefined'){
        let lists_categorys3=lists_categorys2[policy_cat_id];
        
        // console.log('Category Item',lists_categorys3);

        let lpcategory_gender=lists_categorys3.lpcategory_gender;
        let lpcategory_applicable_eligibility=lists_categorys3.lpcategory_applicable_eligibility;
        let lpcategory_min_working_day=lists_categorys3.lpcategory_min_working_day;
        let leavecategory_count_min=lists_categorys3.leavecategory_count_min;
        let leavecategory_count_max=lists_categorys3.leavecategory_count_max;
        let lpcategory_app_setting=lists_categorys3.lpcategory_app_setting;
        let lpcategory_maxcredit=lists_categorys3.lpcategory_maxcredit;

        let lpcategory_ocassions_setting_max=lists_categorys3.lpcategory_ocassions_setting_max;
        let cal_tot_leave_count=lists_categorys3.cal_tot_count;
        let cal_tot_leave_apply=lists_categorys3.cal_tot_apply;
        let leavecategory_withoutcredit=lists_categorys3.leavecategory_withoutcredit;

        //console.log(Helper.add_days);
        var mymaxDate1=Helper.add_days(new Date(),0);
        var mymaxDate2=Helper.add_days(new Date(),null);
        // var mymaxDate1=new Date();
        // var mymaxDate2=new Date();

        if(lpcategory_min_working_day){  
          mymaxDate1 = Helper.add_days(new Date,lpcategory_min_working_day);
        }

        if(leavecategory_count_max !=0){
          mymaxDate2 = Helper.add_days(new Date(mymaxDate1),leavecategory_count_max);
        }

        // console.log(mymaxDate1+' == '+mymaxDate2);
        //console.log(new Date(mymaxDate1)+' == '+new Date(mymaxDate2)+'==='+leavecategory_count_max);

        this.setState({ 
          lpcategory_min_working_day1:lpcategory_min_working_day,
          leavecategory_count_min:leavecategory_count_min,
          leavecategory_count_max:leavecategory_count_max,
          lpcategory_maxcredit:lpcategory_maxcredit,
          sel_date_from:new Date(mymaxDate1),
          sel_date_to:new Date(mymaxDate1),
          fromDate:new Date(mymaxDate1),
          toDate:new Date(mymaxDate1),
          minDate:new Date(mymaxDate1),
          minToDate:new Date(mymaxDate1),
          mxDate:new Date(mymaxDate2),
          sel_leave_without_credit:leavecategory_withoutcredit,
        });
         
        // console.log(employee_gender);
        if(employee_gender!=lpcategory_gender && lpcategory_gender!='Both'){ 
          this.setState({ btn_hideshow:0 }); 
          this.props.alert.error('Only '+lpcategory_gender+' candidates can avail this leave..');
          return false;
        }

        //console.log(cal_date_diff1+' '+lpcategory_applicable_eligibility);
        if(cal_date_diff1<lpcategory_applicable_eligibility){
          this.setState({ btn_hideshow:0 });
          this.props.alert.error('Minimum year of service not completed..');
          return false;
        }

        if(cal_tot_leave_count>=lpcategory_ocassions_setting_max){
          this.setState({ btn_hideshow:0 });
          this.props.alert.error('You have already exceeded the maximum number of ocassions limits..');
          return false;
        }

        if(this.props.sel_user_id!='' && lpcategory_app_setting=='Yes'){
          this.setState({ btn_hideshow:0 });
          this.props.alert.error('Only administrators can apply leave from your end..');
          return false;
        }

      }//end_if_undefined 
    };

    const getAvailabeCountByCat = async (category_id)=>{
      
      for (let j=0;j<lists_categorys1.length;j++){  
        //console.log('sacc',lists_categorys1[j]);
        if(lists_categorys1[j].leave_cat_id == category_id){
          //console.log('I am in');
          let total_applied_leaves =lists_categorys1[j].cal_total_apply;
          let total_credits_leaves=lists_categorys1[j].cal_total_credit;
          let remaining_leaves =parseFloat(total_credits_leaves)-parseFloat(total_applied_leaves);
          return remaining_leaves;
        }
      }
    };
     
    const fromDateChage = async (e) => {   
      
      this.setState({ sel_date_from:e });
      let sel_max_date=((this.state.lpcategory_min_working_day1!="")?this.state.lpcategory_min_working_day1:0);
      let sel_max_date2=((this.state.leavecategory_count_max!="")?this.state.leavecategory_count_max:null);
      
      var mymaxDate = Helper.add_days(e, sel_max_date2);
      // this.setState({ sel_date_from:e });

      //console.log('sel_max_date2',sel_max_date2);
      this.setState({ 
        fromDate: e,
        toDate: e, 
        minToDate:new Date(e),
        mxDate: new Date(mymaxDate),
      }); 
    };
    const toDateChage = async (e) => {

      if(this.state.list_attendance.length==0){
        this.setState({ btn_hideshow:0 });
        this.props.alert.error('Please mark attendance to get short day leave.');
        return false;
      }
      
      // console.log('sac');
      let current_date1=new Date();
      let sel_date_from1=moment(new Date(this.state.sel_date_from)).format("YYYY-MM-DD"); 
      let sel_date_to1=moment(new Date(e)).format("YYYY-MM-DD");

      let available_leaves = await getAvailabeCountByCat(this.state.sel_category);
      let appliedDays = Helper.getTotalDiffInDays(sel_date_from1,sel_date_to1);

      let sel_min_date=((this.state.leavecategory_count_min!="")?this.state.leavecategory_count_min:0);
      let sel_max_date=((this.state.lpcategory_min_working_day1!="")?this.state.lpcategory_min_working_day1:0);
      var mymaxDate = Helper.add_days(e, sel_max_date);
      
      let sel_leave_without_credit=((this.state.sel_leave_without_credit!="")?this.state.sel_leave_without_credit:'');
      
      if(sel_date_from1>sel_date_to1 && sel_leave_without_credit!='Yes'){
        this.setState({ btn_hideshow:0 });
        this.props.alert.error('Please Choose Correct Date');
        return false;
      }
  
      // var cal_diff1 = Math.abs(this.state.sel_date_from - e);  
      // // console.log(cal_diff1+'==='+this.state.sel_date_from +'==='+e);
      // let cal_date_diff1 = Math.floor(cal_diff1/86400000);
      let cal_date_diff1 = Helper.getTotalDiffInDays(sel_date_from1,sel_date_to1);
      // if(sel_date_from1==sel_date_to1){
      //   cal_date_diff1 = 1;
      // }
      
      
      if(this.state.sel_full_half_day==1){
        cal_date_diff1=0.5;
        appliedDays=0.5;
      }
      // console.log(cal_date_diff1+' == '+sel_min_date);
      if(cal_date_diff1<sel_min_date && sel_leave_without_credit!='Yes'){
        this.setState({ btn_hideshow:0 });
        this.props.alert.error('You cannot apply less than '+String(sel_min_date)+'');
        return false;
      }

      // console.log('appliedDays='+appliedDays);
      if(this.state.sel_full_half_day==1 && appliedDays>=1 && sel_leave_without_credit!='Yes'){
        this.setState({ btn_hideshow:0 });
        this.props.alert.error('You cannot apply more than one day because you apply half day leave..');
        return false;
      }
      // console.log(appliedDays+'======'+available_leaves);
      if(appliedDays>available_leaves && sel_leave_without_credit!='Yes'){
        this.setState({ btn_hideshow:0 });
        this.props.alert.error('You are trying to apply more than available leave..');
        return false;
      }else{
        this.setState({ btn_hideshow:1 });
        this.setState({ sel_date_to:e });
        this.setState({ toDate: e }); 
        return true;
      }

      
      
    };
 
    // console.log(lists_categorys1);
   
    const onInputChange_emp = async (evnt,event_val) => {   
      let selemp_id=(event_val!=null)?event_val.id:''; 
      await this.getUserDetails(this.props.company_id,selemp_id,this.state.is_admin,this.state.sel_shortleave_type);
      this.setState({ sel_user_id2:selemp_id});
      this.setState({is_refresh:selemp_id});
    }

    const onInputChange_radio_1 = async (evnt) => {  
      let selhalf_day=evnt.currentTarget.value;
      await this.setState({ sel_full_half_day:selhalf_day}); 
    }

    const onInputChange_radio_2 = async (evnt) => { 
      let selhalf_day=evnt.currentTarget.value;   
      await this.setState({ sel_full_half_day:selhalf_day}); 

      if(selhalf_day==1){
        let sel_full_half_day=((this.state.sel_full_half_day!="")?this.state.sel_full_half_day:0);
        let sel_date_from=((this.state.sel_date_from!="")?this.state.sel_date_from:0);
        let sel_date_to=((this.state.sel_date_to!="")?this.state.sel_date_to:0);
        let appliedDays = Helper.getDiffInDays(sel_date_from,sel_date_to); 

        if(sel_full_half_day==1 && appliedDays>=1){
          this.setState({ btn_hideshow:0 });
          this.props.alert.error('You cannot apply more than one day because you apply half day leave..');
          return false;
        }
      }
    }
 
    const onInputChange_reason = async (evnt) => {   
      this.setState({ sel_reason:evnt.currentTarget.value});
    }
 
    const onInputChange_type = async (evnt) => { 
      this.setState({ sel_is_half_type:evnt.currentTarget.value});
    }

    const get_calculate_date_change_and_submit= async () => {

    }
    

    const onSubmit = async (event) => {
      event.preventDefault();
      
      // let 
      let pst_user_id=(sel_user_id!='')?sel_user_id:this.state.sel_user_id2;
      let pst_company_id=this.props.company_id;
      let pst_full_half_day=this.state.sel_full_half_day;
      let pst_category=this.state.sel_category;
      let pst_date_from=this.state.sel_date_from;
      let pst_date_to=this.state.sel_date_to;
      let pst_sel_reason=this.state.sel_reason;
      let pst_is_half_type=this.state.sel_is_half_type;
      let sel_leave_without_credit=((this.state.sel_leave_without_credit!="")?this.state.sel_leave_without_credit:'');
      

      const data_post1 = { 
        user_id:pst_user_id,
        company_id:pst_company_id,
        full_half_day:pst_full_half_day,
        sel_reason:pst_sel_reason,
        pst_category:pst_category,
        pst_date_from:pst_date_from,
        pst_date_to:pst_date_to,
        pst_is_half_type:pst_is_half_type,
      };
      
      if(sel_leave_without_credit!='Yes'){
        if(pst_user_id=='' || pst_user_id==0){
          this.props.alert.error('Please Select Employee..');
          return false;
        }
      }

      if(pst_category=='' && sel_leave_without_credit!='Yes'){
        this.props.alert.error('Please Select Category..');
        return false;
      }
 

      let current_date1=new Date();
      let available_leaves = await getAvailabeCountByCat(pst_category);
      let appliedDays = Helper.getTotalDiffInDays(pst_date_from,pst_date_to);
      
      let sel_min_date=((this.state.leavecategory_count_min!="")?this.state.leavecategory_count_min:0);
      let sel_max_date=((this.state.lpcategory_min_working_day1!="")?this.state.lpcategory_min_working_day1:0);
      var mymaxDate = Helper.add_days(pst_date_to, sel_max_date);

      // var cal_diff1 = Math.abs(this.state.sel_date_from - pst_date_to);  
      // let cal_date_diff1 = Math.floor(cal_diff1/86400000);
      let cal_date_diff1 = Helper.getTotalDiffInDays(this.state.sel_date_from,pst_date_to);
      if(this.state.sel_full_half_day==1){
        cal_date_diff1=0.5;
        appliedDays=0.5;
      }

      // console.log(cal_date_diff1+' '+sel_min_date);
      
      if(sel_min_date>cal_date_diff1 && sel_leave_without_credit!='Yes'){ 
        this.props.alert.error('You cannot apply more than '+String(sel_min_date)+'');
        return false;
      }

      if(this.state.sel_full_half_day==1 && appliedDays>=1 && sel_leave_without_credit!='Yes'){ 
        this.props.alert.error('You cannot apply more than one day because you apply half day leave.');
        return false;
      }
 
      if(available_leaves<appliedDays && sel_leave_without_credit!='Yes'){ 
        this.props.alert.error('You are trying to apply more than available leaves.');
        return false;
      }  

      if(list_attendance.length==0){
        this.props.alert.error('Please mark attendance to get short day leave.');
        return false;
      }

      let sel_leave_count='';
      let info={};
      let fromDate=pst_date_from;
      let toDate=pst_date_to;

      info['leave_typ']=pst_category;
      info['company_id']=pst_company_id;
      info['reason']=pst_sel_reason;
      info['emp_id']=pst_user_id;
      info['typ']='debit';
      info['status']=0;
      info['day_typ']=''; 
      
      info['lop']=0;
      info['empleave_category_id']=this.state.sel_policy_category_id;

      info['leave_start_date']= moment(new Date(pst_date_from)).format("YYYY-MM-DD"); 
      info['leave_end_date']=moment(new Date(pst_date_to)).format("YYYY-MM-DD"); 
       
      info['is_halfday']=pst_full_half_day;

      let Final_Result = 0.5;
      let cal_leave_count=Number(Final_Result);

      if(pst_full_half_day != 1){
        var Difference_In_Time = toDate.getTime() - fromDate.getTime();
        var Difference_In_Days = Math.round( Difference_In_Time / (1000 * 3600 * 24) );
        Final_Result = Difference_In_Days.toFixed(0);
        cal_leave_count = Number(Final_Result) + 1; 
      }

      if(cal_leave_count<0){
        cal_leave_count=0;
      }

      info['leave_count']=String(cal_leave_count);
      info['day_typ']=pst_is_half_type;
      info['is_halfday_type']=pst_is_half_type;
      
      
      let info_enc = Apiconnect.encrypt_obj(info);
      const inf_a = { enc: info_enc, user_id: pst_user_id };
 
      let res_data_apply=[];
      await Apiconnect.postData("leave_manage/get_employee_leave_apply_details", info).then(async (response) => { 
        res_data_apply= Apiconnect.decrypt_obj(response.data.data); 
      });

      let cal_count_applyleave=res_data_apply[0].cal_count_applyleave;
     
      if(cal_count_applyleave>0){ 
        this.props.alert.error('You have already applied leave for the same date.');
        return false;
      } 

      
      await Apiconnect.postData("employee_leave/create_by_empid", inf_a).then(async (response) => { 
        this.setState({sel_category:'', sel_reason:'' }); 
        await this.getUserDetails(pst_company_id,pst_user_id,this.state.is_admin,this.state.sel_shortleave_type);
        this.props.alert.success(response.data.message); 
        this.setState({is_refresh:Math.random()});
      });

    };

    

    if(this.state.mxDate=='Invalid Date'){
      this.setState({mxDate: null});
    }

    //console.log('cat== '+this.state.sel_category);
    //console.log('mx_d== '+this.state.mxDate);
    // console.log('sel_user_id == '+this.state.sel_user_id);
    // console.log('sel_user_id2 == '+this.state.sel_user_id2);
    //let pst_user_id=(typeof this.state.sel_user_id!='undefined')?this.state.sel_user_id:this.state.sel_user_id2;
    let pst_user_id=(this.state.sel_user_id2!='')?this.state.sel_user_id2:this.props.sel_user_id;

    //console.log(this.props);
    //console.log('pst_user_id 2== '+pst_user_id);


    // console.log();

    return ( 
      
      <div className="row">

        {/*================strat_left_div==================*/}
        
        <div className="col-md-6 ">
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                  <h4 className="card-title">Leave Apply</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <div className="row"> 
                  
                <div className="col-lg-12">
                  <label>Name </label> 
                  {
                    sel_user_id!= ''
                    ? ( 
                      <input type="text"  className="form-control" value={sel_user_name} autoComplete="off"/>
                    )
                    :     
                     
                    <Autocomplete
                      options={list_employees}
                      getOptionLabel={(option) => (option.first_name?option.first_name:'')+' '+(option.last_name?option.last_name:'')+' / '+(option.emp_code?option.emp_code.slice(-4):'')+' / '+(option.email?option.email:'')+' / '+(option.phone?option.phone:'')}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder='Select Employees'
                          label="Employees"
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          autoComplete="off"
                        />
                      )}
                      onChange={onInputChange_emp}
                    />                     
                  } 
                 
                </div>
                <div className="col-lg-12" style={{'marginTop':10}}> 
                  <label>Category</label>
                  <select className="form-control" name="sel_category_id" value={this.state.sel_category} onChange={e => onInputChange_category(e)} >
                    <option value=''>--Select--</option>
                    { 
                      lists_categorys1.map((val_lpcat, key_cat) => {  
                        if(employee_gender==val_lpcat.lpcategory_gender || val_lpcat.lpcategory_gender=='Both'){ 
                          return (
                            <option key={key_cat} data-attr-policy_cat_id={val_lpcat.lpcategory_pk_id} value={val_lpcat.leave_cat_id}>{val_lpcat.leave_cat_code}</option> 
                          ); 
                        }
                        
                      })
                    }   
                  </select>  
                </div>
                  
                {/* <div className="col-sm-12">&nbsp;</div> */}
                
                <div className="col-md-6" style={{display:'none'}}>
                  <div className="form-check form-check-inline" >
                    <input onChange={(e) => onInputChange_radio_1(e)} className="form-check-input" type="radio" name="is_halfday" id="inlineRadio1" value="0" defaultChecked={{checked:(this.state.sel_full_half_day==0)?'true':'false'}} />
                    <label className="form-check-label">Full Day</label>
                  </div>
                  <div className="form-check form-check-inline" >
                    <input onChange={(e) => onInputChange_radio_2(e)} className="form-check-input" type="radio" name="is_halfday" id="inlineRadio2" value="1"/>
                    <label className="form-check-label">Half Day</label>
                  </div>
                </div> 
                <div className="col-md-6"> 
                  <span style={{display:(this.state.sel_full_half_day==0)?'none':''}}>
                    <label>Type</label>
                    <select className="form-control" onChange={(e) => onInputChange_type(e)}>
                      <option value=''>--Select Type--</option>
                      <option value='First Half'>First Half</option>
                      <option value='Second Half'>Second Half</option>
                    </select>
                    </span>  
                </div>     

                <div className="col-sm-12">&nbsp;</div>

                <div className="col-sm-6">
                   
                  <label>Date*</label>
                  <DatePicker 
                    selected={this.state.toDate}
                    minDate={this.state.minToDate}
                    maxDate={this.state.toDate}
                    onChange={(e) => toDateChage(e)}
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="To Date"
                    name="to_dt"
                    dropdownMode="select"
                    autoComplete="off" 

                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown  
                  />
                </div>

                <div className="col-sm-6" style={{display:'none'}}>
                  <label>From*</label>
                  
                  <DatePicker
                    onChange={(e) => fromDateChage(e)} 
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="From Date"
                    selected={this.state.fromDate}
                    minDate={this.state.minDate}
                    // maxDate={this.state.mxDate}
                    name="from_dt"
                    dropdownMode="select"
                    autoComplete="off"
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown   
                  />
                </div>

                <div className="col-sm-12" style={{'marginTop':10}}>
                  <label>Reason*</label>
                  <textarea
                    className="form-control"
                    name="reason"
                    value={this.state.sel_reason}
                    onChange={(e) => onInputChange_reason(e)}
                    required
                  />
                </div>

                
                
                <div className="col-lg-12" align="center" style={{'marginTop':20}}>
                  <button type="submit" className="btn btn-success" id="btn_apply_leave" style={{display:(this.state.btn_hideshow==0)?'none':''}}>Submit</button>
                </div> 
              </div> 
            </div> 
          </div>
          </form>
        </div>
        
        {/*================end_left_div==================*/} 

        {/*================strat_right_div==================*/}
        <div className="col-md-6">
          <Emplyee_leave_list_table 
            sel_emp_id={pst_user_id} 
            company_id={Apiconnect.get_company_id()} 
            is_adm={this.state.is_admin}
            employee_gender={employee_gender}
            refresh={this.state.is_refresh}
            sel_shortleave_type={this.state.sel_shortleave_type}
          />
        </div>
        {/*================end_right_div==================*/} 
 

      </div> 
    );
  }
}
