import React, { useState, useEffect } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Axios from 'axios';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import { confirmAlert } from 'react-confirm-alert'; // Import

function Team_member_leaves() {

   const alert = useAlert();
   const localuser = JSON.parse(localStorage.getItem('user'));
   let emp = Apiconnect.decrypt(localuser.id);



   const leaveApprove = e => {

      confirmAlert({
         customUI: ({ onClose }) => {
           return (
            <div className='react-confirm-alert'>
               <div className='react-confirm-alert-body'>
                  <h1>Are you sure?</h1>
                  <p>You want to Approve this Leave?</p>
                  <p><textarea name="mr_comments" style={{width:'100%'}} id="mr_comments" placeholder='Approve/Reject Note' ></textarea></p>
                  <button class="btn badge badge-pill badge-danger" onClick={onClose}>Close</button>
                  <button class="btn badge badge-pill badge-success" style={{marginLeft:20}}
                  onClick={() => {
                     let approval_note = document.getElementById("mr_comments").value;
                     // console.log(approval_note);
                     // if(approval_note.trim() == ''){
                     //   alert.error('Please write reason to Approve leave');
                     //   return false;
                     // }
                     let info = { id: e, status: 1, approved_by: emp, approval_note:approval_note };
                     let info_enc = Apiconnect.encrypt_obj(info);
                     const inf_a = { enc: info_enc };
                    // Axios.post(`http://localhost:3050/api/employee_leave/updatestatus`,inf_a).then((response) => {
                     Apiconnect.postData("employee_leave/updatestatus/", inf_a).then((response) => {
                        alert.success(response.data.message);
                        leavedata();
                     });
                     onClose();
                  }}
                  >
                  Yes, Approve Leave!
                  </button>
               </div>
            </div>
           );
         }
       });

     

   }

   const leaveCancle = e => {

      confirmAlert({
         customUI: ({ onClose }) => {
           return (
            <div className='react-confirm-alert'>
               <div className='react-confirm-alert-body'>
                  <h1>Are you sure?</h1>
                  <p>You want to Reject this Leave?</p>
                  <p><textarea name="mr_comments" style={{width:'100%'}} id="mr_comments" placeholder='Approve/Reject Note' ></textarea></p>
                  <button class="btn badge badge-pill badge-danger" onClick={onClose}>Close</button>
                  <button class="btn badge badge-pill badge-warning" style={{marginLeft:20}}
                  onClick={() => {
                     let approval_note = document.getElementById("mr_comments").value;
                     // console.log(approval_note);
                     if(approval_note.trim() == ''){
                       alert.error('Please write reason to Reject leave');
                       return false;
                     }
                     let info = { id: e, status: 2, approved_by: emp, approval_note:approval_note };
                     let info_enc = Apiconnect.encrypt_obj(info);
                     const inf_a = { enc: info_enc };
                    // Axios.post(`http://localhost:3050/api/employee_leave/updatestatus`,inf_a).then((response) => {
                     Apiconnect.postData("employee_leave/updatestatus/", inf_a).then((response) => {
                        alert.success(response.data.message);
                        leavedata();
                     });
                     onClose();
                  }}
                  >
                  Yes, Reject Leave!
                  </button>
               </div>
            </div>
           );
         }
       });

   }

   useEffect(() => { leavedata();empdata(); }, []);
   const [leaveList, setleaveList] = useState([]);
   const [empList, setEmpList] = useState([]);

   const leavedata = () => {
       Apiconnect.getData(`employee_leave/leavereportingdata/${emp}`).then((response) => {
     // Axios.get(`http://localhost:3050/api/employee_leave/leavereportingdata/${emp}`,).then((response) => {
         let _xtract = Apiconnect.decrypt_obj(response.data.data);
         setleaveList(_xtract.reverse());
         // console.log(_xtract);
      });
   }
   const empdata = () => {
      Apiconnect.getData(`employee_dailydata/employeereportingdata/${emp}`).then((response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setEmpList(_xtract.reverse());
      //   console.log(_xtract);
      });
    }

    var date = new Date();
    const [from_date,setFromDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [to_date,setToDate] = useState(new Date());

    const leaveListExcel = leaveList.map((item) => ({
      fullname: `${item.first_name} ${item.last_name}`,
      applied_on: item.applied_on,
      name: item.name,
      reason:item.reason,
      leave_count:item.leave_count,
      day_type: item.day_typ,
      date:`${item.leave_start_date1} ${item.leave_start_date1}`,
      status: item.status
    }))

    /* --------------------exceldownload------------------- */
    function getSheetData(data, header) {
      var fields = Object.keys(data[0]);
      var sheetData = data.map(function (row) {
        return fields.map(function (fieldName) {
          return row[fieldName] ? row[fieldName] : "";
        });
      });
      sheetData.unshift(header);
      return sheetData;
    }
    
    const saveAsExcel = () => {
      let header = ["fullName", "applied_on", "name", "reason", "leave_count", "day_type", "date", "status"]
    
      XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        const sheetData = getSheetData(leaveListExcel, header);
        const totalColumns = sheetData[0].length;
    
        sheet1.cell("A1").value(sheetData);
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);
        sheet1.row(1).style("bold", true);
        // sheet1.column("D").value("numberFormat")
        sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
        range.style("border", true);
        return workbook.outputAsync().then((res) => {
          saveAs(res, "TeamLeaves.xlsx");
        });
      });
    }

   return (
      <>
         <div id="content-page" className="content-page">
            <div className="container-fluid">


               <div className="row">
                  <div className="col-sm-12">
                     <div className="iq-card">
                        <div>
                           <h4 className="card-title hd">Team Member Leaves</h4>
                        </div>
                        <div className="iq-card-body">

                           {/* <div className="row p_t_15">

                              <div className="col-md-3">
                                 From :  <br /><DatePicker
                                    selected={from_date}
                                    onChange={e => setFromDate(e)}
                                    className="form-control"
                                    dateFormat='yyyy-MM-dd'
                                    placeholderText="From Date"
                                    name="from_date"
                                    required=""
                                    showMonthDropdown
                                    showYearDropdown
                                    scrollableYearDropdown
                                    scrollableMonthDropdown
                                    yearDropdownItemNumber={100}
                                    dropdownMode="select"
                                 />
                              </div>
                              <div className="col-md-3">
                                 TO :  <br /> <DatePicker
                                    selected={to_date}
                                    onChange={e => setToDate(e)}
                                    className="form-control"
                                    dateFormat='yyyy-MM-dd'
                                    placeholderText="To Date"
                                    name="to_date"
                                    required=""
                                    showMonthDropdown
                                    showYearDropdown
                                    scrollableYearDropdown
                                    scrollableMonthDropdown
                                    yearDropdownItemNumber={100}
                                    dropdownMode="select"
                                 />
                              </div>
                              <div className="col-md-3">
                                 User : <br />
                                 <select className="form-control" name="emp_id">
                                    <option value="">Select User</option>
                                    {empList.map((val, key) => {
                                       return (
                                          <option key={key} value={val.id}>{val.first_name} {val.last_name}</option>
                                       );
                                    })}
                                 </select>
                              </div>
                              <div className="col-md-2">
                                 <input type="button" className="btn btn-success" value="Submit" style={{ marginTop: "23px" }} />
                              </div>

                           </div> */}

                           <div className="row">

                              <div className="table-responsive">
                              <div className='row'>
                                 <div className='col-md-6'></div>
                                    <div className='col-md-6 mb-2'>
                                       <button className='btn btn-warning float-right' onClick={() => saveAsExcel()}><i class="fa fa-download" aria-hidden="true"></i>Team Leave List Excel</button>
                                    </div>
                                 </div>
                                 <table id="user-list-table" className="table table-striped table-bordered mt-4" role="grid" aria-describedby="user-list-page-info">
                                    <thead>
                                       <tr className="clr" >
                                          <th>#</th>
                                          <th>Emp. Name</th>
                                          <th>Applied On</th>
                                          <th>Leave Type</th>
                                          <th>Reason</th>
                                          <th>Days</th>
                                          <th>Half Day Type</th>
                                          <th>Date</th>
                                          <th>Opening</th>
                                          <th>Available</th>
                                          <th>Status</th>
                                          <th>Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {leaveList.map((val, key) => {

                                          var action1 = '';
                                          if (val.status === 0) {
                                             var status = <div className=" badge badge-pill badge-warning">Pending</div>;
                                             var action = <div className="btn badge badge-pill badge-success" onClick={e => leaveApprove(val.id)}>Approve</div>;
                                             var action1 = <div className="btn badge badge-pill badge-danger" onClick={e => leaveCancle(val.id)}>Reject</div>;
                                          }
                                          if (val.status === 1) {
                                             var status = <div className=" badge badge-pill badge-success">Approved</div>;
                                             var action = <div className="btn badge badge-pill badge-danger" onClick={e => leaveCancle(val.id)}>Reject</div>;
                                          }
                                          if (val.status === 2) {
                                             var status = <div className=" badge badge-pill badge-danger">Rejected</div>;
                                             var action = <div className="btn badge badge-pill badge-success" onClick={e => leaveApprove(val.id)}>Approve</div>;
                                          }

                                          return (
                                             <tr key={key}>
                                                <th scope="row">{key + 1}</th>
                                                <td>{val.first_name} {val.last_name}</td>
                                                <td>{val.applied_on}</td>
                                                <td>{val.name}</td>
                                                <td>{val.reason}</td>
                                                <td>{val.leave_count}</td>
                                                <td style={{textAlign:'center'}}>{val.day_typ ? val.day_typ:'-'}</td>
                                                <td>{val.leave_start_date1} to {val.leave_end_date1}</td>
                                                <td></td>
                                                <td></td>
                                                <td>{status}</td>
                                                <td>{action} {action1}</td>
                                             </tr>
                                          );
                                       })}
                                    </tbody>
                                 </table>
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div>


      </>
   )
}

export default Team_member_leaves
