import React, { useState, useEffect, useRef } from "react";
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loadder from "./Loadder";
import Axios from "axios";
import { CSVLink } from "react-csv";
import Modal from "react-modal";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import moment from 'moment'

function Monthly_attendance_report() {
  const localuser = JSON.parse(localStorage.getItem("user"));
  let emp = Apiconnect.decrypt(localuser.id);

  const csvLink = useRef();
  // const [from_date,setFromDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [from_date, setFromDate] = useState(new Date());
  const [emp_id, setempid] = useState(null);
  const [modalLodder, setModalLodder] = useState(false);
  const [dwnEmpAttendanceList, setDwnEmpAttendanceList] = useState([]);

  const onInputChange = (e) => {
    setempid(e.target.value);
  };

  useEffect(() => {
    empdata();
    get_leave_category_list();
  }, []);
  const [empList, setEmpList] = useState([]);
  const [dailyAttendanceList, setDailyAttendanceList] = useState([]);


  const [list_leave_category, setlist_leave_category] = useState([]); 
  const get_leave_category_list =async () => {
    let data_pstlcl = { company_id: Apiconnect.get_company_id()};
    await Apiconnect.postData(`leave_manage/get_all_leave_category_lists`,data_pstlcl).then( (response) => {
      setlist_leave_category(response.data.data);
    });
  };

  const empdata = () => {
    Apiconnect.getData(`employee/getAll/${Apiconnect.get_company_id()}`).then(
      (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setEmpList(_xtract.reverse());
      }
    );
  };

  const submit = () => {
    if (from_date != null) {
      var from = format(from_date, "yyyy-MM");
    }

    let info = {
      company_id: Apiconnect.get_company_id(),
      selected_date: from,
      emp_id: emp_id,
      group_id: selgrp
    };
    // console.log(info);
    let info_enc = Apiconnect.encrypt_obj(info);
    const inf_a = { enc: info_enc };

    setModalLodder("true");
     Apiconnect.postData(`employee/monthlyAttendaceinfo`,inf_a).then((response) => {
    // Axios.post(
    //   `http://localhost:3050/api/employee/monthlyAttendaceinfo`,
    //   inf_a
    // ).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
    //   console.log(_xtract);
      setDwnEmpAttendanceList(_xtract.reverse());
      setModalLodder(false);

      csvLink.current.link.click();
    });
  };

  const customStyles1 = {
    content: {
      top: "55%",
      width: "60%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "unset ",
      backgroundColor: "unset ",
    },
  };
  
  // console.log(dwnEmpAttendanceList)

  
  const local_user = JSON.parse(localStorage.getItem('user'));
  const [companyaddress, setCompanyData] = useState([])
  const getCompany = () => {         
  Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then((response) => {
        let ern = Apiconnect.decrypt_obj(response.data.data);
        setCompanyData(ern[0].address_one)
        
     });
  };
  useEffect(() => {getCompany()}, [])
  /* ------------------------------download in excel---------------------- */
  function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  function nextChar(c) {
    var u = c.toUpperCase();
    if (same(u,'Z')){
        var txt = '';
        var i = u.length;
        while (i--) {
            txt += 'A';
        }
        return (txt+'A');
    } else {
        var p = "";
        var q = "";
        if(u.length > 1){
            p = u.substring(0, u.length - 1);
            q = String.fromCharCode(p.slice(-1).charCodeAt(0));
        }
        var l = u.slice(-1).charCodeAt(0);
        var z = nextLetter(l);
        if(z==='A'){
            return p.slice(0,-1) + nextLetter(q.slice(-1).charCodeAt(0)) + z;
        } else {
            return p + z;
        }
    }
}

function nextLetter(l){
    if(l<90){
        return String.fromCharCode(l + 1);
    }
    else{
        return 'A';
    }
}

function same(str,char){
    var i = str.length;
    while (i--) {
        if (str[i]!==char){
            return false;
        }
    }
    return true;
}

  const saveAsExcel =async () => {
    if (from_date != null) {
      var from = format(from_date, "yyyy-MM");
    }
    let info = {
      company_id: Apiconnect.get_company_id(),
      selected_date: from,
      emp_id: emp_id,
    };
    let info_enc = Apiconnect.encrypt_obj(info);
    const inf_a = { enc: info_enc };

    let header = ["Employee_id",	"Name",	"Department",	"Designation",	"Company"];
    let list_array1=[1,2,3,4];

    let date_current1=new Date(from+'-01');
    var date_lastDay1 = new Date(date_current1.getFullYear(), (date_current1.getMonth() + 1), 0);
    let date_lastDay2=moment(new Date(date_lastDay1)).format("DD"); 
    let date_year1=moment(new Date(date_lastDay1)).format("YYYY");
    let date_month1=moment(new Date(date_lastDay1)).format("MM");

    let cnt_head1=5;
    for(var i_day1=1;i_day1<=date_lastDay2;i_day1++){
      let date_current2=moment(new Date(date_year1+'-'+date_month1+'-'+i_day1)).format("DD-MM-YYYY");

      header[cnt_head1]=date_current2; 
      cnt_head1++;
    }

 
    header[cnt_head1]='Total Half Day Leaves'; 
    header[cnt_head1+1]='Total Full Day Leaves'; 
    header[cnt_head1+2]='Total Loss Of Pay';
    header[cnt_head1+3]='Total Present'; 
    header[cnt_head1+4]='Total Absent';
    header[cnt_head1+5]='Total Paid Days';
    
    // console.log(info);
    let array_list_att1=[];
    await Apiconnect.postData(`employee/monthlyAttendaceinfo`,inf_a).then((response) => {
      array_list_att1= Apiconnect.decrypt_obj(response.data.data);
    });

    // console.log(array_list_att1[0]);
    //return false;

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(list_array1,header);
      const totalColumns = sheetData[0].length;

      sheet1.cell("A1").value(sheetData);

      let cnt_col2=2;
      for(let cnt_col1=0;cnt_col1<array_list_att1.length;cnt_col1++){
        let array_list_att2=array_list_att1[cnt_col1]; 
        sheet1.cell("A"+cnt_col2).value(array_list_att2['Employee_id']); //--Employee_id	 
        sheet1.cell("B"+cnt_col2).value(array_list_att2['Name']); //--Name
        sheet1.cell("C"+cnt_col2).value(array_list_att2['Department']); //--Department
        sheet1.cell("D"+cnt_col2).value(array_list_att2['Designation']); //--Designation
        sheet1.cell("E"+cnt_col2).value(array_list_att2['Company']); //--Company

        let cnt_char1="F"; 
        for(var i_day1=1;i_day1<=date_lastDay2;i_day1++){
          let date_current2=moment(new Date(date_year1+'-'+date_month1+'-'+i_day1)).format("DD-MM-YYYY");
          
          let val_excelcol=array_list_att2[date_current2];
          
          sheet1.cell(cnt_char1+cnt_col2).value(val_excelcol); //--date_wise_data 

          cnt_char1=nextChar(cnt_char1); 
        }

        var cal_tot_present1=array_list_att2['cal_tot_present'];
        var cal_tot_absent1=array_list_att2['cal_tot_absent'];
        var cal_tot_halfday1=array_list_att2['cal_tot_halfday'];
        var cal_tot_fullday1=array_list_att2['cal_tot_fullday'];
        var cal_tot_lop1=array_list_att2['cal_tot_lop'];
        var cal_tot_paidday1=array_list_att2['cal_tot_paidday'];

        sheet1.cell(cnt_char1+cnt_col2).value(cal_tot_halfday1); //--Total Half Leaves
        var cnt_char1_1=nextChar(cnt_char1);
        sheet1.cell(cnt_char1_1+cnt_col2).value(cal_tot_fullday1); //--Total Full Leaves
        var cnt_char1_2=nextChar(cnt_char1_1);
        sheet1.cell(cnt_char1_2+cnt_col2).value(cal_tot_lop1); //--Total Loss Of Pay
        var cnt_char1_3=nextChar(cnt_char1_2);
        sheet1.cell(cnt_char1_3+cnt_col2).value(cal_tot_present1); //--Total Present 
        var cnt_char1_4=nextChar(cnt_char1_3);
        sheet1.cell(cnt_char1_4+cnt_col2).value(cal_tot_absent1); //--Total Absent
        var cnt_char1_5=nextChar(cnt_char1_4);
        sheet1.cell(cnt_char1_5+cnt_col2).value(cal_tot_paidday1); //--Total Paid Days   
        
        cnt_col2++;
      } //--end_for

      // return false;

      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);  
      return workbook.outputAsync().then((res) => {
        saveAs(res, "monthlyattendance.xlsx"); 
      });
    });
  }
  
  const saveAsExcel_old = () => {
    if (from_date != null) {
      var from = format(from_date, "yyyy-MM");
    }

    let info = {
      company_id: Apiconnect.get_company_id(),
      selected_date: from,
      emp_id: emp_id,
    };
    let info_enc = Apiconnect.encrypt_obj(info);
    const inf_a = { enc: info_enc };

     Apiconnect.postData(`employee/monthlyAttendaceinfo`,inf_a).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      let header = ["Employee_id",	"Name",	"Department",	"Designation",	"Company"];
      
      let date_current1=new Date(from+'-01');
      var date_lastDay1 = new Date(date_current1.getFullYear(), (date_current1.getMonth() + 1), 0);
      let date_lastDay2=moment(new Date(date_lastDay1)).format("DD"); 
      let date_year1=moment(new Date(date_lastDay1)).format("YYYY");
      let date_month1=moment(new Date(date_lastDay1)).format("MM");

      let cnt_head1=5;
      for(var i_day1=1;i_day1<=date_lastDay2;i_day1++){
        let date_current2=moment(new Date(date_year1+'-'+date_month1+'-'+i_day1)).format("DD-MM-YYYY");

        header[cnt_head1]=date_current2; 
        cnt_head1++;
      }
 
      XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(_xtract, header);
      const totalColumns = sheetData[0].length;
  
      sheet1.cell("A1").value(local_user.company_name);
      sheet1.cell("A2").value(companyaddress)
      sheet1.cell("A3").value("Daily Early Departure Report");
      // sheet1.cell("A5").value(sheetData);

      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true); 
      range.style("border", true);

      sheet1.range("A1:K1").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true);
      sheet1.range("A2:K2").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
      sheet1.range("A3:K3").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
      sheet1.range("A5:AL5").style({horizontalAlignment: "center", verticalAlignment: "center", }).style("bold", true).style("fill", "BFBFBF");

      return workbook.outputAsync().then((res) => {
        saveAs(res, "monthlyattendance.xlsx");
      });
    });


    });
  }

  //get group list
  const [grplist, setGrouplist] = useState([])
  const [selgrp, setSelgrp] = useState("")
  const getgrouplist = async () => {
    const inf_a = {company_id : Apiconnect.get_company_id()};
    await Apiconnect.postData(`leave_manage/leave_group_list`, inf_a).then(res => setGrouplist(res.data.data)) 
}
    useEffect(() => {getgrouplist()}, [])

    // console.log(selgrp)

  return (
    <>
      <div id="content-page" className="content-page">
        <div className="container-fluid">
          <div className="row">
            {/* ////////////------------>Lodder<------------////////////////// */}
            <div>
              <Modal isOpen={modalLodder} style={customStyles1}>
                {<Loadder />}
              </Modal>
            </div>
            {/* ////////////------------>Lodder<------------////////////////// */}

            <div className="col-sm-12">
              <div className="iq-card">
                <div>
                  <h4 className="card-title hd">Monthly Attendance</h4>
                </div>
                <div className="iq-card-body">
                  <div className="row">
                    <div className="col-md-2">
                      <label>Date</label>
                      <DatePicker
                        selected={from_date}
                        onChange={(date) => setFromDate(date)}
                        maxDate={new Date()}
                        dateFormat="MM/yyyy"
                        className="form-control"
                        showMonthYearPicker
                        dropdownMode="select"
                        autoComplete="off"
                      />
                    </div>

                    <div className="col-md-3">
                      <label>User</label>
                      <select
                        className="form-control"
                        name="emp_id"
                        value={emp_id}
                        onChange={(e) => onInputChange(e)}
                      >
                        <option value="">Select User</option>
                        {empList.map((val, key) => {
                          return (
                            <option key={key} value={val.id}>
                              {val.first_name} {val.last_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-2">
                        <label>Group</label>
                        <select className="form-control" name="selgrp" value={selgrp} onChange={(e) => setSelgrp(e.target.value)}>
                            <option value="" disabled selected>select group</option>
                            {
                                grplist.map((item, index) => (
                                    <option key={index} value={item.leavegroup_pk_id}>{item.leavegroup_name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-lg-12">&nbsp;</div>
                    <div className="col-md-3">
                      <input
                        type="button"
                        className="btn btn-success"
                        value="Submit And Download CSV"
                        style={{ marginTop: "23px" }}
                        onClick={(e) => submit(e)}
                      />
                      <CSVLink
                        data={dwnEmpAttendanceList}
                        filename="employee-attendance.csv"
                        className="hidden"
                        ref={csvLink}
                        target="_blank"
                      />
                    </div>
                    <div className="col-md-3">
                      <button className="btn btn-warning" style={{ marginTop: "23px" }} onClick={() => saveAsExcel()}>Download in Excel</button>
                    </div>
                  </div>

                  <div className="row" style={{ marginTop: "25px" }}>
                    <div className="col-lg-12">
                      <div className="iq-card"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Monthly_attendance_report;
