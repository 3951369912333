import React, { Component } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { withAlert } from 'react-alert';
import { useAlert } from "react-alert";
import '../pages/admin.css';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import Axios from 'axios';


class Employee_manage_reimbursement extends Component {
    constructor(props) {
        super(props);
        this.state = { emp_info: '', reimbursement_head: [] };
        this.getreimbursementList();
        // this.history =useHistory();
    }

    getreimbursementList = () => {
        var empid = this.props.match.params.id;
        Apiconnect.getData(`employee_salary/getreimbursementhead/${empid}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            this.setState({ reimbursement_head: _xtract });
        });
    };

    onInputChange_remb = (e, i) => {
        let ki = i.keyy;
        let markers = this.state.reimbursement_head;
        markers[ki] = { ...markers[ki], amount: e.currentTarget.value };
        this.setState({ reimbursement_head: markers });
    };

    submitForm = async e => {
        const localuser = JSON.parse(localStorage.getItem('user'));
        let employee_id = Apiconnect.decrypt(localuser.id); 
        console.log(employee_id);
        //const alert = useAlert();       
        e.preventDefault();
        var empid = this.props.match.params.id;
        console.log(this.state.reimbursement_head);
        // return false;
        let remb_head_enc = Apiconnect.encrypt_obj(this.state.reimbursement_head);
        const inf_a = { enc_remb: remb_head_enc, emp_id: empid,employee_id:employee_id  };

        await Apiconnect.postData("employee_salary/reimbursementcreate", inf_a).then((response) => {
            // console.log(response);
           // await Axios.post(`http://localhost:3050/api/tush/create`, inf_a).then((response) => {    


            this.props.alert.success(response.data.message)


        });
    };

    // Page1 = () => {
    //     var empid = this.props.match.params.id;
    //     this.history.push(`/employee_manage/${empid}`);
    // }
    // Page2 = () => {
    //     var empid = this.props.match.params.id;
    //     this.history.push(`/employee_manage_info/${empid}`);
    // }
    // Page3 = () => {
    //     var empid = this.props.match.params.id;
    //     this.history.push(`/employee_manage_bank/${empid}`);
    // }
    // Page4 = () => {
    //     var empid = this.props.match.params.id;
    //     this.history.push(`/employee_manage_employement/${empid}`);
    // }
    // Page5 = () => {
    //     var empid = this.props.match.params.id;
    //     this.history.push(`/employee_manage_reimbursement/${empid}`);
    // }
    // Page6 = () => {
    //     var empid = this.props.match.params.id;
    //     this.history.push(`/employee_manage_sal/${empid}`);
    // }

    render() {
        const alert = this.props.alert;

        return (

            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h4 className="card-title">Employee Reimbursement <span style={{ fontSize: '15px', color: 'blue' }}>Stage 5 to 6</span></h4>
                            </div>
                        </div>
                        <div className="iq-card-body">
                            <div className="bs-example">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <Link className="nav-link " data-toggle="tab" to={`/employee_manage/${this.props.match.params.id}`} >Personal</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link " data-toggle="tab" to={`/employee_manage_info/${this.props.match.params.id}`} >Other Details</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link " data-toggle="tab" to={`/employee_manage_employement/${this.props.match.params.id}`} >Employement Details</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" data-toggle="tab" to={`/PreviousEmployment/${this.props.match.params.id}`} >Previous Employment</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link active" data-toggle="tab" to={`/employee_manage_reimbursement/${this.props.match.params.id}`} >Reimbursement Info</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" data-toggle="tab" to={`/employee_manage_qualification/${this.props.match.params.id}`} >Educational Qualification</Link>
                                    </li>
                                </ul>
                            </div>
                            <form onSubmit={e => this.submitForm(e)}>
                                <div className="border">
                                    <div className="row">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th width="120" scope="col">#</th>
                                                    <th scope="col">Head</th>
                                                    <th scope="col"></th>
                                                    <th width="50%" scope="col">Set Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ background: "#948a9e", padding: "0.3rem" }} colSpan="5"><h6 style={{ color: "#fff", 'fontWeight': "bold" }}>Reimbursement Head</h6></td>
                                                </tr>

                                                {this.state.reimbursement_head.map((valy, keyy) => {
                                                    return (
                                                        <tr key={keyy}>
                                                            <td>{keyy + 1}</td>
                                                            <td>{valy.name}  </td>
                                                            <td>

                                                            </td>

                                                            <td>
                                                                <input name={valy.id} placeholder="Amount" value={valy.amount} className="form-control" onChange={e => this.onInputChange_remb(e, { keyy })} />
                                                            </td>
                                                        </tr>
                                                    );
                                                })}

                                                <tr style={{ background: "#948a9e", padding: "0.3rem" }}>
                                                    <td colSpan="3"><h6 style={{ color: "#fff", 'fontWeight': "bold" }}>Total</h6></td>
                                                    <td>{this.state.reimbursement_head.reduce((a, v) => a = Number(a) + Number(v.amount), 0)}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <button type="submit" name="id" className="btn btn-primary mr-2">  Proceed </button>
                                        <Link className="btn btn-info mr-2" to={`/employee_manage_employement/${this.props.match.params.id}`} >Previous</Link>
                                        <Link className="btn btn-success mr-2" to={`/employee_manage_qualification/${this.props.match.params.id}`} >Next</Link>
                                        <Link style={{ float: 'right' }} className="btn btn-secondary" to="/employee_manage_list" >Back to List</Link>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        )

    }

}

export default withAlert()(Employee_manage_reimbursement);