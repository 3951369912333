import React, { useCallback, useEffect, useRef, useState } from 'react'
import Apiconnect from "../services/Apiconnect.js";
import { GoogleMap, Marker, useJsApiLoader, InfoBox, StandaloneSearchBox, LoadScript } from '@react-google-maps/api';
import Geocode from "react-geocode";
import { useAlert } from "react-alert";
import { padding } from '@mui/system';

const Webview_punch_location = (props) => {

    const localuser = JSON.parse(localStorage.getItem("user"));
    let employee_id = props.match.params.id;
    const alert = useAlert();
    
    const [coordinates, setCoordinates] = useState({lat: 20.296059, lng: 85.824539})
    const [address, setAddress] = useState("")
    const [defaultLocation, setDefaultLocation] = useState("jajpur")
    const [list, setList] = useState([])

    const inputRef = useRef()
    const markerRef = useRef(null);

    const [ libraries ] = useState(['places']);

    const getEmpDefaultLocation = () => {
        Apiconnect.postData(`emp_default_punch_location/getEmpDefaultLocation/${employee_id}`).then(res => {
            if(res.data.data.length > 0){
                if(res.data.data[0].lattitude != "" && res.data.data[0].longitude != "" && res.data.data[0].address != ""){
                    setCoordinates({lat: res.data.data[0].latitude, lng: res.data.data[0].longitude})
                    setDefaultLocation(res.data.data[0].address)
                }
                setList(res.data.data)
            }
            
        })
    }
    useEffect(() => {getEmpDefaultLocation()}, [])
    
    console.log(list)

    function onDragEnd(...args) {
        setCoordinates({lat: markerRef.current.position.lat(), lng: markerRef.current.position.lng()});
      }

    const onMarkerLoad = useCallback(
        marker => {
            markerRef.current = marker;
        },
        [onDragEnd]
    );

    const handlePlaceChanged = () => { 
        const [ place ] = inputRef.current.getPlaces();
        if(place) { 
            setCoordinates({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()});
        } 
    }

    Geocode.setApiKey("AIzaSyDYhFbq3EBeezplKWoyD7BYBjLEFJ6cEUE")
    Geocode.fromLatLng(String(coordinates.lat), String(coordinates.lng)).then(
        (response) => {
        setAddress(response.results[0].formatted_address);
        },
        (error) => {
            console.error(error);
        }
        );

    const handleAssign = () => {
        let inf = {employee_id: employee_id, address:address, latitude:coordinates.lat, longitude:coordinates.lng}
        Apiconnect.postData(`emp_default_punch_location/assignEmpLocation`, inf).then(res => {
            console.log("Location Assigned")
            alert.success("Punch Location Request Successful")
            getEmpDefaultLocation()
        })
    }

    const [locationList, setLocationList] = useState([])
    const getEmpAssignedLocationList = () => {
        Apiconnect.postData(`emp_default_punch_location/getAssignedLocationList/${employee_id}`).then(res => {
            setLocationList(res.data.data)
        })
    }
    useEffect(() => {getEmpAssignedLocationList()}, [])

    const handleApproveReject = (id) => {
        Apiconnect.postData(`emp_default_punch_location/approve_reject_AssignedLocation/${id}`).then(res => {
            getEmpAssignedLocationList()
            alert.success("Assigned Successfully")
        })
    }


  return (
    <>
        <div id="content-page" className="" style={{marginLeft:'unset !important',overflow: 'hidden',background: '#e2e2e2',padding: '20px 15px',minHeight: '100vh'}}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-lg-12 col-md-12" style={{padding:0}}>
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div style={{ float: "left" }} className="iq-header-title">
                                    <h4 className="card-title">Manage Punch Location</h4>
                                </div>
                            </div>
                            <div className='row m-2'>
                                <div className='col-md-6' style={{padding:0}}>
                                    <LoadScript googleMapsApiKey="AIzaSyDYhFbq3EBeezplKWoyD7BYBjLEFJ6cEUE" libraries={libraries}>
                                        <StandaloneSearchBox onLoad={ref => inputRef.current = ref} onPlacesChanged={handlePlaceChanged}>
                                            <input type="text" className='form-control' placeholder="Enter Location" value={defaultLocation} onChange={(e) => setDefaultLocation(e.target.value)}/>
                                        </StandaloneSearchBox>
                                        <GoogleMap
                                        mapContainerStyle={{width: "100%", height: "300px", margin: "0px"}}
                                        center={{lat: parseFloat(coordinates.lat), lng: parseFloat(coordinates.lng)}}
                                        zoom={14}
                                        // onClick={handleMapClick}
                                        >
                                            
                                            <Marker position={{lat: parseFloat(coordinates.lat), lng: parseFloat(coordinates.lng)}} draggable onDragEnd={onDragEnd}  onLoad={onMarkerLoad}/>
                                        </GoogleMap>
                                    </LoadScript>
                                </div>
                                <div className='col-md-6 bbox'>
                                    <div className='row'>
                                        <div className='col-md-12 form-group'>
                                            <label>Address</label>
                                            <input className='form-control' value={address}/>
                                        </div>
                                        <div className='col-md-12 form-group'>
                                            <div className='row'>
                                                <div className='col-md-6 form-group'>
                                                    <label>Latitude</label>
                                                    <input className='form-control' value={coordinates.lat}/>
                                                </div>
                                                <div className='col-md-6 form-group'>
                                                    <label>Longitude</label>
                                                    <input className='form-control' value={coordinates.lng}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 form-group'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <button type="button" className="btn btn-info" onClick={() => handleAssign()}>Request Location</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {list.length > 0 ?<div className='row m-2'>
                                <div className='col-md-12'>
                                <table  className="table table-striped table-bordered mt-1 center" role="grid" >
                                    <thead className="">
                                        <tr className="clr">
                                            <th scope="col">#</th>
                                            <th scope="col">Requested Punch Location</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            list.map((val, key) => {
                                                let btn = ''
                                                if(val.status == 1){
                                                    btn = <div className='badge badge-pill badge-success'>Approved</div>
                                                }else{
                                                    btn = <div className='badge badge-pill badge-warning'>Pending</div>
                                                }
                                                return(
                                                    <tr key={key}>
                                                        <td>{key+1}</td>
                                                        <td>{val.pre_approved_address}</td>
                                                        <td>{btn}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                </div>
                            </div> : null}
                            { locationList.length > 0 ? <div className='row m-2'>
                                <div className='col-md-12'>
                                    <h5>Manage Assigned Employee Punch Location</h5>
                                    <hr/>
                                </div>
                                <div className='col-md-12'>
                                <table  className="table table-striped table-bordered mt-1 center" role="grid" >
                                    <thead className="">
                                        <tr className="clr">
                                            <th scope="col">#</th>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col">Location</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            locationList.map((val, key) => {
                                                let btn = ''
                                                if(val.status == 1){
                                                    btn = <div className='badge badge-pill badge-success'>Approved</div>
                                                }else{
                                                    btn = <div className='badge badge-pill badge-warning'>Pending</div>
                                                }
                                                return(
                                                    <tr key={key}>
                                                        <td>{key+1}</td>
                                                        <td>{val.emp_name}</td>
                                                        <td>{val.pre_approved_address}</td>
                                                        <td>{btn}</td>
                                                        <td>
                                                            {val.status == 0 ? <button className='btn btn-sm btn-primary' onClick={() => handleApproveReject(val.edpl_id, val.status)}>Approve</button> : null} 
                                                            {val.status == 1 ? <button className='btn btn-sm btn-danger' onClick={() => handleApproveReject(val.edpl_id, val.status)}>Reject</button> : null} 
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                </div>
                            </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Webview_punch_location