import React, {useState, useEffect} from 'react'
import { useLocation } from "react-router-dom";
import Apiconnect from "../../services/Apiconnect.js";
import DatePicker from "react-datepicker";
import Axios from 'axios';
import { useHistory, useParams, withRouter } from "react-router-dom";
import { useAlert } from "react-alert";

const Employee_leave_report_format = () => {
    const { emp_id } = useParams();
    const company_id = Apiconnect.get_company_id()
 
    const [from_date, setFromDate] = useState(null);
    const [to_date, setToDate] = useState(null);

    const alert = useAlert();
    let history = useHistory();

    const localuser = JSON.parse(localStorage.getItem("user"));
    let user_id = Apiconnect.decrypt(localuser.id);
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);
    const AuthStr = localuser.accessToken;
  
    const [data_user_details, set_user_details] = useState([]);
    const [data_user_leavelist, set_user_leavelist] = useState([]);
    useEffect(() => {
        loadUser();
        get_employee_leave_categorywise_report();
    }, []);
    
    const loadUser = async () => { 
        await Apiconnect.getData(`employee/get/${user_id}`).then(response => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            set_user_details(_xtract)
        }); 
    };

    const data_pst={
        company_id:company_id,
        emp_id:emp_id
    }
    const get_employee_leave_categorywise_report = async () => { 
        await Apiconnect.postData(`employee_report/get_employee_leave_categorywises_report`,data_pst).then(response => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            set_user_leavelist(_xtract)
        }); 
    };

    let employee_name=(typeof data_user_details[0]!='undefined')?data_user_details[0].first_name+' '+data_user_details[0].last_name:'';
    
    // console.log(data_user_leavelist);
    //const [tbl_td, sel_tbl_td] = useState([]); 
    //sel_tbl_td('<td> LEAVE EARNED  </td> <td> LEAVE TAKEN  </td>  <td> LEAVE BALANCE </td>');
    // console.log(tbl_td);
  return (
    <>
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-lg-12 col-md-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div style={{ float: "left" }} className="iq-header-title">
                                    <h4 className="card-title">Employee Leave Report</h4>
                                </div>
                            </div>
                             
                            <div className="iq-card-body">
                                <div className='row'> 
                                    <div className="form-group col-md-3">
                                        <span>From</span>
                                        <DatePicker
                                            selected={from_date}
                                            onChange={e => setFromDate(e)}
                                            className="form-control"
                                            placeholderText="From"
                                            name="from_date"
                                            dateFormat="dd/MM/yyyy"
                                            showMonthYearPicker
                                            showFullMonthYearPicker
                                            showFourColumnMonthYearPicker
                                            autoComplete="off"
                                        />
                                    </div> 
                                    <div className="form-group col-md-3">
                                        <span>To</span>
                                        <DatePicker
                                            selected={to_date}
                                            onChange={e => setToDate(e)}
                                            className="form-control"
                                            placeholderText="To"
                                            name="to_date"
                                            dateFormat="dd/MM/yyyy"
                                            showMonthYearPicker
                                            showFullMonthYearPicker
                                            showFourColumnMonthYearPicker
                                            autoComplete="off"
                                        />
                                    </div>  
                                </div>


                                <div className='row'> 
                                    <div className="form-group col-md-12">
                                        <table className="table table-striped table-bordered table-sm center table-hover" role="grid" >
                                            <thead>
                                                <tr>
                                                    <td> <b>Employee Name</b> </td> 
                                                    <td> <b>Date of Birth</b> </td> 
                                                    <td> <b>Department</b> </td> 
                                                    <td> <b>Desgination</b> </td> 
                                                </tr> 
                                            </thead>
                                            <tbody> 
                                                <tr>
                                                    <td> {employee_name} </td> 
                                                    <td>   </td> 
                                                    <td>   </td> 
                                                    <td>   </td> 
                                                </tr> 
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="form-group col-md-12">
                                        <table className="table table-striped table-bordered table-sm center table-hover" role="grid" >
                                            <thead>
                                                <tr>
                                                    <td> <b>SrNo</b> </td>  
                                                    {
                                                        data_user_leavelist.map((val_dataleave1, key1) => {
                                                            return(
                                                                <td> <b>{val_dataleave1.leave_cat_title}</b> </td>  
                                                            )  
                                                        })
                                                    }
                                                </tr> 
                                                <tr>
                                                    <td> <b>SrNo</b> </td>  
                                                     
                                                </tr> 
                                            </thead>
                                            <tbody>  
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Employee_leave_report_format