import React, { useState, useEffect, Component,useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import '../pages/pages.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { CSVLink } from "react-csv";
import Loadder from "./Loadder";
import Modal from "react-modal";
import Axios from 'axios';
import Pagination from "react-js-pagination";
import FullScreenLoading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";


const Monthly_salary_list = () => {

   const locusr = JSON.parse(localStorage.getItem("user"));
   let user_typ = Apiconnect.decrypt(locusr.emp_typ);
   let company_name = locusr.company_name;
   const alert = useAlert();

   let history = useHistory();
   const csvLink = useRef();
   const csvLink2 = useRef();
   const csvLink3 = useRef();
   const csvLink4 = useRef();
   const csvLink5 = useRef();
   const csvLink6 = useRef();
   const csvLink7 = useRef();
   const hiddenFileInput = useRef(null);
   useEffect(()=>{
    getSalarySlipData();
    getEmpType();
    get_earning_head();
    getdeductList()
    getCompany()
    

    
   },[])

   const [salaryMonths, setSallaryData] = useState([]);
   const [salaryMonthsExcel, setSalaryMonthsExcel] = useState([]);
   const [dwnSalaryMonthsById, setdwnSalaryMonthsById] = useState([]);
   const [dwnEsiSalaryMonthsById, setdwnEsiSalaryMonthsById] = useState([]);
   const [dwnPfSalaryMonthsById, setdwnPfSalaryMonthsById] = useState([]);
   const [getExcelName, setExcelName] = useState([]);
   const [dwnDeductionMonthsById, setdwnDeductionMonthsById] = useState([]);
   const [modalEmpIsOpen, setEmpModalIsOpen] = useState(false);
   const [selectedFile, setSelectedFile] = useState(false);
   const [selectedMonth, setSelectedMonth] = useState(false);
   const [lodder, setLodder] = useState(false);
   const [modalIsOpen, setModalIsOpen] = useState(false);
   const [activePage, setActivePage] = useState(1);
   const [empCount, setempCount] = useState(0);
   const [loader, setLoader] = useState(false);
   const [List, setEmployeeList] = useState([]);
   const [sMonth, setSMonth] = useState('');
   const [sYear, setSYear] = useState('');
   const [searchTerm, setSearchTerm] = useState('')
   const [getDeductionSampleFile, setDeductionSampleFile] = useState([])
   const [getEarningSampleFile, setEarningSampleFile] = useState([])
   const [fullScreenLoader, setFullScreenLoader] = useState(false);
   const [addmonthModalIsOpen,setAddmonthModalIsOpen] = useState(false)
   const [dwnEarningMonthsById, setdwnEarningMonthsById] = useState([]);
   const [modalEarningIsOpen, setEarningModalIsOpen] = useState(false);
   const [salaryModalIsOpen, setSalaryModalIsOpen] = useState(false);
   const [empTypeList, setEmpType] = useState([]);
   const [empTypeId, setEmpTypeId] = useState([])
   const [salaryPdfModalIsOpen, setSalaryPdfModalIsOpen] = useState(false);
   const [earning_head, setEarning_head] = useState([]);
   const [deduct_head, setDeduct_head] = useState([])
   const [companyaddress, setCompanyData] = useState([])

   const getCompany = () => {         
    Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then((response) => {
          let ern = Apiconnect.decrypt_obj(response.data.data);
          setCompanyData(ern[0].address_one)
         
      });
  };

  const get_earning_head = () => {         
    Apiconnect.getData(`earning_head/getall/${Apiconnect.get_company_id()}`).then((response) => {
          let ern = Apiconnect.decrypt_obj(response.data.data);
          setEarning_head(ern)
        //   console.log(ern);
      });
  };
  const getdeductList = () => {
        //var l_user = JSON.parse(localStorage.getItem('user'));
        //var empid = l_user.emp_id;
      Apiconnect.getData(`deduction_head/getall/${Apiconnect.get_company_id()}`).then((response) => {           
          let _xtract = Apiconnect.decrypt_obj(response.data.data);
          setDeduct_head(_xtract)
      });
  };


   const getSalarySlipData = () => {
    Apiconnect.getData(`employee_monthly_salary/getSalarySlipAllMonths/${Apiconnect.get_company_id()}`).then((response) => {
       let _xtract = Apiconnect.decrypt_obj(response.data.data);
       var monthsList=_xtract;
       setSallaryData(monthsList);
       loadExcelData(monthsList)
    });
 };

 const getEmpType = () => {
  Apiconnect.getData(`common/getallemploymenttype`).then((response) => {
    let _xtract = Apiconnect.decrypt_obj(response.data.data);
    setEmpType(_xtract.reverse());
});
};

 const loadExcelData = async (monthsList) => {
  setFullScreenLoader(true)
         var newList = [];
         monthsList.map( async (vvx, kkx) => {
         var yearmon= vvx.year+''+vvx.month;

         newList.push(vvx);
          if(kkx === monthsList.length-1){
            setSalaryMonthsExcel(newList);
            setFullScreenLoader(false)
          } 
       });
 }
 
 const get_monthData = (yearMonth) => {         
   var salaryData = Apiconnect.getData(`employee_salary/getSalaryDataMonthWise/${yearMonth}/${Apiconnect.get_company_id()}`).then((response) => {
         let salData = Apiconnect.decrypt_obj(response.data.data);
          // console.log('salData',salData);
         return salData;
     });
     return salaryData;
 }; 

 const get_monthDataPdf = (yearMonth) => {         
  var salaryData = Apiconnect.getData(`employee_salary/getSalaryDataMonthWisePdf/${yearMonth}/${Apiconnect.get_company_id()}`).then((response) => {
        let salData = response.data;
        //  console.log('salData',salData);
        return salData;
    });
    return salaryData;
}; 

 const getEsiMonthData = (yearMonth) => {         
   var salaryData = Apiconnect.getData(`employee_salary/getEsiDataMonthWise/${yearMonth}/${Apiconnect.get_company_id()}`).then((response) => {
         let salData = Apiconnect.decrypt_obj(response.data.data);
         return salData;
     });
     return salaryData;
 }; 

 const getPfMonthData = (yearMonth) => {         
   var salaryData = Apiconnect.getData(`employee_salary/getPfDataMonthWise/${yearMonth}/${Apiconnect.get_company_id()}`).then((response) => {
         let salData = Apiconnect.decrypt_obj(response.data.data);
         return salData;
     });
     return salaryData;
 }; 

 const getDeductionMonthData = (yearMonth) => {         
   var salaryData = Apiconnect.getData(`employee_salary/getDeductionDataMonthWise/${yearMonth}/${Apiconnect.get_company_id()}`).then((response) => {
         let salData = Apiconnect.decrypt_obj(response.data.data);
         return salData;
     });
     return salaryData;
 }; 

 const getEarningMonthData = (yearMonth) => {         
  var salaryData = Apiconnect.getData(`employee_salary/getEarningDataMonthWise/${yearMonth}/${Apiconnect.get_company_id()}`).then((response) => {
        let salData = Apiconnect.decrypt_obj(response.data.data);
        return salData;
    });
    return salaryData;
}; 




 const downloadExcel = async () => {
  var month = sMonth;
  var year = sYear;
  var empTypeId1 = empTypeId.join(',');


  setFullScreenLoader(true)
  var salaryMonth = await get_monthData(year+''+month,empTypeId1);
  if(salaryMonth){
    setExcelName('SalaryList_'+months[month]+'-'+year);
    setdwnSalaryMonthsById(salaryMonth)
    setTimeout(function () {csvLink.current.link.click();}, 100);
    setFullScreenLoader(false)
  }
   
 }
 const downloadESIExcel = async (id,name,yearmonth) => {

  setFullScreenLoader(true)
  var dwnEmpEsiSalaryList = await getEsiMonthData(yearmonth);
  if(dwnEmpEsiSalaryList){
    setExcelName('Esi_'+name);
    setdwnEsiSalaryMonthsById(dwnEmpEsiSalaryList)
    setTimeout(function () {csvLink2.current.link.click();}, 100);
    setFullScreenLoader(false)
  }

 }

 const downloadESIPdf = (year,month) => {
   history.push(`/esiPdf/${year}/${month}`); 
 }

 const downloadPFExcel = async (id,name,yearmonth) => {
  setFullScreenLoader(true)
  var pfSalaryMonth = await getPfMonthData(yearmonth);
  if(pfSalaryMonth){
    setExcelName('PF_'+name);
    setdwnPfSalaryMonthsById(pfSalaryMonth)
    setTimeout(function () {csvLink3.current.link.click();}, 100);
    setFullScreenLoader(false)
  }

 }

 const downloadDeduction = async  (id,name,yearmonth) => {
  setFullScreenLoader(true)
  var DeductionSalaryMonth = await getDeductionMonthData(yearmonth);
  if(DeductionSalaryMonth){
    setExcelName('Deduction_'+name);
    setdwnDeductionMonthsById(DeductionSalaryMonth)
    setTimeout(function () {csvLink4.current.link.click();}, 100);
    setFullScreenLoader(false)
  }

 }

 const downloadEarning = async  (id,name,yearmonth) => {
  setFullScreenLoader(true)
  var EarningSalaryMonth = await getEarningMonthData(yearmonth);
  if(EarningSalaryMonth){
    setExcelName('Earning_'+name);
    setdwnEarningMonthsById(EarningSalaryMonth)
    setTimeout(function () {csvLink6.current.link.click();}, 100);
    setFullScreenLoader(false)
  }

}

 const unblock = (id) => {

   const inf_a = {id:id,status:0};
   Apiconnect.postData("employee_monthly_salary/lock_month", inf_a).then((response) => {
   alert.success("Unblock Successfully");
   getSalarySlipData();

 });

 }

 const uploadDeduction = (date,month,year) => {
  setSelectedMonth(date)
   setEmpModalIsOpen(true);
   deductionSampleFile(month,year);
   
 };

 const uploadEarning = (date,month,year) => {
  setSelectedMonth(date)
   setEarningModalIsOpen(true);
   earningSampleFile(month,year);
 };




 const months = {'01' :'JAN','02' :'FEB','03' :'MAR','04' :'APR','05' :'MAY',
 '06' :'JUN','07' :'JUL','08' :'AUG','09' :'SEP','10' :'OCT','11' :'NOV','12' :'DEC'};

 const viewItem = (e , status,id)=> {
    history.push(`/employee_month_sal/${e}/${id}/${status}`); 
    }

    const customStyles = {
      content: {
        top: "55%",
        width: "60%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    const customStyles1 = {
      content: {
        top: "40%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        height:'300px'
      },
    };

    const submitSalary = async e => {
      const data = new FormData() ;
      data.append('csvdeductionfile', selectedFile);   
   //  Axios.post(`http://localhost:3040/api/test/uploadsalarycsv/${Apiconnect.get_company_id()}/${selectedMonth}`, data).then((response) => {
    setLodder(true)
      Apiconnect.postData(`upload/uploaddeductioncsv/${Apiconnect.get_company_id()}/${selectedMonth}`, data).then((response) => {           
         console.log(response.data)
        if(response.data.data.status === true){
            var wrongCompany = response.data.data.emp_company;
            var wrongDate = response.data.data.emp_date;
            var msg = response.data.message;
            var msgCompany = '';
            var msgDate = '';
            if(wrongCompany.length > 0){
              msgCompany = 'Wrong company name '+wrongCompany.join()+'.';
            }
            if(wrongDate.length > 0){
              msgDate = 'Wrong Date '+wrongDate.join()+'.';
            }
            setLodder(false)
            const options = {
              timeout: 10000,
            }  
            if(msgCompany !== ''|| msgDate !== ''){
              alert.error('The following employee not uploaded. '+msgCompany+', '+msgDate,options);
            }else{
              alert.success(msg);
            }
            

          }else{
            setLodder(false)
             alert.success(response.data.message);
          }

      });
       
  };

  const submitSalaryEarning = async e => {
    const data = new FormData() ;
    data.append('csvearningfile', selectedFile);   
 //  Axios.post(`http://localhost:3040/api/test/uploadsalarycsv/${Apiconnect.get_company_id()}/${selectedMonth}`, data).then((response) => {
  setLodder(true)
    Apiconnect.postData(`upload/uploadearningcsv/${Apiconnect.get_company_id()}/${selectedMonth}`, data).then((response) => {           
        if(response.data.data.status === true){
          var wrongCompany = response.data.data.emp_company;
          var wrongDate = response.data.data.emp_date;
          var msg = response.data.message;
          var msgCompany = '';
          var msgDate = '';
          if(wrongCompany.length > 0){
            msgCompany = 'Wrong company name '+wrongCompany.join()+'.';
          }
          if(wrongDate.length > 0){
            msgDate = 'Wrong Date '+wrongDate.join()+'.';
          }
          setLodder(false)
          const options = {
            timeout: 10000,
          }  
          if(msgCompany !== ''|| msgDate !== ''){
            alert.error('The following employee not uploaded. '+msgCompany+', '+msgDate,options);
          }else{
            alert.success(msg);
          }
          

        }else{
          setLodder(false)
           alert.success(response.data.message);
        }

    });
     
};

  const handelChange = (event) => {
   setSelectedFile(event.target.files[0]); 
}

const downloadSalarySlip = (month,year) => {
  getEmployeeList_new("50", "0", "",'',month,year);
  getEmpCount("",'',month,year);
  setModalIsOpen(true);
  setSYear(year);
  setSMonth(month);
}

const downloadSalarysheet = async (month,year) => {
  // setEmpTypeId([])
  // setSalaryModalIsOpen(true);
  setSYear(year);
  setSMonth(month);

  setFullScreenLoader(true)
  var salaryMonth = await get_monthData(year+''+month);
  if(salaryMonth){
    setExcelName('SalaryList_'+months[month]+'-'+year);
    setdwnSalaryMonthsById(salaryMonth)
    setTimeout(function () {csvLink.current.link.click();}, 100);
    setFullScreenLoader(false)
  }

}

// const downloadSalaryPdf = async (month,year) => {
//   setEmpTypeId([])
//   setSalaryPdfModalIsOpen(true);
//   setSYear(year);
//   setSMonth(month);
// }

const onInputChangeAllType = () =>  {
  var id = [];
  empTypeList.map((val, key) => {
    id.push(val.id)
  })
  setEmpTypeId(id);

  const inputs = document.getElementsByClassName('allOption');
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].checked = true
  }

}

const onInputChangeType = (e) => {

  if(e.target.checked){
    setEmpTypeId([...empTypeId, parseInt(e.target.value)]);
  }else{
    var index = empTypeId.indexOf(parseInt(e.target.value));
    if (index > -1) { // only splice array when item is found
      empTypeId.splice(index, 1); // 2nd parameter means remove one item only
    }
    setEmpTypeId([...empTypeId]);
  }

  if (empTypeId.length <= 0) {
    const inputs1 = document.getElementById('inlineCheckbox');
    console.log(inputs1)
    inputs1.checked = false
  }

}


useEffect(() => {
  const delayDebounceFn = setTimeout(() => {
    
    let offset = (activePage - 1) * 50;
    let limit = 50;
    var dept_id1 = '';
    getEmployeeList_new(limit, offset, searchTerm,dept_id1,sMonth,sYear);

    var dept_id1 = '';
    const data = {
      search: searchTerm,
      company_id: Apiconnect.get_company_id(),
      dept_id:dept_id1,
      year:sYear,
      month:sMonth
    };

    Apiconnect.postData(
      `employee_salary/getEmpSearchcount`,data
    ).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setempCount(_xtract[0].cnt);
    });

  }, 2000)

  return () => clearTimeout(delayDebounceFn)
}, [searchTerm])

const deductionSampleFile = (mon,year) => {
  Apiconnect.getData(`employee/deductionsamplefile/${Apiconnect.get_company_id()}/${mon}/${year}`).then((response) => {
     let _xtract = Apiconnect.decrypt_obj(response.data.data);
     setDeductionSampleFile(_xtract.reverse())
  });
}

const earningSampleFile = (mon,year) => {
  Apiconnect.getData(`employee/earningsamplefile/${Apiconnect.get_company_id()}/${mon}/${year}`).then((response) => {
     let _xtract = Apiconnect.decrypt_obj(response.data.data);
     setEarningSampleFile(_xtract.reverse())
  });
}

const getEmployeeList_new = (limit, offset, search,dept_id,month,year) => {
  setLoader(true);
  const data = {
    limit: limit,
    offset: offset,
    search: search,
    company_id: Apiconnect.get_company_id(),
    dept_id:dept_id,
    year:year,
    month:month
  };
  Apiconnect.postData(`employee_salary/getallemployeelist`, data).then(
    (response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      console.log('test',_xtract.reverse())
      setEmployeeList(_xtract.reverse());
      setLoader(false);
    }
  );
};

const handlePageChange = (pageNumber) => {
  setActivePage(pageNumber);
  let offset = (pageNumber - 1) * 50;
  console.log(`active page is ${pageNumber} -- ${offset}`);
  let limit = 50;
  getEmployeeList_new(limit, offset, "",null,sMonth,sYear);
};

const getEmpCount = (search,dept_id,month,year) => {

  var dept_id1 = '';
  if(dept_id != '' && typeof dept_id != 'undefined' && dept_id != null){
     dept_id1 = dept_id;
  }

  const data = {
    search: search,
    company_id: Apiconnect.get_company_id(),
    dept_id:dept_id1,
    year:year,
    month:month
  };
  

  Apiconnect.postData(
    `employee_salary/getEmpSearchcount`,data
  ).then((response) => {
    let _xtract = Apiconnect.decrypt_obj(response.data.data);
    setempCount(_xtract[0].cnt);
  });
};

const downloadDedFile = () => {
  csvLink5.current.link.click();
}

const downloadEarFile = () => {
  csvLink7.current.link.click();
}


const addMonthPopup = () => {
  setAddmonthModalIsOpen(true)
}

const [getDate, setDate] = useState(new Date());
const saveMonth = () => {
  const data = {
    company_id: Apiconnect.get_company_id(),
    month:format(getDate, 'MM'),
    year:format(getDate, 'yyyy'),
    status:0
  };
  Apiconnect.postData("employee_monthly_salary/add_month", data).then((response) => {
    alert.success(response.data.message);   
    setAddmonthModalIsOpen(false)
    getSalarySlipData();
  });

}

const ondlt = (id) => {
  confirmAlert({
    title: 'Are you sure to Delete?',
    buttons: [
      {
        label: 'Yes',
        onClick: () => deleteMonth(id), 
      },
      {
        label: 'No',
      }
    ]
  });
};

const deleteMonth = (id) => {
  Apiconnect.deleteData(`employee_monthly_salary/deletemonth/${id}`).then((response) => {
    getSalarySlipData();
    alert.success(response.data.message);      
 });
}

function titleCase(string){
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

const downloadSalaryPdf = async (month,year) => {

  setSYear(year);
  setSMonth(month);

  
  setFullScreenLoader(true)
  var salaryMonth1 = await get_monthDataPdf(year+''+month);


  if(salaryMonth1){

    var salaryMonth = Apiconnect.decrypt_obj(salaryMonth1.data);
    var data_teaching = Apiconnect.decrypt_obj(salaryMonth1.data_teaching);
    var data_non_teaching = Apiconnect.decrypt_obj(salaryMonth1.data_non_teaching);


   let col = ['Sr.no']
    var head = Object.keys(data_non_teaching[0])
    var doc = new jsPDF('l', 'pt','a3');
    col.push(...head);
    var rows = [];

    var cnt = 1;
    data_non_teaching.map((key, key1) => {    
      var sr = cnt++;  
      var arr = [sr];
       head.map(val => (
          arr.push(key[val])
       ))  
       rows.push(arr);
    }) 


    doc.setFontSize(12);
    doc.text(460,25,company_name.toUpperCase());
    doc.setFontSize(10);
    doc.text(460,40,companyaddress);
    doc.setFontSize(16);
    doc.text(440,65,'Print Salary Register For the  Month of ' +  months[month]);

    doc.autoTable(col, rows, { 
      theme: 'grid',
      // showHead:'firstPage',
      margin: { top: 80 , left:5,right:5},
      headStyles:{fillColor:'#dee2e6',valign:'middle'},
      styles: { fontSize: 7,cellWidth:'auto',textColor:20,valign:'middle',halign:'middle'  },
      });
  
    let col_teaching = ['Sr.no']
    var head1 = Object.keys(data_teaching[0])
    col_teaching.push(...head1);
    var rows_teaching = [];

    var cnt1 = 1;
    data_teaching.map((key, key1) => {       
      var sr = cnt1++;  
      var arr = [sr];
        head.map(val => (
          arr.push(key[val])
        ))  
        rows_teaching.push(arr);
    }) 

    doc.addPage();

    doc.autoTable(col_teaching, rows_teaching, { 
      theme: 'grid',
      // showHead:'firstPage',
      margin: { top: 80 , left:5,right:5},
      headStyles:{fillColor:'#dee2e6',valign:'middle'},
      styles: { fontSize: 7,cellWidth:'auto',textColor:20,valign:'middle',halign:'middle'  },
      });


var sal = salaryMonth[salaryMonth.length-1];   
var col2 = ["Earning","Amount"];
var rows = [];
var col1 = ["Deduction","Amount"];
var rows1 = [];

var itemNewEarning = [];
  earning_head.map((val)=>{
    itemNewEarning.push({
      ['Earning']:val.name,['Amount']:sal[titleCase(val.name)],
    })
  })
  var itemNewDeduction = [];
  deduct_head.map((val)=>{
    itemNewDeduction.push({
      ['Deduction']:val.name,['Amount']:sal[titleCase(val.name)],
    })
  })


  let eartot = 0;
  itemNewEarning.forEach(element => {      
  var temp = [element.Earning,element.Amount];
  eartot = eartot+element.Amount;
  rows.push(temp);
});    

var temp = ['Gross Pay',eartot];
rows.push(temp);

let dedtot = 0;
itemNewDeduction.forEach(element => {      
  var temp = [element.Deduction,element.Amount];
  dedtot = dedtot+element.Amount;
  rows1.push(temp);

}); 

var temp = ['Total Deduction',dedtot];
rows1.push(temp);
var temp = ['Net Pay',eartot-dedtot];
rows1.push(temp);

doc.addPage();

doc.setFontSize(16);
doc.text(100,50,'Grand Total');

doc.autoTable(col2, rows, { 
  theme: 'grid',
  showHead:'firstPage',
   margin: {top: 50 , left:250,right:10},
  headStyles:{fillColor:'#dee2e6',valign:'middle'},
  styles: { fontSize: 9,cellWidth:'auto',textColor:50,valign:'middle',halign:'middle'  },
  startY: 20,
  tableWidth: 180,
  });


doc.autoTable(col1, rows1, { 
  theme: 'grid',
  showHead:'firstPage',
   margin: {horizontal: 460, },
  headStyles:{fillColor:'#dee2e6',valign:'middle'},
  styles: { fontSize: 9,cellWidth:'auto',textColor:50,valign:'middle',halign:'middle'  },
  // margin: {horizontal: 160},
  startY: 20,
  tableWidth: 180
  });


   let finalY = doc.lastAutoTable.finalY;

  doc.setFontSize(16);
  doc.text(100,finalY+150,'Prepared by :');

  doc.setFontSize(16);
  doc.text(400,finalY+150,'Checked by :');

  doc.setFontSize(16);
  doc.text(700,finalY+150,'Verified by :');

  doc.setFontSize(16);
  doc.text(1000,finalY+150,'Approved by :');

  doc.setFontSize(10);
  doc.text(100,finalY+220,'(Manager Accounts)');

  doc.setFontSize(10);
  doc.text(400,finalY+220,'(Sr. Manager Commercial)');

  doc.setFontSize(10);
  doc.text(700,finalY+220,'(DGM-Admin) ');

  doc.setFontSize(10);
  doc.text(1000,finalY+220,'(Director-Principal):');

    doc.save('SalaryPdf'+year+''+month+'.pdf');

    setFullScreenLoader(false)
  }
   
 }


 /* -------------- salary list monthly------------------ */

 function getSheetData(data, header) {
  var fields = Object.keys(data[0]);
  var sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
}

const local_user = JSON.parse(localStorage.getItem('user'));


//salarysheet excel
const downloadSalarysheetExcel = async (month,year) => {
  setSYear(year);
  setSMonth(month);

  var salaryMonth = await get_monthData(year+''+month);
  if(salaryMonth){
    setExcelName('SalaryList_'+months[month]+'-'+year);
    setdwnSalaryMonthsById(salaryMonth)

    let header = ["Emp_code",	"Full_name",	"Employee_group",	"Department",	"Designation",	"D_O_B",	"D_O_J",	"Salary_template",	"Total_days",	"Present_days",	"Ideal_basic",	"Basic",	"Da",	"Hra",	"Others",	"Coordinator", "allowance",	"Arears",	"Gross salary",	"Miscellaneous2",	"Pf",	"Tds",	"Group insurance",	"Esi",	"Staff conveyance",	"Advance",	"Medical insurance",	"Miscellaneous1",	"Total Deduction",	"Net Pay",	"Account_no",	"IFSC_Code",	"Remarks"]

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(salaryMonth, header);
      const totalColumns = sheetData[0].length;

      sheet1.cell("A1").value(local_user.company_name);
      sheet1.cell("A2").value(companyaddress)
      sheet1.cell("A3").value(`Salary sheet: ${months[month]}-${year}`);
      sheet1.cell("A5").value(sheetData);

      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      range.style("border", true);

      sheet1.range("A1:K1").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true);
      sheet1.range("A2:K2").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
      sheet1.range("A3:K3").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
      sheet1.range("A5:AG5").style({horizontalAlignment: "center", verticalAlignment: "center", }).style("bold", true).style("fill", "BFBFBF");

      return workbook.outputAsync().then((res) => {
        saveAs(res, `salarysheet.xlsx`);
      });
    });
  }
}

//esiexcel
const downloadESIExcelnew = async (id,name,yearmonth) => {

  let dwnEmpEsiSalaryList = await getEsiMonthData(yearmonth);
  if(dwnEmpEsiSalaryList){
    setExcelName('Esi_'+name);
    setdwnEsiSalaryMonthsById(dwnEmpEsiSalaryList)

    let header = ["id",	"emp_code",	"Full_name",	"Days_Attended",	"Gross_salary",	"ESI"]

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(dwnEmpEsiSalaryList, header);
      const totalColumns = sheetData[0].length;

      sheet1.cell("A1").value(local_user.company_name);
      sheet1.cell("A2").value(companyaddress)
      sheet1.cell("A3").value(`ESI: ${name}`);
      sheet1.cell("A5").value(sheetData);

      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      range.style("border", true);

      sheet1.range("A1:F1").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true);
      sheet1.range("A2:F2").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
      sheet1.range("A3:F3").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
      sheet1.range("A5:F5").style({horizontalAlignment: "center", verticalAlignment: "center", }).style("bold", true).style("fill", "BFBFBF");

      return workbook.outputAsync().then((res) => {
        saveAs(res, `ESI.xlsx`);
      });
    });

  }
 }

 //PF excel
 const downloadPFExcelnew = async (id,name,yearmonth) => {
  var pfSalaryMonth = await getPfMonthData(yearmonth);
  if(pfSalaryMonth){
    setExcelName('PF_'+name);
    setdwnPfSalaryMonthsById(pfSalaryMonth)

    let header = ["id",	"emp_code",	"Full_name",	"DOB",	"UAN_number",	"BASIC+DA",	"EligibleSalary",	"EmployeeShare",	"AgeRemarks"]

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(pfSalaryMonth, header);
      const totalColumns = sheetData[0].length;

      sheet1.cell("A1").value(local_user.company_name);
      sheet1.cell("A2").value(companyaddress)
      sheet1.cell("A3").value(`PF: ${name}`);
      sheet1.cell("A5").value(sheetData);

      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      range.style("border", true);

      sheet1.range("A1:I1").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true);
      sheet1.range("A2:I2").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
      sheet1.range("A3:I3").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
      sheet1.range("A5:I5").style({horizontalAlignment: "center", verticalAlignment: "center", }).style("bold", true).style("fill", "BFBFBF");

      return workbook.outputAsync().then((res) => {
        saveAs(res, `PF.xlsx`);
      });
    });
  }

 }

 //Deduction Excel
 const downloadDeductionExcel = async  (id,name,yearmonth) => {
  var DeductionSalaryMonth = await getDeductionMonthData(yearmonth);
  if(DeductionSalaryMonth){
    setExcelName('Deduction_'+name);
    setdwnDeductionMonthsById(DeductionSalaryMonth)

    let header = ["id",	"emp_code",	"first_name",	"company_name",	"month", "year",	"deduction_Miscellaneous2",	"deduction_TDS",	"deduction_Group", "Insurance","	deduction_Staff Conveyance",	"deduction_Advance",	"deduction_Medical Insurance",	"deduction_Miscellaneous1"]

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(DeductionSalaryMonth, header);
      const totalColumns = sheetData[0].length;

      sheet1.cell("A1").value(local_user.company_name);
      sheet1.cell("A2").value(companyaddress)
      sheet1.cell("A3").value(`Deduction: ${name}`);
      sheet1.cell("A5").value(sheetData);

      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      range.style("border", true);

      sheet1.range("A1:N1").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true);
      sheet1.range("A2:N2").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
      sheet1.range("A3:N3").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
      sheet1.range("A5:N5").style({horizontalAlignment: "center", verticalAlignment: "center", }).style("bold", true).style("fill", "BFBFBF");

      return workbook.outputAsync().then((res) => {
        saveAs(res, `deduction.xlsx`);
      });
    });
  }
 }

 //Earning head excel
 const downloadEarningExcel = async  (id,name,yearmonth) => {
  var EarningSalaryMonth = await getEarningMonthData(yearmonth);
  if(EarningSalaryMonth){
    setExcelName('Earning_'+name);
    setdwnEarningMonthsById(EarningSalaryMonth)

    let header = ["id",	"emp_code",	"first_name",	"company_name",	"month",	"year",	"earning_Others",	"earning_Coordinator Allowance",	"earning_Arears"]

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(EarningSalaryMonth, header);
      const totalColumns = sheetData[0].length;

      sheet1.cell("A1").value(local_user.company_name);
      sheet1.cell("A2").value(companyaddress)
      sheet1.cell("A3").value(`Earning Head: ${name}`);
      sheet1.cell("A5").value(sheetData);

      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      range.style("border", true);

      sheet1.range("A1:I1").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true);
      sheet1.range("A2:I2").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
      sheet1.range("A3:I3").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
      sheet1.range("A5:J5").style({horizontalAlignment: "center", verticalAlignment: "center", }).style("bold", true).style("fill", "BFBFBF");

      return workbook.outputAsync().then((res) => {
        saveAs(res, `earning head.xlsx`);
      });
    });
  }

}




   return (
    <>     
           <div id="content-page" className="content-page">
            <div className="container-fluid">
            {fullScreenLoader
            ? (
              <FullScreenLoading loading loaderColor="#3498db" />
            )
             
            : null
            }
            <div>
              
                         {/* salry sheet pdf modal */}
                {/* <div >
                  <Modal isOpen={salaryPdfModalIsOpen} style={customStyles1} onRequestClose={() => setSalaryPdfModalIsOpen(false)}>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between model_head">
                           <div className="iq-header-title">
                              <h4 className="card-title ">Employment type</h4>
                           </div>
                        </div>
                        <div className="iq-card-body" style={{width:'900px'}}>
                        <div className="row" style={{width:'100%'}}>

                          <div className="col-md-12">

                          <div className="col-md-3 form-check form-check-inline" style={{padding:'3px'}}>
                            <input className="form-check-input" type="checkbox" id="inlineCheckbox"  value='' onChange={e => onInputChangeAllType(e)} />
                            <label className="form-check-label" htmlFor={"inlineCheckbox"}>All</label>
                          </div>

                          {
                              empTypeList.map((val, key) => {
                                  return (
                                      <div key={key} className="col-md-3 form-check form-check-inline" style={{padding:'3px'}}>
                                      <input className="allOption form-check-input" type="checkbox" id={"inlineCheckbox"+key} value={val.id} onChange={e => onInputChangeType(e)} />
                                      <label className="form-check-label" htmlFor={"inlineCheckbox"+key}>{val.name}</label>
                                    </div>
                                  )
                              })
                          }
                          </div>
                         </div>
                        </div>
                        <div style={{textAlign: 'center'}}>
                        <input type="button" className="btn btn-success" style={{marginRight:'10px'}} value="Download" onClick={() => downloadSalaryPdf1()} />
                        <button className="btn btn-info" onClick={() => setSalaryPdfModalIsOpen(false)}>close</button>
                        </div>
                     </div>
                  </Modal>
               </div>   */}

             {/* salry sheet modal */}
            {/* <div >
                  <Modal isOpen={salaryModalIsOpen} style={customStyles1} onRequestClose={() => setSalaryModalIsOpen(false)}>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between model_head">
                           <div className="iq-header-title">
                              <h4 className="card-title ">Employment type</h4>
                           </div>
                        </div>
                        <div className="iq-card-body" style={{width:'900px'}}>
                        <div className="row" style={{width:'100%'}}>

                          <div className="col-md-12">

                          <div className="col-md-3 form-check form-check-inline" style={{padding:'3px'}}>
                            <input className="form-check-input" type="checkbox" id="inlineCheckbox"  value='' onChange={e => onInputChangeAllType(e)} />
                            <label className="form-check-label" htmlFor={"inlineCheckbox"}>All</label>
                          </div>

                          {
                              empTypeList.map((val, key) => {
                                  return (
                                      <div key={key} className="col-md-3 form-check form-check-inline" style={{padding:'3px'}}>
                                      <input className="allOption form-check-input" type="checkbox" id={"inlineCheckbox"+key} value={val.id} onChange={e => onInputChangeType(e)} />
                                      <label className="form-check-label" htmlFor={"inlineCheckbox"+key}>{val.name}</label>
                                    </div>
                                  )
                              })
                          }
                          </div>
                         </div>
                        </div>
                        <div style={{textAlign: 'center'}}>
                        <input type="button" className="btn btn-success" style={{marginRight:'10px'}} value="Download" onClick={() => downloadExcel()} />
                        <button className="btn btn-info" onClick={() => setSalaryModalIsOpen(false)}>close</button>
                        </div>
                     </div>
                  </Modal>
               </div>   */}

               {/* Add Month modal */}
            <div >
                  <Modal isOpen={addmonthModalIsOpen} style={customStyles1} onRequestClose={() => setAddmonthModalIsOpen(false)}>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between model_head">
                           <div className="iq-header-title">
                              <h4 className="card-title ">Add Month</h4>
                           </div>
                        </div>
                        <div className="iq-card-body" style={{width:'900px'}}>
                        <div className="row" style={{width:'100%'}}>

                          <div className="col-md-12">
                            <div className="form-group" style={{display:'flex'}}>
                              <label style={{paddingRight:'7px',width:'70px'}}>Date : {` `}</label>
                              <DatePicker
                                dateFormat="MMMM yyyy"
                                showMonthYearPicker
                                selected={getDate}
                                onChange={(e)=>setDate(e)}
                                dropdownMode="select"
                              />
                            </div>
                          </div>
                         </div>
                        </div>
                        <div style={{textAlign: 'center'}}>
                        <input type="button" className="btn btn-success" style={{marginRight:'10px'}} value="Save" onClick={() => saveMonth()} />
                        <button className="btn btn-info" onClick={() => setAddmonthModalIsOpen(false)}>close</button>
                        </div>
                     </div>
                  </Modal>
               </div>   
              
              {/* deduction file modal */}
            <Modal
              isOpen={modalEmpIsOpen}
              style={customStyles}
              onRequestClose={() => setEmpModalIsOpen(false)}
            >
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between model_head">
                  <div className="iq-header-title">
                    <h4 className="card-title ">Deduction File</h4>
                  </div>


                </div>
                <div className="iq-card-body">
                  <div className="row">
                  <div className="col-sm-2">
                        </div> 
                    <div className="col-sm-8">
                      {lodder ? <Loadder/> : ''}
                        <div class="container center">
                            <div class="row">
                                <div class="col-md-12" style={{ marginBottom: '15px' }}>
                                    <h2 class="white" style={{paddingTop: '10px'}}>Deduction Bulk Data Upload</h2>
                                    {/* <p class="white">In this example, submit is allowed only in case the user uploads a valid image file.</p> */}
                                    <hr style={{backgroundColor:'#fff'}}/>
                                </div>
                               
                            </div>
                                <div class="row">
                                    <div class="col-md-3 col-md-offset-3 center">
                                    </div>
                                    <div class="col-md-6 col-md-offset-3 center">
                                        <div class="btn-container">
                                            
                                        <div class="dropzone">
                                            <img src="http://100dayscss.com/codepen/upload.svg" class="upload-icon" />
                                                {/* <input type="file" class="upload-input" value="" /> */}
                                                <div className="row" style={{margin:' 0 10px 25px 10px'}}>
                                                <input type="file" id="file" className="form-control" name="csvdeductionfile" onChange={handelChange} />
                                                </div>
                                            </div>

                                            <button type="button" id="btnup" class="btn btn-primary btn-lg" onClick={()=>submitSalary()}>Submit</button>
                                            {/* <CSVLink filename={"salary_sample.csv"} style={{ marginTop: '13px', float: 'right',paddingRight:'10px' }} data={getDeductionSampleFile} >
                                            <u><i class="fa fa-download"></i> Salary smaple file</u>
                                            </CSVLink> */}
                                            <button  className="dropdown-item" onClick={() => downloadDedFile()}>
                                             <i className="fa fa-download"></i>
                                             {` `} Salary smaple file CSV

                                             <CSVLink
                                             data={getDeductionSampleFile}
                                             filename={'samplefile.csv'}
                                             className="hidden"
                                             ref={csvLink5}
                                             target="_blank"
                                             /> 

                                          </button>
                                        </div>
                                    </div>
                                </div>
                              
                        </div>
                      </div>  
                        <div className="col-sm-2">
                        </div> 
                </div>
                <div className="col-md-12 mt-2">
                  <button
                    className="btn btn-info"
                    onClick={() => setEmpModalIsOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              </div>
            </Modal>

              {/* Earning file modal */}                               
            <Modal
              isOpen={modalEarningIsOpen}
              style={customStyles}
              onRequestClose={() => setEarningModalIsOpen(false)}
            >
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between model_head">
                  <div className="iq-header-title">
                    <h4 className="card-title ">Earning File</h4>
                  </div>


                </div>
                <div className="iq-card-body">
                  <div className="row">
                  <div className="col-sm-2">
                        </div> 
                    <div className="col-sm-8">
                      {lodder ? <Loadder/> : ''}
                        <div class="container center">
                            <div class="row">
                                <div class="col-md-12" style={{ marginBottom: '15px' }}>
                                    <h2 class="white" style={{paddingTop: '10px'}}>Earning Bulk Data Upload</h2>
                                    {/* <p class="white">In this example, submit is allowed only in case the user uploads a valid image file.</p> */}
                                    <hr style={{backgroundColor:'#fff'}}/>
                                </div>
                               
                            </div>
                                <div class="row">
                                    <div class="col-md-3 col-md-offset-3 center">
                                    </div>
                                    <div class="col-md-6 col-md-offset-3 center">
                                        <div class="btn-container">
                                            
                                        <div class="dropzone">
                                            <img src="http://100dayscss.com/codepen/upload.svg" class="upload-icon" />
                                                {/* <input type="file" class="upload-input" value="" /> */}
                                                <div className="row" style={{margin:' 0 10px 25px 10px'}}>
                                                <input type="file" id="file" className="form-control" name="csvearningfile" onChange={handelChange} />
                                                </div>
                                            </div>

                                            <button type="button" id="btnup" class="btn btn-primary btn-lg" onClick={()=>submitSalaryEarning()}>Submit</button>
                                            {/* <CSVLink filename={"salary_sample.csv"} style={{ marginTop: '13px', float: 'right',paddingRight:'10px' }} data={getDeductionSampleFile} >
                                            <u><i class="fa fa-download"></i> Salary smaple file</u>
                                            </CSVLink> */}
                                            <button  className="dropdown-item" onClick={() => downloadEarFile()}>
                                             <i className="fa fa-download"></i>
                                             {` `} Salary smaple file CSV

                                             <CSVLink
                                             data={getEarningSampleFile}
                                             filename={'samplefile.csv'}
                                             className="hidden"
                                             ref={csvLink7}
                                             target="_blank"
                                             /> 

                                          </button>
                                        </div>
                                    </div>
                                </div>
                              
                        </div>
                      </div>  
                        <div className="col-sm-2">
                        </div> 
                </div>
                <div className="col-md-12 mt-2">
                  <button
                    className="btn btn-info"
                    onClick={() => setEarningModalIsOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              </div>
            </Modal>
          </div>

          {/* Salary Slip List modal */}
          <div >
                  <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)}>
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between model_head">
                           <div className="iq-header-title">
                              <h4 className="card-title ">Salary Slip List</h4>
                           </div>
                        </div>
                        <div className="iq-card-body" style={{padding:'0px'}}>
                   
                  <div style={{ padding: "10px", textAlign: "right" }}>
                    <div>
                      <input
                        type="text"
                        placeholder="Search here..."
                        name="search"
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="table-responsive" style={{height: '530px',overflowY: 'auto'}}>
                  <table className="table table-striped table-hover">
                    <thead className="">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Emp Code</th>
                        <th scope="col">Name</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody className="">
                    {loader ? (
                        <Loadder />
                      ) : (
                        List.map((val, key) => {
                          return (
                            <tr key={key}>
                              <th scope="row">{key + 1}</th>
                              <td>{val.emp_code}</td>
                              <td>
                                {val.first_name} {val.last_name}
                              </td>
                              <td><a href={`/salaryslip/${val.id}/${sYear}/${sMonth}`} target='_blank'>Salary Slip</a></td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                  </div>
                  <div>
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={50}
                      totalItemsCount={empCount}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                    />
                  </div>
                        </div>
                        <div className="text_center">
                        <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>close</button>
                        </div>
                     </div>
                  </Modal>
               </div> 

            <div className="col-sm-12 col-lg-12">
                     <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                           <div className="iq-header-title">
                              <h4 className="card-title">List Of Monthly Salary</h4>
                              
                           </div>

                           {
                            user_typ === '3'
                             ? (
                              <button onClick={()=>addMonthPopup()} className="btn btn-info mr-2" style={{ marginTop: "23px",marginLeft:'10px',float: 'right' }}>
                                Add Month
                              </button>)
                             : null
                           }
                          
                        </div>
                        
                        <div className="iq-card-body">
                           <table className="table table-striped table-hover">
                              <thead className="">
                                 <tr className="clr" style={{textAlign:'center'}}>
                                    <th>#</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody className="">
                                {
                                    salaryMonths.map((value,key)=>{
                                        return (
                                        <tr key={key} style={{textAlign:'center'}}>
                                        <td style={{width:'150px'}} >{key+1}</td>
                                        <td style={{textAlign:'center'}}>{months[value.month]+'-'+value.year}</td>
                                        <td>{value.status === 0 ? 'Unlock' : 'Lock'}</td>
                                        <td style={{width:'450px',textAlign: 'left'}}>
                                        <button  className="btn btn-sm  btn-info mr-2 f_left" onClick={() => viewItem(value.year+''+value.month,value.status,value.id)}><i className="fa fa-eye" aria-hidden="true"></i></button>
                                      
                                       {
                                          value.isSalary 
                                          ? (
                                             <>
                                       <div className="dropdown f_left">
                                          <span
                                             className="dropdown-toggle btn "
                                             id="dropdownMenuButton4"
                                             data-toggle="dropdown"
                                          >
                                             <i className="fa fa-cog"></i>
                                          </span>

                                          <div
                                             className="dropdown-menu dropdown-menu-right"
                                             aria-labelledby="dropdownMenuButton4"
                                          >
                                         
                                          <button  className="dropdown-item" 
                                             onClick={() =>  downloadSalarysheet(value.month,value.year)}>
                                             <i className="fa fa-download"></i>
                                             {` `} SalarySheet CSV
                                           </button>
                                          <button  className="dropdown-item" 
                                             onClick={() =>  downloadSalarysheetExcel(value.month,value.year)}>
                                             <i className="fa fa-download"></i>
                                             {` `} SalarySheet Excel
                                           </button>
                                           <button  className="dropdown-item" onClick={() => downloadSalaryPdf(value.month,value.year)}>
                                             <i className="fa fa-download"></i>
                                             {` `} SalarySheet Pdf
                                          </button> 
                                           <button  className="dropdown-item" onClick={() => downloadESIExcel(value.id,months[value.month]+'-'+value.year,value.year+''+value.month)}>
                                             <i className="fa fa-download"></i>
                                             {` `} ESI CSV
                                           </button>
                                           <button  className="dropdown-item" onClick={() => downloadESIExcelnew(value.id,months[value.month]+'-'+value.year,value.year+''+value.month)}>
                                             <i className="fa fa-download"></i>
                                             {` `} ESI Excel
                                           </button>
                                           <button  className="dropdown-item" onClick={() => downloadPFExcel(value.id,months[value.month]+'-'+value.year,value.year+''+value.month)}>
                                             <i className="fa fa-download"></i>
                                             {` `} PF Statement CSV
                                          </button>
                                           <button  className="dropdown-item" onClick={() => downloadPFExcelnew(value.id,months[value.month]+'-'+value.year,value.year+''+value.month)}>
                                             <i className="fa fa-download"></i>
                                             {` `} PF Statement Excel
                                          </button>
                                          <button  className="dropdown-item" onClick={() => downloadESIPdf(value.year,value.month)}>
                                             <i className="fa fa-download"></i>
                                             {` `} ESIPDF
                                          </button> 
                                          <button  className="dropdown-item" onClick={() => downloadDeduction(value.id,months[value.month]+'-'+value.year,value.year+''+value.month)}>
                                             <i className="fa fa-download"></i>
                                             {` `} Deduction Head CSV
                                          </button>  
                                          <button  className="dropdown-item" onClick={() => downloadDeductionExcel(value.id,months[value.month]+'-'+value.year,value.year+''+value.month)}>
                                             <i className="fa fa-download"></i>
                                             {` `} Deduction Head Excel
                                          </button>  
                                          {value.status === 0 ?
                                           ( <button  className="dropdown-item" onClick={() => uploadDeduction(value.year+'-'+value.month,value.month,value.year)}>
                                            
                                           {` `} Upload Deduction Head
                                        </button>)
                                           : null  
                                        }    
                                         
                                          <button  className="dropdown-item" onClick={() => downloadEarning(value.id,months[value.month]+'-'+value.year,value.year+''+value.month)}>
                                             <i className="fa fa-download"></i>
                                             {` `} Earning Head CSV
                                          </button>  
                                          <button  className="dropdown-item" onClick={() => downloadEarningExcel(value.id,months[value.month]+'-'+value.year,value.year+''+value.month)}>
                                             <i className="fa fa-download"></i>
                                             {` `} Earning Head Excel
                                          </button>  

                                          {value.status === 0 ?
                                           ( 
                                            <button  className="dropdown-item" onClick={() => uploadEarning(value.year+'-'+value.month,value.month,value.year)}>
                                            {` `} Upload Earning Head
                                           </button>
                                           )
                                           : null  
                                        }    

                                        
                                          <button  className="dropdown-item" 
                                             onClick={() => downloadSalarySlip(value.month,value.year)}>
                                             {` `} Salary Slip  
                                           </button>  
                                          
                                          </div>
                                       </div>
                                       </>)
                                         : ''     
                                       }

                                        {
                                           user_typ == '3' && !value.isSalary
                                             ? <button  className="btn btn-sm  btn-danger mr-2" onClick={() => ondlt(value.id)}>Delete Month</button>
                                             : null
                                        }

                                        {
                                           user_typ == '3' && value.status === 1
                                             ? <button  className="btn btn-sm  btn-success mr-2" onClick={() => unblock(value.id)}>Unblock</button>
                                             : null
                                        }
                                         
                                          <CSVLink
                                             data={dwnSalaryMonthsById}
                                             filename={getExcelName+'.csv'}
                                            
                                             className="hidden"
                                             ref={csvLink}
                                             target="_blank"
                                             /> 
                                          
                                          <CSVLink
                                             data={dwnEsiSalaryMonthsById}
                                             filename={getExcelName+'.csv'}
                                             className="hidden"
                                             ref={csvLink2}
                                             target="_blank"
                                             /> 
                                           <CSVLink
                                             data={dwnPfSalaryMonthsById}
                                             filename={getExcelName+'.csv'}
                                             className="hidden"
                                             ref={csvLink3}
                                             target="_blank"
                                             /> 
                                             <CSVLink
                                             data={dwnDeductionMonthsById}
                                             filename={getExcelName+'.csv'}
                                             className="hidden"
                                             ref={csvLink4}
                                             target="_blank"
                                             /> 
                                             <CSVLink
                                             data={dwnEarningMonthsById}
                                             filename={getExcelName+'.csv'}
                                             className="hidden"
                                             ref={csvLink6}
                                             target="_blank"
                                             /> 
                                             
                                        </td>
                                    </tr>
                                        )
                                    })
                                    }
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
            </div>
            </div>
    </>
   )

}
export default Monthly_salary_list