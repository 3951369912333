import React, {useState, useEffect} from 'react'
import DatePicker from "react-datepicker";
import Modal from 'react-modal';
import {Link} from 'react-router-dom'
import Apiconnect from '../../services/Apiconnect';
import moment from 'moment'
import { confirmAlert } from 'react-confirm-alert'; // Import
import { useAlert } from "react-alert";

const LeaveEmployee = () => { 
    const alert = useAlert();
    const locusr = JSON.parse(localStorage.getItem("user"));
    let user_id = Apiconnect.decrypt(locusr.id);

    const [list, setList] = useState([])
    
    const getCreditList = () => {
        Apiconnect.postData(`empLeaveDebit/getall/${Apiconnect.get_company_id()}`).then(res => {
            setList(res.data.data)
        }).catch(err => console.log(err))
    }

    useEffect(() => {getCreditList()}, [])

    //console.log(list)
    const leaveApprove = e => {

        confirmAlert({
           customUI: ({ onClose }) => {
             return (
              <div className='react-confirm-alert'>
                 <div className='react-confirm-alert-body'>
                    <h1>Are you sure?</h1>
                    <p>You want to Approve this Leave?</p>
                    <p><textarea name="mr_comments" style={{width:'100%'}} id="mr_comments" placeholder='Approve/Reject Note' ></textarea></p>
                    <button class="btn badge badge-pill badge-danger" onClick={onClose}>Close</button>
                    <button class="btn badge badge-pill badge-success" style={{marginLeft:20}}
                    onClick={() => {
                       let approval_note = document.getElementById("mr_comments").value;
                       
                       let info = { id: e, status: 1, approved_by: user_id, approval_note:approval_note };
                       let info_enc = Apiconnect.encrypt_obj(info);
                       const inf_a = { enc: info_enc };
                       Apiconnect.postData("employee_leave/updatestatus/", inf_a).then((response) => {
                          alert.success(response.data.message);
                        //   leavedata();
                        getCreditList();
                       });
                       onClose();
                    }}
                    >
                    Yes, Approve Leave!
                    </button>
                 </div>
              </div>
             );
           }
         });

    }

    const leaveCancle = e => {

        confirmAlert({
           customUI: ({ onClose }) => {
             return (
              <div className='react-confirm-alert'>
                 <div className='react-confirm-alert-body'>
                    <h1>Are you sure?</h1>
                    <p>You want to Reject this Leave?</p>
                    <p><textarea name="mr_comments" style={{width:'100%'}} id="mr_comments" placeholder='Approve/Reject Note' ></textarea></p>
                    <button class="btn badge badge-pill badge-danger" onClick={onClose}>Close</button>
                    <button class="btn badge badge-pill badge-warning" style={{marginLeft:20}}
                    onClick={() => {
                       let approval_note = document.getElementById("mr_comments").value;
                       // console.log(approval_note);
                       if(approval_note.trim() == ''){
                         alert.error('Please write reason to Reject leave');
                         return false;
                       }
                       let info = { id: e, status: 2, approved_by: user_id, approval_note:approval_note };
                       let info_enc = Apiconnect.encrypt_obj(info);
                       const inf_a = { enc: info_enc }; 
                       Apiconnect.postData("employee_leave/updatestatus/", inf_a).then((response) => {
                          alert.success(response.data.message);
                        //   leavedata();
                        getCreditList();
                       });
                       onClose();
                    }}
                    >
                    Yes, Reject Leave!
                    </button>
                 </div>
              </div>
             );
           }
         });
    }

  return (
    <>
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-lg-12 col-md-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div style={{ float: "left" }} className="iq-header-title">
                                    <h4 className="card-title">Employee Leave</h4>
                                </div>
                            </div>
                                <div className='row'>
                                    <div class="col-md-12 text-right">
                                        <Link to="/employee_leave_apply"><button className="btn badge badge-pill badge-success mt-3 mr-5">Leave Apply</button></Link>
                                    </div>
                                </div>
                            <div className="iq-card-body">
                                <table  className="table table-striped table-bordered mt-1 center" role="grid" >
                                    <thead className="">
                                    <tr className="clr">
                                        <th scope="col">#</th>
                                        <th scope="col">Employee Name</th>
                                        <th scope="col">Leave Start</th>
                                        <th scope="col">Leave End</th>
                                        <th scope="col">Leave Count</th>
                                        <th scope="col">Leave Reason</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Leave Applied On</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                    </thead>
                                        { 
                                            list.map((item, index) => { 
                                                console.log(item)
                                                var action1 ='';
                                                if (item.status == 0) {
                                                    var status = (
                                                    <div className=" badge badge-pill badge-warning">
                                                        Pending
                                                    </div>
                                                    );
                                                   
                                                }
                                                if (item.status == 1) {
                                                    var status = (
                                                    <div className=" badge badge-pill badge-success">
                                                        Approved
                                                    </div>
                                                    );
                                                }
                                                if (item.status == 2) {
                                                    var status = (
                                                    <div className=" badge badge-pill badge-danger">
                                                        Rejected
                                                    </div>
                                                    );
                                                }

                                                var action='';
                                                var action1 = '';
                                                if (item.status === 0) {
                                                    status = <div className=" badge badge-pill badge-warning">Pending</div>;
                                                    action = <div className="btn badge badge-pill badge-success" onClick={e => leaveApprove(item.id)}>Approve</div>;
                                                    action1 = <div className="btn badge badge-pill badge-danger" onClick={e => leaveCancle(item.id)}>Reject</div>;
                                                }
                                                if (item.status === 1) {
                                                    status = <div className=" badge badge-pill badge-success">Approved</div>;
                                                    action = <div className="btn badge badge-pill badge-danger" onClick={e => leaveCancle(item.id)}>Reject</div>;
                                                }
                                                if (item.status === 2) {
                                                    status = <div className=" badge badge-pill badge-danger">Rejected</div>;
                                                    action = <div className="btn badge badge-pill badge-success" onClick={e => leaveApprove(item.id)}>Approve</div>;
                                                }
                                                // console.log(item);
                                                return(
                                                    <tr>
                                                        <th>{index + 1}</th>
                                                        <td>{item.first_name} {item.last_name}</td>
                                                        <td>{moment(new Date(item.leave_start_date)).format("YYYY-MM-DD")}</td>
                                                        <td>{moment(new Date(item.leave_end_date)).format("YYYY-MM-DD")}</td>
                                                        <td>{item.leave_count}</td>
                                                        <td>{item.reason}</td>
                                                        <td>{item.leave_cat_code}</td>
                                                        <td>{moment(new Date(item.created_on)).format("YYYY-MM-DD")}</td>
                                                        <td>{action}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    <tbody>
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default LeaveEmployee