import React, { Component } from 'react';
import Apiconnect from "../../services/Apiconnect.js";
import { withAlert } from 'react-alert';
import '../pages/admin.css';
import FullScreenLoading from 'react-fullscreen-loading';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

class Employee_manage_salary_wages extends Component {
    constructor(props) {
        super(props);
        this.state = {showView:false,empList:[],selectedEmployeeId:'', earning_head: [], emp_info: '', deduct_head: [], reimbursement_head: [], emp_salary_template: [], emp_salary_template_deduction: [],fullScreen:false };
      
        this.getAllEmpdata();
         this.onUserChange = this.onUserChange.bind(this);
    }

    onUserChange = (e, value) => {
        this.setState({selectedEmployeeId:value.id});
        if(value.id != ''){
            this.setState({'fullScreen':true}) 
            this.loadEmployeeData(value.id)
        }else{
            this.setState({showView:false})
        }

    }


    loadEmployeeData = (id) => {
        this.get_earning_head(id);
        this.getdeductList(id);
        this.getEmpTemplateList(id);
        this.getEmpTemplateDeductionList(id);
        this.handleLoad = this.handleLoad.bind(this);
        this.setState({showView:true})
    }

    // componentDidMount() {
    //     window.addEventListener('load', this.handleLoad);
    //  }
    
    //  componentWillUnmount() { 
    //    window.removeEventListener('load', this.handleLoad)  
    //  }

      getAllEmpdata = () => {
        this.setState({'fullScreen':true}) 
        Apiconnect.getData(`employee/getAll/${Apiconnect.get_company_id()}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            if(_xtract.length>0){
            this.setState({empList:_xtract.reverse()});
            }
            this.setState({'fullScreen':false}) 
        });
    }

    getEmpTemplateList = (id) => {
        var emp_id = id;
        Apiconnect.getData(`employee_salary_template/getEmployeeTemplateById/${emp_id}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            // console.log('tempalte---...>>>>-----');
            // console.log(_xtract);
            // console.log('---...>>>>-----');
            this.setState({ emp_salary_template: _xtract });


        });
    }

    getEmpTemplateDeductionList = (id) => {
        var emp_id = id;
        Apiconnect.getData(`employee_salary_template/getEmployeeTemplateDeductionById/${emp_id}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            this.setState({ emp_salary_template_deduction: _xtract });
            this.setState({'fullScreen':false}) 
        });
    }

    get_earning_head = (id) => {
        var empid = id;
        var company_id = Apiconnect.get_company_id();
        Apiconnect.getData(`employee_salary/getAllhead/${empid}/${company_id}`).then((response) => {
            let ern = Apiconnect.decrypt_obj(response.data.data);
            this.setState({ earning_head: ern });
        });
    };
    getdeductList = (id) => {
        var empid = id;
        Apiconnect.getData(`employee_salary/getDeducthead/${empid}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            this.setState({ deduct_head: _xtract });
            // console.log('_xtract',_xtract)

        });
    };

    handleLoad() {
        this.setState({'fullScreen':true}) 
        setTimeout(() => {
            var obj = {key:0}
            var val = {currentTarget:{value:this.state.earning_head[0].amount}}
            this.onInputChange(val,obj);
            this.setState({'fullScreen':false}) 
        }, 3000)
     }

    onInputChange = (e, i) => {

        let ki = i.key;
        let markers = this.state.earning_head;
        markers[ki] = { ...markers[ki], amount: e.currentTarget.value };
        this.setState({ earning_head: markers });

        let ddx = this.state.deduct_head;
        ddx.map((vk, kk) => {
            if (vk.formula_id > 0) {
                this.formula_man(kk, vk.formula_id);
            }
        })


        let ernz = this.state.earning_head;
        ernz.map((vkx, kkx) => {
            if (vkx.formula_id > 0) {
                this.formula_man_er(kkx, vkx.formula_id);
            }
        })


    };
    onInputChange_deduct = (e, i) => {
        let ki = i.keyx;
        console.log(ki);
        let markers = this.state.deduct_head;
        console.log(markers[ki].abbreviation);
        if( markers[ki].abbreviation !== undefined && markers[ki].abbreviation !== null && markers[ki].abbreviation.toUpperCase()=== 'ESI'){
            markers[ki] = { ...markers[ki], amount: Math.ceil(e.currentTarget.value) };
        }else{
            markers[ki] = { ...markers[ki], amount: e.currentTarget.value };
        }
       
        this.setState({ deduct_head: markers });
    };
   

    changeFormula = (e, i) => {
        let ki = i.keyx;
        let va = e.target.value;
        // console.log(ki);
        // console.log(va);
        //console.log('====....====>>>>'); 
        // this.formula_man(ki, va);

        if (va > 0) {
            this.formula_man(ki, va);
        } else {
            let markers = this.state.deduct_head;
            ////markers[ki] = { ...markers[ki], amount: out };
            markers[ki] = { ...markers[ki], formula_id: va };
            this.setState({ deduct_head: markers });
        }
    };

    formula_man = (ki, va) => {
        Apiconnect.getData(`employee_salary/get_formula/${va}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            var logic = _xtract[0].logic;

            var itm = {};
            this.state.earning_head.map((val, key) => {
                let kkn = val.abbreviation;
                itm[kkn] = val.amount;
            });

            this.state.deduct_head.map((val, key) => {
                let kkn = val.abbreviation;
                itm[kkn] = val.amount;
            });
           
            const kkk = Object.keys(itm);
            const vvv = Object.values(itm);

            var out = 0;
            logic.map((v, k) => {
                var if_con = v.if_condition;
                var chk = v.check_value;
                var opr = v.f_operator;

                kkk.map((v1, k1) => {
                    var finder = v1;
                    var rplcer = vvv[k1];



                    var rr = if_con.replace(finder, rplcer);
                    if_con = rr.toString();
                });
                console.log(if_con)
                var ifcon_o = eval(if_con);
                if (opr == 'BETWEEN') {
                    var rng = chk.split("-");
                    var ifcon_out = ifcon_o + '>=' + rng[0] + ' && ' + ifcon_o + '<=' + rng[1];
                } else {
                    var ifcon_out = ifcon_o + opr + chk;
                }
                // console.log(ifcon_out);
                // console.log(eval(ifcon_out));

                if (eval(ifcon_out)) {
                    out = v.f_then;
                    //console.log('--====-->>');
                    //console.log(out);
                    kkk.map((v1, k1) => {
                        var finder = v1;
                        var rplcer = vvv[k1];
                        //console.log(finder);
                        //console.log(rplcer);
                        var rr = out.replace(finder, rplcer);
                        out = rr.toString();


                    });
                    //console.log(out);


                    out = eval(out);
                    //console.log(out);
                    // console.log('--====-->>');
                } else {
                    out = v.f_else;
                    kkk.map((v1, k1) => {
                        var finder = v1;
                        var rplcer = vvv[k1];
                        var rr = out.replace(finder, rplcer);
                        out = rr.toString();
                    });
                    //console.log(out);
                    out = eval(out);
                    //console.log(out);
                    //console.log('--====-->>');
                }
            });
            ///console.log('::: PTAX -->::' + out);
            let markers = this.state.deduct_head;

            if(markers[ki].abbreviation !== undefined && markers[ki].abbreviation !== null && markers[ki].abbreviation.toUpperCase()  === 'ESI'){
                out = Math.ceil(out)
            }


            markers[ki] = { ...markers[ki], amount: out };
            markers[ki] = { ...markers[ki], formula_id: va };
            this.setState({ deduct_head: markers });

        });
    }



    changeFormula_er = (e, i) => {
        let ki = i.key;
        let va = e.target.value;

       
        if (va > 0) {
            this.formula_man_er(ki, va);
        } else {
            let markers = this.state.earning_head;
            //markers[ki] = { ...markers[ki], amount: out };
            markers[ki] = { ...markers[ki], formula_id: va };
            this.setState({ earning_head: markers });
        }

    };

    formula_man_er = (ki, va) => {

      
        Apiconnect.getData(`employee_salary/get_formula/${va}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            var logic = _xtract[0].logic;

            var itm = {};
            this.state.earning_head.map((val, key) => {
                let kkn = val.abbreviation;
                itm[kkn] = val.amount;
            });
           
            this.state.deduct_head.map((val, key) => {
                let kkn = val.abbreviation;
                itm[kkn] = val.amount;
            });

            const kkk = Object.keys(itm);
            const vvv = Object.values(itm);

            var out = 0;
            logic.map((v, k) => {
                var if_con = v.if_condition;
                var chk = v.check_value;
                var opr = v.f_operator;

                kkk.map((v1, k1) => {
                    var finder = v1;
                    var rplcer = vvv[k1];



                    var rr = if_con.replace(finder, rplcer);
                    if_con = rr.toString();
                });

                var ifcon_o = eval(if_con);
                if (opr == 'BETWEEN') {
                    var rng = chk.split("-");
                    var ifcon_out = ifcon_o + '>=' + rng[0] + ' && ' + ifcon_o + '<=' + rng[1];
                } else {
                    var ifcon_out = ifcon_o + opr + chk;
                }
                //console.log(ifcon_out);
                ///console.log(eval(ifcon_out));

                if (eval(ifcon_out)) {
                    out = v.f_then;
                    //console.log('--====-->>');
                    //console.log(out);
                    kkk.map((v1, k1) => {
                        var finder = v1;
                        var rplcer = vvv[k1];
                        //console.log(finder);
                        //console.log(rplcer);
                        var rr = out.replace(finder, rplcer);
                        out = rr.toString();


                    });
                    //console.log(out);


                    out = eval(out);
                    //console.log(out);
                    //console.log('--====-->>');
                } else {
                    out = v.f_else;
                    kkk.map((v1, k1) => {
                        var finder = v1;
                        var rplcer = vvv[k1];
                        var rr = out.replace(finder, rplcer);
                        out = rr.toString();
                    });
                    //console.log(out);
                    out = eval(out);
                    //console.log(out);
                    //console.log('--====-->>');
                }
            });

            // console.log('ki',ki,'va',va)
            // console.log('amount',out)

            //console.log('::: ERN-- -->::' + out);
            let markers = this.state.earning_head;
            markers[ki] = { ...markers[ki], amount: out };
            markers[ki] = { ...markers[ki],  formula_id: va, };
            // console.log('markers',markers[ki])
            // console.log('markers',markers)
            this.setState({ earning_head: markers });

        });
    }

    submitForm = async e => {
        this.setState({'fullScreen':true}) 
        e.preventDefault();
        var empid = this.state.selectedEmployeeId;
        const localuser = JSON.parse(localStorage.getItem('user'));
        let user_id = Apiconnect.decrypt(localuser.id); 

        let info_enc = Apiconnect.encrypt_obj(this.state.earning_head);
        let deduct_head_enc = Apiconnect.encrypt_obj(this.state.deduct_head);

        const inf_a = { enc: info_enc, enc_deduct: deduct_head_enc, emp_id: empid,user_id:user_id };

        await Apiconnect.postData("employee_salary/create", inf_a).then((response) => {
            this.props.alert.success(response.data.message)
            this.setState({'fullScreen':false}) 
        });
    };

    render() {
    
        const alert = this.props.alert;

        return (

            <div id="content-page" className="content-page">
                {
                    this.state.fullScreen
                    ? <FullScreenLoading loading loaderColor="#3498db" />
                    : null
                }

                <div className="container-fluid">
                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h4 className="card-title">Employee Salary Info</h4>
                            </div>
                        </div>
                        <div className="iq-card-body">
                        <div className="row" style={{marginBottom:'10px'}}>
                        {/* <div className="col-md-3">
                          Employee : <br />
                            <select className="form-control" name="emp_id" onChange={e => this.onUserChange(e)}>
                                <option value="">Select Employee</option>
                                {this.state.empList.map((val, key) => {
                                    return (
                                        <option key={key} value={val.id}>{val.first_name} {val.last_name}</option>
                                    );
                                })}
                            </select>
                        </div> */}
                    
              
                            <div className="col-md-5">
                            <Autocomplete
                                
                                options={this.state.empList}
                                getOptionLabel={(option) => (option.first_name?option.first_name:'')+' '+(option.last_name?option.last_name:'')+' / '+(option.emp_code?option.emp_code.slice(-4):'')+' / '+(option.email?option.email:'')+' / '+(option.phone?option.phone:'')}
                                style={{ width: 600 }}
                                
                                renderInput={params => (
                                    <TextField
                                    {...params}
                                    placeholder='Select Employees'
                                    label="Employees"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    />
                                )}
                                 onChange={this.onUserChange}
                                />
                            </div>
                        
                       
                        </div>
                            
                            {
                                this.state.showView 
                                 ? (
                                    <form onSubmit={e => this.submitForm(e)}>

                                    <div className="border">
    
    
                                        <div className="row">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th width="120" scope="col">#</th>
                                                        <th scope="col">Head</th>
                                                        <th scope="col">Type</th>
                                                        <th width="50%" scope="col">Set Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ background: "#948a9e", padding: "0.3rem" }} colSpan="5"><h6 style={{ color: "#fff", 'fontWeight': "bold" }}>Earning Head</h6></td>
                                                    </tr>
                                                    {this.state.earning_head.map((val, key) => {
    
                                                        let fr_id = this.state.emp_salary_template.find(item => val.id == item.head_id);
                                                       
                                                        let fm_id = '';
                                                        if (fr_id != undefined) {
                                                           
                                                            if (fr_id.formula_id != 0) {
                                                                val.formula_id = fr_id.formula_id
                                                            }
                                                        }
    
                                                        let frmla = val.formula;
                                                        return (
                                                            <tr key={key}>
                                                                <td>{key + 1}</td>
                                                                <td>{val.name}  </td>
                                                                <td>
                                                                    <select className="form-control" value={val.formula_id} onChange={e => this.changeFormula_er(e, { key })}  >
                                                                        <option>Static Data</option>
                                                                        {frmla.map((valza, keyza) => {
                                                                            return (<option key={keyza} value={valza.id}>{valza.title}</option>);
                                                                        })}
                                                                    </select>
                                                                </td>
    
                                                                <td>
                                                                    <input name={val.id} className="form-control" value={val.amount} placeholder="Amount" onChange={e => this.onInputChange(e, { key })} />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    <tr>
                                                    <td colSpan="3"><h6>Earning Total</h6></td>
                                                    <td colSpan="3">{this.state.earning_head.reduce((a, v) => a = Number(a) + Number(v.amount), 0)}</td>
                                                    </tr>
                                                    <td style={{ background: "#948a9e", padding: "0.3rem" }} colSpan="5"><h6 style={{ color: "#fff", 'fontWeight': "bold" }}>Deduction Head</h6></td>
    
                                                    {this.state.deduct_head.map((valx, keyx) => {
    
                                                        let fr_id = this.state.emp_salary_template.find(item => valx.id == item.head_id);
                                                        let fm_id = '';
                                                        if (fr_id != undefined) {
                                                            if (fr_id.formula_id != 0) {
                                                                // fm_id = fr_id.formula_id;
                                                                if (valx.formula_id == null) {
                                                                    fm_id = fr_id.formula_id;
                                                                    valx.formula_id = fm_id;
                                                                }
                                                            }
                                                        }
    
                                                        let frml = valx.formula;
                                                        return (
                                                            <tr key={keyx}>
                                                                <td>{keyx + 1}</td>
                                                                <td>{valx.name} </td>
                                                                <td>
                                                                    <select className="form-control" value={valx.formula_id} onChange={e => this.changeFormula(e, { keyx })}  >
                                                                        <option value="">Static Data</option>
                                                                        {frml.map((valz, keyz) => {
                                                                            return (<option key={keyz} value={valz.id}>{valz.title}</option>);
                                                                        })}
                                                                    </select>
                                                                </td>
    
                                                                <td>
    
                                                                    <input placeholder="Amount" className="form-control" name={valx.id} value={valx.amount} defaultValue={valx.amount} onChange={e => this.onInputChange_deduct(e, { keyx })} />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    <tr>
                                                    <td colSpan="3"><h6>Deduction Total</h6></td>
                                                    <td colSpan="3">{this.state.deduct_head.reduce((a, v) => a = Number(a) + Number(v.amount), 0)}</td>
                                                    </tr>
    
                                                    <tr style={{ background: "#948a9e", padding: "0.3rem" }}>
                                                    <td colSpan="3"><h6 style={{ color: "#fff", 'fontWeight': "bold" }}>Net Amount</h6></td>
                                                    <td>{this.state.earning_head.reduce((a, v) => a = Number(a) + Number(v.amount), 0) - this.state.deduct_head.reduce((a, v) => a = Number(a) + Number(v.amount), 0)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
    
    
                                    </div>
    
                                    <div className="row">
                                        <div className="col-md-12 mt-4">
                                            <button type="submit" name="id" className="btn btn-primary mr-2">  Proceed </button>
                                        </div>
                                    </div>
                                   
    
                                </form>
                                 )
                                 : null
                            }
                          
                        </div>
                    </div>
                </div>
            </div>





        );
    }
}

export default withAlert()(Employee_manage_salary_wages);