import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";
import { Link } from "react-router-dom";
import "./admin.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Modal from "react-modal";
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";
import Loadder from "./Loadder";
import Loadder_progress from "./Loadder_progress";
import Employee_manage_view from "./Employee_manage_view.js";
import Axios from "axios";
import Pagination from "react-js-pagination";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

// const { createProxyMiddleware } = require('http-proxy-middleware');

const Employee_manage_list = () => {
  const alert = useAlert();
  let history = useHistory();
  const localuser = JSON.parse(localStorage.getItem("user"));
  let employee_id = Apiconnect.decrypt(localuser.id);
  let AuthStr = localuser.accessToken;
  let user_typ = Apiconnect.decrypt(localuser.emp_typ);

  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [List, setEmployeeList] = useState([]);
  const [catList, setCategory] = useState([]);
  const [dept_id, setDept] = useState(null);
  const [shift_id, setShiftId] = useState(null);
  const [allchecked, setallchecked] = useState(null);
  const [selectedEmp, setSelectedEmp] = useState([]);
  // const [selectedCountEmp, setSelectedCountEmp] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalEmpIsOpen, setEmpModalIsOpen] = useState(false);
  const [modalActiveEmpIsOpen, setActiveEmpModalIsOpen] = useState(false);

  const [modalLodder, setModalLodder] = useState(false);
  const [modalProgressLodder, setModalProgressLodder] = useState(false);
  const [modalProgressLodder1, setModalProgressLodder1] = useState(false);

  const [shiftList, setShift] = useState([]);
  const [dwnEmpList, setDwnEmpList] = useState([]);
  const [dwnEmpSalaryList, setDwnEmpSalaryList] = useState([]);
  const [dwnEmpAttendanceList, setDwnEmpAttendanceList] = useState([]);
  const [changeButtonAtt, setChangeButtonAtt] = useState("false");
  const [searchTerm, setSearchTerm] = useState('')
  const [empStatus, setEmpStatus] = useState(1);

  useEffect(() => {
    getEmployeeList();
    getCategory();
    getEmployees(empStatus,'');
    getEmployeeSalary();
    getEmployeeList_new("50", "0", "",'',empStatus);
    getEmpCount("",'',empStatus);
  }, []);


  const csvLink = useRef();

  const getEmployeeList = () => {
    setLoader(true);
    Apiconnect.getData(`employee/getall/${Apiconnect.get_company_id()}`).then((response) => {
       let _xtract = Apiconnect.decrypt_obj(response.data.data);
       setEmployeeList(_xtract.reverse());
       setLoader(false);
    });
  };

  ////--------> Start Pagination And Search<--------/////

  const [activePage, setActivePage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    let offset = (pageNumber - 1) * 50;
    console.log(`active page is ${pageNumber} -- ${offset}`);
    let limit = 50;
    getEmployeeList_new(limit, offset, "",dept_id,empStatus);
  };

  const getEmployeeList_new = (limit, offset, search,dept_id,empStatus) => {
    setLoader(true);
    const data = {
      limit: limit,
      offset: offset,
      search: search,
      company_id: Apiconnect.get_company_id(),
      dept_id:dept_id,
      empStatus:empStatus
    };
    Apiconnect.postData(`employee/getallemployeelist`, data).then(
      (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        console.log('test',_xtract.reverse())
        setEmployeeList(_xtract.reverse());
        setLoader(false);
      }
    );
  };

  const [empCount, setempCount] = useState(0);
  const getEmpCount = (search,dept_id,empStatus) => {

    var dept_id1 = '';
    if(dept_id != '' && typeof dept_id != 'undefined' && dept_id != null){
       dept_id1 = dept_id;
    }

    const data = {
      search: search,
      company_id: Apiconnect.get_company_id(),
      dept_id:dept_id1,
      empStatus:empStatus
    };
    

    Apiconnect.postData(
      `employee/getEmpSearchcount`,data
    ).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setempCount(_xtract[0].cnt);
    });
  };


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(searchTerm)
      
      let offset = (activePage - 1) * 50;
      let limit = 50;
     
      getEmployeeList_new(limit, offset, searchTerm,dept_id,empStatus);

      var dept_id1 = '';
      if(dept_id != '' && typeof dept_id != 'undefined' && dept_id != null){
         dept_id1 = dept_id;
      }
  
      var dept_id1 = '';
      const data = {
        search: searchTerm,
        company_id: Apiconnect.get_company_id(),
        dept_id:dept_id1,
        empStatus:empStatus
      };
  
      Apiconnect.postData(
        `employee/getEmpSearchcount`,data
      ).then((response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setempCount(_xtract[0].cnt);
      });
  
    }, 2000)
  
    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  ////--------> End Pagination And Search<--------/////

  const getEmployeeListByDept = (id) => {
    setLoader(true);
    Apiconnect.getData(`employee/getallbydept/${id}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setEmployeeList(_xtract.reverse());
      setLoader(false);
    });
  };

  const getEmployees = (empStatus,dept_id) => {
    var company_id = Apiconnect.get_company_id();
    var dept_id2 = '';
    if(dept_id != '' && typeof dept_id != 'undefined' && dept_id != null){
       dept_id2 = dept_id;
    }
    Apiconnect.getData(`employee/allempinfo/${company_id}/${empStatus}/${dept_id2}`).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      setDwnEmpList(_xtract.reverse());
    });
  };

  const getEmployeeSalary = () => {
    var company_id = Apiconnect.get_company_id();
    Apiconnect.getData(`employee/allempsalaryinfo/${company_id}`).then(
      (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setDwnEmpSalaryList(_xtract.reverse());
      }
    );
  };
  const getEmployeeAttendance = () => {
    setModalLodder("true");
    var company_id = Apiconnect.get_company_id();
    Apiconnect.getData(`employee/allempAttendaceinfo/${company_id}`).then(
      (response) => {
        //  Axios.get(`http://localhost:3050/api/employee/allempAttendaceinfo/${company_id}`).then((response) => {

        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setDwnEmpAttendanceList(_xtract.reverse());
        setChangeButtonAtt("false");
        setModalLodder(false);
        console.log(_xtract);

        csvLink.current.link.click();
      }
    );
  };

  const attendance_popup = (e) => {
    confirmAlert({
      message: "Download Employee Attendance",
      buttons: [
        {
          label: "Yes",
          onClick: () => getEmployeeAttendance(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const getCategory = () => {
    var company_id = Apiconnect.get_company_id();
    Apiconnect.getData(`company_category/getall/${company_id}`).then(
      (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setCategory(_xtract.reverse());
      }
    );
  };

  const onDeptChange = (e) => {
    let dept_id = e.target.value;
    getEmployees(empStatus,dept_id);
    getEmployeeList_new("50", "0", "",dept_id,empStatus);
    getEmpCount('',dept_id,empStatus)
    setDept(dept_id);
  };

  const onSatusChange = (e) => {
    let st_id = e.target.value;
    getEmployees(st_id,dept_id);
    getEmployeeList_new("50", "0", "",dept_id,st_id);
    getEmpCount('',dept_id,st_id)
    setEmpStatus(st_id);
  };

  

  const onShiftChange = (e) => {
    let shift_id = e.target.value;
    setShiftId(shift_id);
    //console.log(e.target.value);
  };

  const onInputChange2 = (e) => {
    setallchecked(null);

    if (e.target.checked === true) {
      setSelectedEmp({
        ...selectedEmp,
        [e.currentTarget.name]: e.currentTarget.value,
      });
      // setSelectedCountEmp({ ...selectedCountEmp, [e.currentTarget.name]: e.currentTarget.value });
    } else {
      setSelectedEmp({ ...selectedEmp, [e.currentTarget.name]: 0 });

      // let ck12 = Object.keys(selectedCountEmp).find(function (element) {
      //    if(selectedCountEmp[element] == e.currentTarget.name){
      //       delete selectedCountEmp[element];
      //    }
      // });
    }
  };

  const sellectAll = (e) => {
    if (e.target.checked === true) {
      // console.log(e.target.checked);
      setallchecked("checked");
      let ob = {};
      List.map((val, key) => {
        ob[val.id] = val.id;
      });
      setSelectedEmp(ob);
      // setSelectedCountEmp(ob);
    } else {
      setallchecked(null);
      setSelectedEmp([]);
      // setSelectedCountEmp([]);
    }
  };

  // const sync_attendace = async (e) =>   {
  //    setModalLodder(true);
  //    var formData = new FormData();
  //    formData.append('company_domain', process.env.React_App_DETECTION_DOMAIN);
  //    Axios.post(`${process.env.React_App_DETECTION_API}/restoreEmployee`, formData)
  //      .then(response =>{
  //       console.log(response)
  //       if(response.data.Status === "Success"){
  //          setModalLodder(false);
  //          alert.success('Successfully Sync');
  //       }else{
  //          setModalLodder(false);
  //          alert.success('Something Went Wrong');
  //       }
  //      });
  // }

  const [progressCount, setProgressCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [noSync, setNoSync] = useState(0);

  const sync_attendace_new = async (e) => {
    if (selectedEmp == "") {
      confirmAlert({
        title: "Select Employee",
        buttons: [
          {
            label: "OK",
          },
        ],
      });
      return false;
    }

    setTotalCount(Object.keys(selectedEmp).length);

    setModalProgressLodder(true);
    var emp_data = Object.keys(selectedEmp);
    setProgressCount(0);
    var cnt = 0;
    var cnt1 = 0;
    for (var emp_id of emp_data) {
      if (emp_id != 0) {
        var formData = new FormData();
        formData.append(
          "company_domain",
          process.env.React_App_DETECTION_DOMAIN
        );
        formData.append("emp_id", emp_id);
        var res = await Axios.post(
          `${process.env.React_App_DETECTION_API}/restoreSingleEmployee`,
          formData
        ).then((response) => {
          return response;
        });
        if (res.data.Status == "Success") {
          setProgressCount(++cnt);
        } else {
          setNoSync(++cnt1);
        }
      }
    }
    alert.success("Successfully Sync");
    //   setModalProgressLodder(false);
  };

  var percentage = Math.round(((progressCount + noSync) / totalCount) * 100);
  if (percentage == 100) {
    var timerId = setTimeout(() => {
      setModalProgressLodder(false);
      timerId = null;
    }, 3000);
  }

  const shift_popup = (e) => {
    if (selectedEmp != "") {
      setModalIsOpen(true);
      getShift();
      // console.log(selectedEmp);
    } else {
      confirmAlert({
        title: "Select Employee",
        buttons: [
          {
            label: "OK",
          },
        ],
      });
    }
  };


  const getShift = () => {
    Apiconnect.getData(`shift/getall/${Apiconnect.get_company_id()}`).then(
      (response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setShift(_xtract.reverse());
      }
    );
  };

  const ondlt = (id) => {
    confirmAlert({
      title: "Are you sure to Delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteItem(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const deleteItem = (id) => {
    Apiconnect.deleteData(`employee/delete/${id}/${employee_id}`).then(
      (response) => {
        getEmployeeList_new("50", "0", "",'',empStatus);
        alert.success(response.data.message);
      }
    );
  };

  const set_attendance = (id) => {
    confirmAlert({
      title: "Are you sure to set this as Attendance Image ?",
      message:
        "This will be used for face recognition while marking attendance.",
      buttons: [
        {
          label: "Yes",
          onClick: () => copy_profile_to_attendance(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const copy_profile_to_attendance = (id) => {
    Apiconnect.getData(`employee_info/copy_profile_to_attendance/${id}`).then(
      (response) => {
        sync_attendace(id);
        getEmployeeList_new("50", "0", "",'',empStatus);
        alert.success(response.data.message);
      }
    );
  };

  const sync_attendace = async (emp_id) => {
    if (emp_id != 0) {
      var formData = new FormData();
      formData.append("company_domain", process.env.React_App_DETECTION_DOMAIN);
      formData.append("emp_id", emp_id);
      var d = await Axios.post(
        `${process.env.React_App_DETECTION_API}/restoreSingleEmployee`,
        formData
      ).then((response) => {
        return emp_id;
      });
    }
    alert.success("Successfully Sync");
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const inf_a = { shift_id: shift_id, employee: selectedEmp };
    console.log(inf_a);
    await Apiconnect.postData("employee/shift_assign_update", inf_a).then(
      (response) => {}
    );
    alert.success("Shift Assign Successfully");
    setModalIsOpen(false);
    setSelectedEmp([]);
    // setSelectedCountEmp([]);
    setallchecked(null);
    getEmployeeList_new("50", "0", "",'',empStatus);
  };

  const customStyles = {
    content: {
      top: "55%",
      width: "60%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const customStyles1 = {
    content: {
      top: "55%",
      width: "60%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "unset ",
      backgroundColor: "unset ",
    },
  };

  const customStyles3 = {
    content: {
      top: "55%",
      width: "45%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      height : '450px'
    },
  };

  ///////---------->Start Employee View<-----------/////////
  const [viewId, setviewId] = useState(null);

  const viewItem = (id) => {
    setviewId(id);
    setEmpModalIsOpen(true);
  };
  ///////---------->End Employee View<-----------/////////

  // background-color: rgba(0, 0, 0, 0.75) !important;    position: fixed;inset: 0px;
  const [leavingDateLock, setLeavingDateLock] = useState(false);
  const [leavingDate, setLeavingDate] = useState(null);
  const [getStatus, setStatus] = useState(null);

  const activeEmployee = (id,status) => {
    getItem(id)
    setviewId(id);
    setStatus(status)
    setActiveEmpModalIsOpen(true);
  };

  const getItem = (emp_id) => {
        Apiconnect.getData(`employee_info/get/${emp_id}`).then((response) => {
            let _xtract = Apiconnect.decrypt_obj(response.data.data);
            if (_xtract.length > 0) {
                  setLeavingDate(null);
                if (_xtract[0].leaving_date !== null && _xtract[0].leaving_date !== '0000-00-00') {
                    setLeavingDate(new Date(_xtract[0].leaving_date));
                    setLeavingDateLock(true)
                }
            }
        });
};

const onSubmitActiveStatus = async () => {

  if (leavingDate === null) {
    var leaving_date1 = leavingDate;
  } else {
      var leaving_date1 = format(leavingDate, 'yyyy-MM-dd');
  }
  
  const inf_a = { status: getStatus, employee: viewId,leaving_date:leaving_date1 };
  await Apiconnect.postData("employee/employee_status_update", inf_a).then(
    (response) => {
      alert.success(response.data.message);
      setActiveEmpModalIsOpen(false);
      getEmployeeList_new("50", "0", "",'',empStatus);
      getEmpCount("",'',empStatus);
    }
  );

};

/* ----------- download excel--------- */
  const dwnEmpListxlsx = dwnEmpList.map((item) => (
    {
      EmpCode: item.emp_code,
      Name: `${item.first_name} ${item.last_name}`,
      Mobile: item.phone,
      Email: item.email
    }
  ))


function getSheetData(data, header) {
  var fields = Object.keys(data[0]);
  var sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
}
const local_user = JSON.parse(localStorage.getItem('user'));
const [companyaddress, setCompanyData] = useState([])
const getCompany = () => {         
  Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then((response) => {
        let ern = Apiconnect.decrypt_obj(response.data.data);
        setCompanyData(ern[0].address_one)
       
    });
};
useEffect(() => {getCompany()}, [])

const saveAsExcel = () => {
  let header = ["emp_code",	"reporting_officer",	"employee_group",	"department",	"employement_type",	"first_name",	"middle_name",	"last_name",	"gender",	"date_of_birth",	"email",	"phone",	"phone_secondary",	"email_secondary",	"is_active",	"present_address_one",	"present_address_two",	"present_state_id",	"present_city_id",	"present_area",	"present_zip",	"present_landmark",	"parmanent_address_one",	"parmanent_address_two",	"parmanent_state_id",	"parmanent_city_id",	"parmanent_area",	"parmanent_zip",	"parmanent_landmark",	"blood_group",	"religion",	"caste",	"nationality",	"marital_status",	"anniversary_date",	"emergency_contact_person",	"emergency_relationship_person_id",	"emergency_contact_number",	"emergency_contact_person2",	"emergency_relationship_person_id2",	"emergency_contact_number2",	"pan",	"passport_no",	"aadhaar",	"payment_mode",	"bank_name",	"ifsc_code",	"account_number",	"is_epf",	"UAN_number",	"epf_number",	"pf_start_date",	"is_esi",	"esi_number",	"dispencery",	"confirmation_date",	"joining_date",	"resign_date",	"leaving_date",	"promotion_date",	"transfer_date",	"rf_id",	"emp_shift",	"salary_template",	"ctc_yearly",	"emp_designation"]

  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);
    const sheetData = getSheetData(dwnEmpList, header);
    const totalColumns = sheetData[0].length;

    sheet1.cell("A1").value(local_user.company_name);
    sheet1.cell("A2").value(companyaddress)
    sheet1.cell("A3").value("All Employee List");
    sheet1.cell("A5").value(sheetData);

    const range = sheet1.usedRange();
    const endColumn = String.fromCharCode(64 + totalColumns);

    sheet1.row(1).style("bold", true);
    // sheet1.range("A5:" + endColumn + "5").style("fill", "BFBFBF");
    range.style("border", true);

    sheet1.range("A1:I1").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true);
    sheet1.range("A2:I2").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
    sheet1.range("A3:I3").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
    sheet1.range("A5:BN5").style({horizontalAlignment: "center", verticalAlignment: "center", }).style("bold", true).style("fill", "BFBFBF");;

    return workbook.outputAsync().then((res) => {
      saveAs(res, "employee list.xlsx");
    });
  });
}

/* -------------password update modal---------- */
const [modal, setModal] = useState(false);
const [pass, setPass] = useState("")

const customCss = {
  content: {
     marginTop: "50px",
     top: '50%', width: '40%',
     left: '50%',
     right: 'auto',
     bottom: 'auto',
     marginRight: '-50%',
     transform: 'translate(-50%, -50%)',
     height:'30%',
  },
};

const handleClick = () => {
  let inf_a = {employee_password: pass, employee_ids:selectedEmp}
  Apiconnect.postData(`employee_management/update_employee_password_by_ids`, inf_a).then(res => {
    alert.success("Password Changed")
    setPass("")
    setSelectedEmp([]);
    setModal(false)
  })
}

  return (
    <>
      <div id="content-page" className="content-page">
        <div className="container-fluid">
          {/* ///////---------->Start Employee View<-----------///////// */}

         
          <div>
          <Modal isOpen={modal} style={customCss} onRequestClose={() => setModal(false)}>
               <div className="iq-card">
                  <div className="iq-card-header d-flex justify-content-between model_head">
                     <div className="iq-header-title">
                        <h4 className="card-title ">Change Password</h4>
                     </div>
                  </div>
                  <div className="iq-card-body">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="form-group">
                              <input  className="form-control" type="text" placeholder='Change Password' name="pass" value={pass} onChange={(e) => setPass(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-3">
                              <button className="btn badge badge-pill badge-success" onClick={handleClick}>Submit</button>
                        </div>
                    </div>
                  </div>
                  <div className="text_center">
                     <button className="btn btn-info" onClick={() => setModal(false)}>close</button>
                  </div>
               </div>
            </Modal>
            <Modal
              isOpen={modalEmpIsOpen}
              style={customStyles}
              onRequestClose={() => setEmpModalIsOpen(false)}
            >
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between model_head">
                  <div className="iq-header-title">
                    <h4 className="card-title ">Employee Details</h4>
                  </div>
                </div>
                <div className="iq-card-body">
                  <div className="row">
                    <div className="col-md-12">
                      {viewId !== null ? (
                        <Employee_manage_view emp_id={viewId} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <button
                    className="btn btn-info"
                    onClick={() => setEmpModalIsOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
          </div>
          
            {/* ////////////------------>Active Emplyee<------------////////////////// */}
          <div>
            <Modal
              isOpen={modalActiveEmpIsOpen}
              style={customStyles3}
              onRequestClose={() => setActiveEmpModalIsOpen(false)}
            >
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between model_head">
                  <div className="iq-header-title">
                    <h4 className="card-title ">Employee Management</h4>
                  </div>
                </div>
                <div className="iq-card-body">
                  <div className="row">
                    <div className="col-md-12">
                  
                    <div className="col-md-10">
                        <div className="form-group">
                            <label>Relieving Date</label>
                            <DatePicker
                                selected={leavingDate}
                                onChange={e => setLeavingDate(e)}
                                className="form-control datelabel"
                                dateFormat='yyyy-MM-dd'
                                placeholderText="Relieving Date"
                                name="leaving_date"
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                scrollableMonthDropdown
                                yearDropdownItemNumber={100}
                                dropdownMode="select"
                                // maxDate={new Date()}
                                // readOnly = {leavingDateLock ? user_typ === '3' ? false : true : false }
                                />
                        </div>
                    </div>
                       
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-2" style={{textAlign:'center'}}>
                <button
                style={{marginRight :'5px' , pointerEvents : leavingDate ? '' : 'none'}}
                    className="btn btn-success"
                    onClick={() => onSubmitActiveStatus()}
                  >
                    Submit
                  </button>
                  <button
                    className="btn btn-info"
                    onClick={() => setActiveEmpModalIsOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
          </div>


          {/* ////////////------------>Lodder<------------////////////////// */}
          <div>
            <Modal isOpen={modalLodder} style={customStyles1}>
              {<Loadder />}
            </Modal>
          </div>
          {/* ////////////------------>Lodder<------------////////////////// */}

          {/* ////////////------------>Lodder<------------////////////////// */}
          <div>
            <Modal isOpen={modalProgressLodder} style={customStyles1}>
              {
                <Loadder_progress
                  sync_count={progressCount}
                  noSync={noSync}
                  total={totalCount}
                />
              }
            </Modal>
          </div>
          {/* ////////////------------>Lodder<------------////////////////// */}

          {/* ////////////------------>Lodder<------------////////////////// */}
          {/* <div >
                  <Modal isOpen={modalProgressLodder1} style={customStyles1}>
                                 {<Loadder_progress_bar sync_count={progressCount1} noSync="0" total={totalCount1} />}
                  </Modal>
               </div> */}
          {/* ////////////------------>Lodder<------------////////////////// */}

          {/* ///////---------->End Employee View<-----------///////// */}

          <div>
            <Modal
              isOpen={modalIsOpen}
              style={customStyles}
              onRequestClose={() => setModalIsOpen(false)}
            >
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between model_head">
                  <div className="iq-header-title">
                    <h4 className="card-title ">Assign Shift</h4>
                  </div>
                </div>

                <div className="iq-card-body">
                  <form onSubmit={(e) => onSubmit(e)}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Shift <span className="required">*</span>
                          </label>
                          <select
                            className="form-control"
                            required
                            name="shift_id"
                            value={shift_id}
                            onChange={(e) => onShiftChange(e)}
                          >
                            <option value="">Select Shift</option>
                            {shiftList.map((val, key) => {
                              return (
                                <option key={key} value={val.id}>
                                  {val.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-2">
                      <button
                        type="submit"
                        name="id"
                        className="btn btn-primary mr-2"
                      >
                        Submit
                      </button>
                      <button
                        className="btn btn-info"
                        onClick={() => setModalIsOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
                {/* <div className="text_center">
                        <button className="btn btn-info" onClick={() => setModalIsOpen(false)}>close</button>
                        </div> */}
              </div>
            </Modal>
          </div>

          <div className="row">
            <div className="col-sm-12 col-lg-12 col-md-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div style={{ float: "left" }} className="iq-header-title">
                    <h4 className="card-title">List Of Employee</h4>
                  </div>
                  <div style={{ float: "right" }}>
                    <Link
                      style={{ float: "right", marginLeft: "5px" }}
                      className="btn badge badge-pill mr-1 btn-success float-right"
                      to="/employee_manage/0"
                    >
                      Add New
                    </Link>

                    {
                      user_typ === '3'
                      ? (
                          <Link
                          style={{ float: "right" }}
                          className="btn btn-info"
                          to="/employee_upload_bulk"
                        >
                          Upload Bulk
                        </Link>
                      )
                      : null
                    }
                   
                  </div>
                </div>
                <div className="iq-card-body">
                  {/* <div className="" style={{float:'right'}}>
                        <div className="col-md-2">
                            <button style={{ marginTop: '30px' }} onClick={() => { shift_popup(); }}
                                    className="btn btn-info mr-2">Shift Assign</button>
                              </div>
                        </div> */}
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Department</label>
                        <select
                          className="form-control"
                          required
                          name="emp_category"
                          value={dept_id}
                          onChange={(e) => onDeptChange(e)}
                        >
                          <option value="">Select Department</option>
                          {catList.map((val, key) => {
                            return (
                              <option key={key} value={val.id}>
                                {val.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <button
                        style={{ marginTop: "30px" }}
                        onClick={() => {
                          shift_popup();
                        }}
                        className="btn btn-info mr-2"
                      >
                        Shift Assign
                      </button>
                    </div>

                    <div className="col-md-7">
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td>
                            {" "}
                            <CSVLink
                              filename={"employee-data.csv"}
                              style={{ marginTop: "20px", float: "right" }}
                              className="btn btn-warning btn-sm"
                              data={dwnEmpList}
                            >
                              <i class="fa fa-download"></i>Employee Data CSV
                            </CSVLink>
                          </td>
                          <td>
                            <button className="btn btn-info btn-sm mr-2 mt-3 ml-3" onClick={saveAsExcel}><i class="fa fa-download"></i>Employee Data Excel</button>
                          </td>
                          <td>
                            {" "}
                            <CSVLink
                              filename={"employee-salary.csv"}
                              style={{
                                marginTop: "20px",
                                float: "right",
                                marginLeft: "5px",
                              }}
                              className="btn btn-sm btn-primary"
                              data={dwnEmpSalaryList}
                            >
                              <i class="fa fa-download"></i>Employee Salary CSV
                            </CSVLink>
                          </td>
                          <td>
                            <div>
                              <button
                                className="btn btn-info btn-sm mr-2 "
                                onClick={() => {
                                  attendance_popup();
                                }}
                                style={{
                                  marginTop: "20px",
                                  float: "right",
                                  marginLeft: "5px",
                                }}
                              >
                                <i class="fa fa-download"></i> Employee
                                Attendance CSV
                              </button>
                              <CSVLink
                                data={dwnEmpAttendanceList}
                                filename="employee-attendance.csv"
                                className="hidden"
                                ref={csvLink}
                                target="_blank"
                              />
                            </div>

                            {/* {
                                             (changeButtonAtt === 'false')?
                                             <CSVLink filename={"employee-attendance.csv"} style={{ marginTop: '20px', float: 'right', marginLeft: '5px' }} className="btn btn-sm btn-info" data={dwnEmpAttendanceList}  >
                                                 <i class="fa fa-download"></i>Employee Attendance12
                                             </CSVLink>:
                                             <button style={{ marginTop: '20px',float: 'right', marginLeft: '5px' }} onClick={() => { attendance_popup(); }} className="btn btn-info btn-sm mr-2 ">Employee Attendance</button>
                                             
                                          } */}

                            {/* <CSVLink filename={"employee-attendance.csv"} style={{ marginTop: '20px', float: 'right', marginLeft: '5px' }} className="btn btn-sm btn-info" data={dwnEmpAttendanceList}  >
                                            <i class="fa fa-download"></i>Employee Attendance
                                         </CSVLink> */}
                          </td>
                          <td>
                            {" "}
                            <button
                              style={{
                                marginTop: "20px",
                                float: "right",
                                backgroundColor: "#f7786b",
                                borderColor: "#f7786b",
                              }}
                              onClick={() => {
                                sync_attendace_new();
                              }}
                              className="btn btn-info btn-sm mr-2"
                            >
                              Sync Attendance images
                            </button>
                          </td>
                          <td>
                            {" "}
                            <button className="btn btn-info btn-sm mr-2" style={{
                                marginTop: "20px",
                                float: "right",
                                backgroundColor: "#7F00FF",
                                borderColor: "#7F00FF",
                              }}
                              onClick={() => setModal(true)}
                              >Change password</button>
                          </td>
                         
                        </tr>
                      </table>
                    </div>

                    {/* <div className="col-md-2">
                                
                              </div> */}
                  </div>
                  <div className="row">
                  <div className="col-md-3">
                      <div className="form-group">
                        <label>Employee Status</label>
                        <select
                          className="form-control"
                          required
                          name="emp_status"
                          value={empStatus}
                          onChange={(e) => onSatusChange(e)}
                        >
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                          <option value="">Both</option>
                        </select>
                      </div>
                    </div>  
                           
                    <div className="col-md-3" style={{position:'absolute',paddingTop:'25px',right:0}}> 
                  <div style={{ padding: "10px", textAlign: "right" }}>
                    <div>
                      <input
                        type="text"
                        placeholder="Search here..."
                        name="search"
                        // onChange={(e) => inputsearch(e)}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                  </div>
                  </div>

                  <table className="table table-striped table-hover">
                    <thead className="">
                      <tr>
                        <th scope="col">
                          <input
                            type="checkbox"
                            name="select_all"
                            checked={allchecked}
                            onChange={(e) => sellectAll(e)}
                          />
                        </th>
                        <th scope="col">#</th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Profile
                        </th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Attendance Profile
                        </th>
                        <th scope="col">Emp Code</th>
                        <th scope="col">Name</th>
                        <th scope="col">Mobile</th>
                        <th scope="col">Email</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {loader ? (
                        <Loadder />
                      ) : (
                        List.map((val, key) => {
                          if (val.display_picture == null) {
                            var profile_img = "/images/user/1.jpg";
                          } else {
                            const min = 1;
                            const max = 1000;
                            var rand = min + Math.random() * (max - min);
                            var profile_img =
                              process.env.React_App_BASE_URL_API +
                              "/uploads/thumbdp/" +
                              val.display_picture +
                              "?" +
                              rand;
                          }

                          if (val.thumb == null) {
                            var atten_img = "";
                          } else {
                            const min = 1;
                            const max = 1000;
                            var rand = min + Math.random() * (max - min);
                            var atten_img =
                              process.env.React_App_BASE_URL_API +
                              "/uploads/thumb/" +
                              val.thumb +
                              "?" +
                              rand;
                          }

                          // var profile_img = '/images/user/1.jpg';
                          // var atten_img = '/images/user/1.jpg';

                          var ck = Object.keys(selectedEmp).find(function (
                            element
                          ) {
                            return selectedEmp[element] == val.id;
                          });

                          var ck1 = "";
                          if (ck !== undefined) {
                            ck1 = true;
                          }

                          return (
                            <tr key={key}>
                              <th scope="row">
                                <input
                                  type="checkbox"
                                  name={val.id}
                                  value={val.id}
                                  checked={ck1}
                                  onChange={(e) => onInputChange2(e)}
                                 
                                />
                              </th>
                              <th scope="row">{key + 1}</th>
                              <td
                                style={{ width: "130px", textAlign: "center" }}
                              >
                                <div className="" style={{ display: "grid" }}>
                                  <img
                                    src={profile_img}
                                    alt="profile-img"
                                    className="prof"
                                  />
                                  <Link to={`/fr_upload_image/${val.id}`}>
                                    Change Pic
                                  </Link>
                                  {/* <button>Set As Attendance Image</button> */}
                                  <a
                                    href="#"
                                    onClick={() => {
                                      set_attendance(val.id);
                                    }}
                                  >
                                    Set As Attendance
                                  </a>
                                  {/* <button
                                                className="btn  mr-2">Set As Attendance Image</button> */}
                                </div>
                              </td>
                              <td
                                style={{ width: "130px", textAlign: "center" }}
                              >
                                {atten_img != "" ? (
                                  <div className="" style={{ display: "grid" }}>
                                    <img
                                      src={atten_img}
                                      alt="profile-img"
                                      className="prof"
                                    />
                                    {/* <Link to={`/fr_upload_image/${val.id}`} >Change Pic</Link> */}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>{val.emp_code}</td>
                              <td>
                                {val.first_name} {val.last_name}
                              </td>
                              <td>{val.phone}</td>
                              <td>{val.email}</td>
                              <td style={{width:'250px'}}>
                                <button
                                  onClick={() => {
                                    viewItem(val.id);
                                  }}
                                  className="btn btn-info mr-2 m-1"
                                >
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </button>

                                {
                                  user_typ === '3' 
                                  ? (
                                    <button
                                    onClick={() => {
                                      ondlt(val.id);
                                    }}
                                    className="btn btn-warning mr-2"
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  ) 
                                  : null
                                }
                               

                                <div className="dropdown f_left">
                                  <span
                                    className="dropdown-toggle btn "
                                    id="dropdownMenuButton4"
                                    data-toggle="dropdown"
                                  >
                                    <i className="fa fa-cog"></i>
                                  </span>

                                  <div
                                    className="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenuButton4"
                                  >
                                    <Link
                                      to={`/employee_manage/${val.id}`}
                                      className="dropdown-item"
                                    >
                                      <i
                                        className="ri-pencil-fill mr-2"
                                        aria-hidden="true"
                                      ></i>
                                      Personal
                                    </Link>
                                    <Link
                                      to={`/employee_manage_info/${val.id}`}
                                      className="dropdown-item"
                                    >
                                      <i
                                        className="ri-pencil-fill mr-2"
                                        aria-hidden="true"
                                      ></i>
                                      Other Details
                                    </Link>
                                    {/* <Link
                                      to={`/employee_manage_bank/${val.id}`}
                                      className="dropdown-item"
                                    >
                                      <i
                                        className="ri-pencil-fill mr-2"
                                        aria-hidden="true"
                                      ></i>
                                      Financial
                                    </Link> */}
                                    <Link
                                      to={`/employee_manage_employement/${val.id}`}
                                      className="dropdown-item"
                                    >
                                      <i
                                        className="ri-pencil-fill mr-2"
                                        aria-hidden="true"
                                      ></i>
                                      Employment Details
                                    </Link>
                                    <Link
                                      to={`/PreviousEmployment/${val.id}`}
                                      className="dropdown-item"
                                    >
                                      <i
                                        className="ri-pencil-fill mr-2"
                                        aria-hidden="true"
                                      ></i>
                                      Previous Employment
                                    </Link>
                                    <Link
                                      to={`/employee_manage_reimbursement/${val.id}`}
                                      className="dropdown-item"
                                    >
                                      <i
                                        className="ri-pencil-fill mr-2"
                                        aria-hidden="true"
                                      ></i>
                                      Reimbursement Info
                                    </Link>
                                    {/* <Link
                                      to={`/employee_manage_sal/${val.id}`}
                                      className="dropdown-item"
                                    >
                                      <i
                                        className="ri-pencil-fill mr-2"
                                        aria-hidden="true"
                                      ></i>
                                      Salary (Monthly Wages)
                                    </Link> */}
                                    <Link
                                      to={`/employee_manage_qualification/${val.id}`}
                                      className="dropdown-item"
                                    >
                                      <i
                                        className="ri-pencil-fill mr-2"
                                        aria-hidden="true"
                                      ></i>
                                      Educational Qualification
                                    </Link>
                                    <Link
                                      to={`/employee_manage_doc/${val.id}`}
                                      className="dropdown-item"
                                    >
                                      <i
                                        className="ri-pencil-fill mr-2"
                                        aria-hidden="true"
                                      ></i>
                                      Previous Docs
                                    </Link>
                                  </div>
                                </div>
                                {
                                   user_typ === '3' 
                                   ? (
                                    <button
                                    onClick={() => {
                                      activeEmployee(val.id,val.is_active);
                                    }}
                                    className={val.is_active == 1 ? 'btn btn-danger mr-2 m-1' : 'btn btn-success mr-2 m-1'}
                                    style={{float:'right'}}
                                  >
                                    {val.is_active == 1 ? 'Inactive' : 'Active'}
                                  
                                  </button>
                                   ) 
                                   : (
                                    val.is_active == 1 ?
                                    ( <button
                                      onClick={() => {
                                        activeEmployee(val.id,val.is_active);
                                      }}
                                      className='btn btn-danger mr-2 m-1'
                                    >
                                      Inactive
                                    </button>)
                                    : null
                                   
                                   )
                                }
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                  <div>
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={50}
                      totalItemsCount={empCount}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Employee_manage_list;
