import Axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import Apiconnect from '../../services/Apiconnect';
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import FullScreenLoading from 'react-fullscreen-loading';
import { useAlert } from "react-alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import moment from 'moment'


const Manage_leave_category_wise = () => {
    
    const localuser = JSON.parse(localStorage.getItem("user"));
    let employee_id = Apiconnect.decrypt(localuser.id);
    const alert = useAlert(); 
    
    const [fullScreenLoader, setFullScreenLoader] = useState(false);
    const [lppkid, setLppkid] = useState(0)
    var date = new Date();
    const [from_date, setFromDate] = useState(null);
    const [sel_policy_category_id, setsel_policy_category_id] = useState(null);


    const [inputs, setInputs] = useState({cat: "", type: "", count: "", desc: ""})
    const {cat, type, count, desc} = inputs

    const onInputChange = (e) => {
        if(e.target.name=='cat'){
            const idx = e.target.selectedIndex;
            const option = e.target.querySelectorAll('option')[idx];
            const policy_cat_id = option.getAttribute('data-attr-policy_cat_id');      
            setsel_policy_category_id(policy_cat_id);
            // console.log(sel_policy_category_id);
        }

        setInputs({...inputs, [e.target.name]:e.target.value})
    }

    let company_id = Apiconnect.get_company_id()

     const [empList, setEmpList] = useState([])

    const getEmployeeList = () => {
        setFullScreenLoader(true)
        Apiconnect.getData(`employee/getall/${Apiconnect.get_company_id()}`).then((response) => {
           let _xtract = Apiconnect.decrypt_obj(response.data.data);
           setEmpList(_xtract.reverse());
           setFullScreenLoader(false)
        });
      };
      useEffect(() => {getEmployeeList()}, [])
    

    const getPolicyId = () => {
        setFullScreenLoader(true)
        let data = {employee_id:employee_id, company_id:company_id}
        Apiconnect.postData(`leave_manage/get_policy_employee_details_by_id`, data).then(res => {
            const _xtract = Apiconnect.decrypt_obj(res.data.data)
            setLppkid(_xtract[0].leavepolicy_pk_id)
            setFullScreenLoader(false)
        }).catch(err => console.log(err.message))
    }

    useEffect(() => {
        getPolicyId()
    }, [])
    
const [emp, setEmp] = useState('')
const [lpcategories, setLpcategories] = useState([])    
const getPolicyCatList = (id) => {
        setFullScreenLoader(true)    
        let data = {company_id:company_id, employee_id:id}
        Apiconnect.postData(`leave_manage/get_employee_policy_category_list`, data).then(res => {
            const _xtract = (typeof res.data.data!='undefined')?Apiconnect.decrypt_obj(res.data.data):[''];
            setLpcategories(_xtract);
            console.log('lpcategories',_xtract);
            setFullScreenLoader(false);
        })
    }
    const handleCreate =async (e) => {
        e.preventDefault()
        setFullScreenLoader(true)
        if (from_date != null) {
            var from = format(from_date, 'MM-yyyy');
        }
        const data = {company_id:company_id, emp_id:emp, leave_typ:cat, empleave_category_id:sel_policy_category_id, typ:type, leave_count:count, reason:desc, applied_on: from}
        // console.log(data);
        if(!data.emp_id){
            alert.error("Please Select Employee")
            setFullScreenLoader(false)
        }else if(!data.empleave_category_id){
            alert.error("Please Select Leave Category")
            setFullScreenLoader(false)
        }else if(!data.typ){
            alert.error("Please Select Leave Type")
            setFullScreenLoader(false)
        }else if(!data.leave_count){
            alert.error("Please Enter Leave Count")
            setFullScreenLoader(false)
        }else if(!data.reason){
            alert.error("Please Enter Leave Reason")
            setFullScreenLoader(false)
        }else if(!data.applied_on){
            alert.error("Please Select Month")
            setFullScreenLoader(false)
        }else{
            await Apiconnect.postData(`empLeaveCredit/create/${employee_id}`, data).then(res => {
                getManageLeaveCategoryWiseList()
                setFullScreenLoader(false)
                alert.success("Created Successfully")
            });
        }

         
    }


    const [list, setList] = useState([])
   const getManageLeaveCategoryWiseList =async () => {
    setFullScreenLoader(true)
    await Apiconnect.postData(`empLeaveCredit/getall/${company_id}`).then(res => {
        setList(res.data.data)
        setFullScreenLoader(false)
    }); 
   }
   useEffect(() => {getManageLeaveCategoryWiseList()}, [])

   const handleDelete =async (id) => {
        setFullScreenLoader(true)
        await Apiconnect.postData(`empLeaveCredit/delete/${id}`).then(res => {
            getManageLeaveCategoryWiseList()
            setFullScreenLoader(false)
            alert.success("Deleted Successfully")
        }); 
   }

   const [creditlist, setCreditList] = useState([])
   const getLeaveCredit =async () => {
    setFullScreenLoader(true)
    
    await Apiconnect.postData(`empLeaveCredit/getleavecreditlist/${company_id}`).then(res => {
        setCreditList(res.data.data)
        setFullScreenLoader(false)
    }); 
   }
   useEffect(() => {getLeaveCredit()},[])
   
   let creditlistnew = creditlist.map((item) => ({
    id: item.id,
    company_id: item.company_id,
    emp_id:item.emp_id,
    is_carryforward:item.is_carryforward,
    typ:item.typ,
    leave_typ:item.leave_typ,
    credit_ym:item.credit_ym,
    reason:item.reason,
    leave_start_date:item.leave_start_date,
    leave_end_date:item.leave_end_date,
    leave_count:item.leave_count,
    lop:item.lop,
    approved_by:item.approved_by,
    approval_note:item.approval_note,
    status:item.status,
    created_on:item.created_on,
    updated_on:item.updated_on,
    day_typ:item.day_typ,
    is_halfday:item.is_halfday,
    empleave_category_id:item.empleave_category_id,
    empleave_cycle_setting:item.empleave_cycle_setting,
    applied_on:item.applied_on,
    credited_by:item.credited_by
   }))

   const local_user = JSON.parse(localStorage.getItem('user'));
   const [companyaddress, setCompanyData] = useState([])
   const getCompany = () => {   
    setFullScreenLoader(true)      
   Apiconnect.getData(`company/get/${Apiconnect.get_company_id()}`).then((response) => {
         let ern = Apiconnect.decrypt_obj(response.data.data);
         setCompanyData(ern[0].address_one)
         setFullScreenLoader(false)
      });
   };
   useEffect(() => {getCompany()}, [])

   function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  const saveAsExcel = () => {
    setFullScreenLoader(true)
    let header = ["id", "company_id", "emp_id", "is_carryforward", "typ", "leave_typ", "credit_ym", "reason", "leave_start_date", "leave_end_date", "leave_count", "lop", "approved_by", "approval_note", "status", "created_on", "updated_on", "day_typ", "is_halfday", "empleave_category_id", "empleave_cycle_setting", "applied_on", "credited_by"]
  
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(creditlistnew, header);
      const totalColumns = sheetData[0].length;
  
       sheet1.cell("A1").value(local_user.company_name);
       sheet1.cell("A2").value(companyaddress)
       sheet1.cell("A3").value("List of leaves");
       sheet1.cell("A5").value(sheetData);

      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      range.style("border", true);

    sheet1.range("A1:W1").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true);
    sheet1.range("A2:W2").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
    sheet1.range("A3:W3").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
    sheet1.range("A5:W5").style({horizontalAlignment: "center", verticalAlignment: "center", }).style("bold", true).style("fill", "BFBFBF");


      return workbook.outputAsync().then((res) => {
        saveAs(res, "LeaveCatwiseList.xlsx");
        setFullScreenLoader(false)
      });
    });
  }

  function getSheetData2(data, header) {
    var fields = Object.keys(data);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }

  const saveAsExcel1 =async () => {
   
    let list_category=[];
    const data_pst1= {company_id:company_id}
    await Apiconnect.postData(`leave_manage/get_all_leave_category_lists`,data_pst1).then(res => {
        // setLeaveCatCode(res.data.data)
        list_category=res.data.data;
    });

    // return false;

    setFullScreenLoader(true)
    let header = ["Employee Code","Employee Name", "Type"];
    let data = []
    
    // if(emp==''){
    //     alert.success("Please select employee...");
    //     return false;
    // }
    
    getPolicyCatList(emp); 
    if(list_category.length>0){
        var cnt_i2=3;
        for(var cnt_i1=0;cnt_i1<list_category.length;cnt_i1++){
            header[cnt_i2]=list_category[cnt_i1].leave_cat_code;
            cnt_i2++;
        }
    }//end_if

    // console.log(event);
    // return false; 
  
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData2(data, header);
      const totalColumns = sheetData[0].length;
  
      sheet1.cell("A1").value(sheetData);
      //sheet1.column("A").width(10);
      sheet1.column("B").width(25);
      sheet1.column("C").width(20);

      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);  
      range.style("border", true);
    
      sheet1.range("A2:E2").value([["Test05", "Demo1 Name", "Credit", "1", "2"]]);

      return workbook.outputAsync().then((res) => {
        saveAs(res, "empsample.xlsx");
        setFullScreenLoader(false)
      });
    });
  }



  const saveAsExcel2 = () => {
    let header = ["employee code", "reason", "leave_start_date", "leave_end_date", "leave_count", "approved_by emp code", "is_halfday", "category code"]
    let data = []
    setFullScreenLoader(true)
  
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData2(data, header);
      const totalColumns = sheetData[0].length;
  
      sheet1.cell("A1").value(sheetData);

      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      sheet1.range("A2:H2").value([["Test05", "Demo", "02-02-2023", "04-02-2023", "3", "Test06", "", "CL"]]);
      sheet1.range("A3:H3").value([["Test05", "Demo", "02-02-2023", "02-02-2023", "0.5", "Test06", "first half", "PL"]]);

      range.style("border", true);
      return workbook.outputAsync().then((res) => {
        saveAs(res, "leaveDebitSample.xlsx");
        setFullScreenLoader(false)
      });
    });
  }


  const [selectedfile, setSelectedfile] = useState('')
  
  const onSubmitCreditFile =async () => {
    const data = new FormData()
    data.append('leaveCreditExcel', selectedfile);  
    data.append('company_id', company_id);  
    data.append('sel_credited_by',employee_id);
    setFullScreenLoader(true)
    await Apiconnect.postData(`uplaod_management/manage_leave_category_wise_credit_new`, data).then(res => {
        setFullScreenLoader(false)
        alert.success("Successfully Submitted");
        // console.log(res);
    }); 
  }

  const onSubmitDabitFile =async () => {
    setFullScreenLoader(true)
    const data = new FormData()
    data.append('leaveDebitExcel', selectedfile);  
    await Apiconnect.postData(`uplaod_management/upload_leave_debit/${employee_id}/${company_id}`, data).then(res => {
        setFullScreenLoader(false)
        alert.success("Successfully Submitted")
    }); 
  }

  const [empleavelist, setEmpLeavelist] = useState([])
  const getEmpleavelist = () => {
    const data={company_id:company_id}
     Apiconnect.postData(`employee/get_employee_leave_list`, data).then((response) => {
        let _xtract = Apiconnect.decrypt_obj(response.data.data);
        setEmpLeavelist(_xtract); 
      });
  }
  useEffect(() => {getEmpleavelist()}, [])
  

  let empleaveListExcel = empleavelist.map((item) => ({
    id: item.id,
    emp_id:item.emp_id,
    company_id:item.company_id,
    approval_note:item.approval_note,
    approved_by:item.approved_by,
    created_on:item.created_on,
    credit_ym:item.credit_ym,
    day_typ:item.day_typ,
    empleave_category_id:item.empleave_category_id,
    is_carryforward:item.is_carryforward,
    is_halfday:item.is_halfday,
    leave_count:item.leave_count,
    leave_start_date:item.leave_start_date,
    leave_end_date:item.leave_end_date,
    leave_typ:item.leave_typ,
    lop:item.lop,
    reason:item.reason,
    status:item.status,
    typ:item.typ,
    updated_on:item.updated_on
  }))

  const saveAsExcel3 = () => {
    setFullScreenLoader(true)
    let header = ["id","emp_id","company_id","approval_note", "approved_by", "created_on", "credit_ym", "day_typ", "empleave_category_id", "is_carryforward", "is_halfday", "leave_count", "leave_start_date", "leave_end_date", "leave_typ", "lop", "reason", "status", "typ", "updated_on"]
  
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(empleaveListExcel, header);
      const totalColumns = sheetData[0].length;
  
       sheet1.cell("A1").value(local_user.company_name);
       sheet1.cell("A2").value(companyaddress)
       sheet1.cell("A3").value("List of leaves");
       sheet1.cell("A5").value(sheetData);

      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      range.style("border", true);

    sheet1.range("A1:T1").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true);
    sheet1.range("A2:T2").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
    sheet1.range("A3:T3").style({horizontalAlignment: "center", verticalAlignment: "center", }).merged(true).style("bold", true);
    sheet1.range("A5:T5").style({horizontalAlignment: "center", verticalAlignment: "center", }).style("bold", true).style("fill", "BFBFBF");


      return workbook.outputAsync().then((res) => {
        saveAs(res, "LeaveCatwiseList.xlsx");
        setFullScreenLoader(false)
      });
    });
  }


  const [debitlist, setDebitList] = useState([])
  const getdebitlist =async () => {
    
    await Apiconnect.postData(`empLeaveDebit/getall/${company_id}`).then(res => {
        setDebitList(res.data.data)
    }); 
  }
  useEffect(() => {getdebitlist()}, [])


  const handleDeleteempdebit = async(id) => { 
    await Apiconnect.postData(`empLeaveDebit/delete/${id}`).then(res => {
        // setDebitList(res.data.data)
        getdebitlist();
    }); 
  }


  const onUserChange = (e,value) => {
    let selemp_id=(value!=null)?value.id:0;
    setEmp(selemp_id);
    getPolicyCatList(selemp_id);
    
    
    // setEmployeeId(value.id);
    // if(value.id != ''){
    //     setFullScreen(true)
    //     getItem(value.id);
    // }else{
    //     setView(false) 
    // }
  }


  const monthNames = {
    1:"January", 2:"February",3:"March", 4:"April", 5:"May",
    6:"June", 7:"July", 8:"August", 9:"September", 10:"October", 
    11:"November", 12:"December"
  };
  
  return (
    <>
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                    {fullScreenLoader
                    ? (
                    <FullScreenLoading loading loaderColor="#3498db" />
                    )
                    
                    : null
                    }
                <div className="row">
                    <div className="col-sm-12 col-lg-12 col-md-12">
                        <div className="iq-card">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div style={{ float: "left" }} className="iq-header-title">
                                    <h4 className="card-title">Leave Balance Update</h4>
                                </div>
                            </div>
                            <div className='row mt-3 p-3 m-3 bbox'>
                                <div className='col-md-3 form-group'>
                                    <label>Employee<span className="required">*</span></label>
                                    {/* <select className="form-control" name='emp' value={emp} onChange={(e) => {
                                        setEmp(e.target.value)
                                        getPolicyCatList(e.target.value)
                                    }}>
                                        <option value="" disabled selected>Select Employee</option>
                                        {
                                            empList.map((item, index) => {
                                                return(
                                                    <option key={index} value={item.id}>{item.first_name} {item.last_name}</option>
                                                )
                                            })
                                        }
                                    </select> */}
                                    <Autocomplete
                                        options={empList}
                                        getOptionLabel={(option) => (option.first_name?option.first_name:'')+' '+(option.last_name?option.last_name:'')+' / '+(option.emp_code?option.emp_code.slice(-4):'')+' / '+(option.email?option.email:'')+' / '+(option.phone?option.phone:'')}
                                        // style={{ width: 600 }}
                                        renderInput={params => (
                                            <TextField
                                            {...params}
                                            placeholder='Select Employees'
                                            label="Employees"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            />
                                        )}
                                        onChange={onUserChange}
                                    />
                                </div>
                                
                                <div className='col-md-3 form-group'>
                                    <label>Leave Category<span className="required">*</span></label>
                                    <select className="form-control" name='cat' value={cat} onChange={(e) =>onInputChange(e)}>
                                        <option value="" selected>Select Leave Category</option>
                                        {
                                            lpcategories.map((item, index) => (
                                                <option key={index} data-attr-policy_cat_id={item.lpcategory_pk_id} value={item.leave_cat_id}>{item.leave_cat_code}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                <div className='col-md-3 form-group'>
                                    <label>Type<span className="required">*</span></label>
                                    <select className="form-control" name='type' value={type} onChange={(e) => onInputChange(e)}>
                                        <option value="" disabled selected>Select Type</option>
                                        <option value="credit" >Credit</option>
                                        <option value="Carry Forward" >CarryForward</option>
                                    </select>
                                </div>
                                <div className='col-md-3 form-group'>
                                    <label>Leave Count<span className="required">*</span></label>
                                    <input className='form-control'  placeholder='Leave Count' autoComplete="off" type='text' name='count' value={count} onChange={(e) => onInputChange(e)}/>
                                </div>
                                <div className='col-md-6 form-group'>
                                    <label>Date<span className="required">*</span></label>
                                        <DatePicker
                                                selected={from_date}
                                                onChange={e => setFromDate(e)}
                                                className="form-control"
                                                placeholderText="From Date"
                                                name="from_date"
                                                dateFormat="MM/yyyy"
                                                showMonthYearPicker
                                                showFullMonthYearPicker
                                                showFourColumnMonthYearPicker
                                                autoComplete="off"
                                            />
                                </div>
                                <div className='col-md-6 form-group'>
                                    <label>Description<span className="required">*</span></label>
                                    <textarea rows="2" className='form-control' placeholder='Describe . . . .' type='text' name='desc' value={desc} onChange={(e) => onInputChange(e)}/>
                                </div>
                                <div className='col-md-6 form-group'>
                                    <button className="btn badge badge-pill badge-success" onClick={e => handleCreate(e)}>add</button>
                                </div>
                            </div>
                            <div className='row m-3 mt-4'>
                                <button className="btn badge badge-pill btn-warning float-right" onClick={() => saveAsExcel1()}>Download Sample Excel - Credit</button>
                            </div>
                            <div className='row m-3 border bbox rounded'>
                                <div className='col-md-8'>
                                    <input type="file" id="file" className="form-control" name="leaveCreditExcel" onChange={(e) => setSelectedfile(e.target.files[0])}/>
                                </div>
                                <div className='col-md-2'>
                                    <button className="btn badge badge-pill btn-info" onClick={() => onSubmitCreditFile()}>Upload Bulk Excel - Credit</button>
                                </div>
                            </div>
                            <div className='row m-3 mt-4'>
                                <button className="btn badge badge-pill btn-warning float-right" onClick={() => saveAsExcel2()}>Download Sample Excel - Debit</button>
                            </div>
                            <div className='row m-3 border bbox rounded'>
                                <div className='col-md-8'>
                                    <input type="file" id="file" className="form-control" name="leaveDebitExcel" onChange={(e) => setSelectedfile(e.target.files[0])}/>
                                </div>
                                <div className='col-md-2'>
                                    <button className="btn badge badge-pill btn-info" onClick={() => onSubmitDabitFile()}>Upload Bulk Excel - Debit</button>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div className='row ml-2'>
                                    <h5 className='border-bottom'>Leave Category Wise - Credit</h5>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>    
                                    </div>
                                    <div className='col-md-6'>
                                        <button className='btn badge badge-pill btn-warning float-right' type='button' onClick={() => saveAsExcel()}>Download Excel List - Credit</button>
                                    </div>
                                </div>
                            <table  className="table table-striped table-bordered mt-1 center" role="grid" >
                                <thead className="">
                                <tr className="clr">
                                    <th scope="col">#</th>
                                    <th scope="col">Employee Name</th>
                                    <th scope="col">Policy Category</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Leave Count</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.map((val, key) => {
                                            let credit_ym = new Date(val.credit_ym);
                                            // let year = (d.getFullYear())
                                            // let month = (d.getMonth())
                                            // let day = (d.getDay())
                                            let cal_year= (credit_ym.getFullYear());
                                            let cal_month= (credit_ym.getMonth()+1);

                                            // console.log(credit_ym);
                                            return(
                                                <tr key={key}>
                                                    <th scope="row">{key + 1}</th>
                                                    <td>{val.first_name} {val.last_name}</td>
                                                    <td>{val.lpcategory_name}</td>
                                                    <td>{val.typ}</td>
                                                    <td>{val.leave_count}</td>
                                                    <td>{monthNames[cal_month]+'-'+cal_year} </td>
                                                    <td>
                                                        <button className="btn badge badge-pill badge-danger" onClick={() => handleDelete(val.id)}>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                            <div className="iq-card-body">
                                <div className='row ml-2'>
                                    <h5 className='border-bottom'>Leave Category Wise - Debit</h5>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>    
                                    </div>
                                    <div className='col-md-6'>
                                        <button className='btn badge badge-pill btn-warning float-right' type='button' onClick={() => saveAsExcel3()}>Download Excel List - Debit</button>
                                    </div>
                                </div>
                            <table  className="table table-striped table-bordered mt-1 center" role="grid" >
                                <thead className="">
                                <tr className="clr">
                                    <th scope="col">#</th>
                                    <th scope="col">Employee Name</th>
                                    <th scope="col">Leave Start</th>
                                    <th scope="col">Policy End</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Leave Count</th>
                                    <th scope="col">Reason</th>
                                    <th scope="col">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        debitlist.map((val, key) => {
                                            let from=(val.leave_start_date).slice(0, 9)
                                            let to = (val.leave_end_date).slice(0, 9)
                                            
                                            return(
                                                <tr key={key}>
                                                    <th scope="row">{key + 1}</th>
                                                    <td>{val.first_name} {val.last_name}</td>
                                                    <td>{from}</td>
                                                    <td>{to}</td>
                                                    <td>{val.typ}</td>
                                                    <td>{val.leave_count}</td>
                                                    <td>{val.reason}</td>
                                                    <td>
                                                        <button className="btn badge badge-pill badge-danger" onClick={() => handleDeleteempdebit(val.id)}>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Manage_leave_category_wise