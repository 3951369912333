import React, { useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Apiconnect from "../../services/Apiconnect.js";
import { useAlert } from "react-alert";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import './datepicker.css'
import Modal from 'react-modal';


const PreviousEmployment = (props) => {
    console.log(props)

    const [modalIsOpen, setModalIsOpen] = useState(false);


    const localuser = JSON.parse(localStorage.getItem('user'));
    let employee_id = Apiconnect.decrypt(localuser.id); 
    let user_typ = Apiconnect.decrypt(localuser.emp_typ);

    // let emp_id = Apiconnect.decrypt(props.match.params.id);
    let emp_id = props.match.params.id;

    const alert = useAlert();
    let history = useHistory();
    const [btn_data, setBtnId] = useState([]);
    const { btn_id } = btn_data;


    const [info2, setInfo2] = useState({
        employee_mst_id: emp_id, notice_period: '',id:''
    });
    const { notice_period,id } = info2;


    const onInputChange2 = e => {
        setInfo2({ ...info2, [e.target.name]: e.target.value });
    };

    // console.log(info2);

    const onSubmit = async e => {
        e.preventDefault();


        if (btn_id > 0) {

            console.log('in edit function.....');
            info2.employee_mst_id = emp_id;
            info2.id = id;


            if (confirmationDate === null) {
                info2.confirmation_date = confirmationDate;
            } else {
                info2.confirmation_date = format(confirmationDate, 'yyyy-MM-dd');
            }

            if (joiningDate === null) {
                info2.joining_date = joiningDate;
            } else {
                info2.joining_date = format(joiningDate, 'yyyy-MM-dd');
            }

            if (resignDate === null) {
                info2.resign_date = resignDate;
            } else {
                info2.resign_date = format(resignDate, 'yyyy-MM-dd');
            }

            if (leavingDate === null) {
                info2.leaving_date = leavingDate;
            } else {
                info2.leaving_date = format(leavingDate, 'yyyy-MM-dd');
            }

            if (promotionDate === null) {
                info2.promotion_date = promotionDate;
            } else {
                info2.promotion_date = format(promotionDate, 'yyyy-MM-dd');
            }

            if (transferDate === null) {
                info2.transfer_date = transferDate;
            } else {
                info2.transfer_date = format(transferDate, 'yyyy-MM-dd');
            }

            let info_enc2 = Apiconnect.encrypt_obj(info2);
            const inf_a2 = { enc: info_enc2,employee_id:employee_id };

            Apiconnect.postData("employee_info/update", inf_a2).then((response) => {
                setInfo2({
                    employee_mst_id: '', notice_period: '',id:''
                });

                alert.success(response.data.message);
                history.push(`/employee_manage_employement/${emp_id}`);
            });
        } else {

            console.log('in crate function.....');
            info2.employee_mst_id = emp_id;

            if (confirmationDate === null) {
                info2.confirmation_date = confirmationDate;
            } else {
                info2.confirmation_date = format(confirmationDate, 'yyyy-MM-dd');
            }

            if (joiningDate === null) {
                info2.joining_date = joiningDate;
            } else {
                info2.joining_date = format(joiningDate, 'yyyy-MM-dd');
            }

            if (resignDate === null) {
                info2.resign_date = resignDate;
            } else {
                info2.resign_date = format(resignDate, 'yyyy-MM-dd');
            }

            if (leavingDate === null) {
                info2.leaving_date = leavingDate;
            } else {
                info2.leaving_date = format(leavingDate, 'yyyy-MM-dd');
            }

            if (promotionDate === null) {
                info2.promotion_date = promotionDate;
            } else {
                info2.promotion_date = format(promotionDate, 'yyyy-MM-dd');
            }

            if (transferDate === null) {
                info2.transfer_date = transferDate;
            } else {
                info2.transfer_date = format(transferDate, 'yyyy-MM-dd');
            }

            let info_enc2 = Apiconnect.encrypt_obj(info2);
            const inf_a2 = { enc: info_enc2,employee_id:employee_id };

            Apiconnect.postData("employee_info/create", inf_a2).then((response) => {
                setInfo2({
                    employee_mst_id: '', notice_period: '',id:''
                });

                alert.success(response.data.message);
                history.push(`/employee_manage_sal/${emp_id}`);
            });

        }



    };

    const [confirmationDate, setConfirmationDate] = useState(null);
    const [joiningDate, setJoiningDate] = useState(null);
    const [resignDate, setResignDate] = useState(null);
    const [leavingDate, setLeavingDate] = useState(null);
    const [promotionDate, setPromotionDate] = useState(null);
    const [transferDate, setTransferDate] = useState(null);
    const [resignDateLock, setResignDateLock] = useState(false);
    const [leavingDateLock, setLeavingDateLock] = useState(false);
    


    useEffect(() => { getItem(); }, []);
    const getItem = () => {
        if (emp_id > 0) {
            Apiconnect.getData(`employee_info/get/${emp_id}`).then((response) => {
                let _xtract = Apiconnect.decrypt_obj(response.data.data);

               
                if (_xtract.length > 0) {

                    setInfo2({ notice_period: _xtract[0].notice_period,id: _xtract[0].id });

                    setBtnId({ btn_id: 1 });

                    setConfirmationDate(null);
                    if (_xtract[0].confirmation_date !== null && _xtract[0].confirmation_date !== '0000-00-00') {
                        setConfirmationDate(new Date(_xtract[0].confirmation_date));
                    }
                    setJoiningDate(null);
                    if (_xtract[0].joining_date !== null && _xtract[0].joining_date !== '0000-00-00') {
                        setJoiningDate(new Date(_xtract[0].joining_date));
                    }
                    setResignDate(null);
                    if (_xtract[0].resign_date !== null && _xtract[0].resign_date !== '0000-00-00') {
                        setResignDate(new Date(_xtract[0].resign_date));
                        setResignDateLock(true)
                    }
                    setLeavingDate(null);
                    if (_xtract[0].leaving_date !== null && _xtract[0].leaving_date !== '0000-00-00') {
                        setLeavingDate(new Date(_xtract[0].leaving_date));
                        setLeavingDateLock(true)
                    }
                    setPromotionDate(null);
                    if (_xtract[0].promotion_date !== null && _xtract[0].promotion_date !== '0000-00-00') {
                        setPromotionDate(new Date(_xtract[0].promotion_date));
                    }
                    setTransferDate(null);
                    if (_xtract[0].transfer_date !== null && _xtract[0].transfer_date !== '0000-00-00') {
                        setTransferDate(new Date(_xtract[0].transfer_date));
                    }
                } else {
                    setBtnId({ btn_id: null });
                }
            });
        }
    };

    //  if(confirmationDate !== null){
    //      var promo = confirmationDate;
    //      promo = new Date(promo.setMonth(promo.getMonth() + 3));

    //  }


    //   function refreshPage(){ 
    //     window.location.reload(); 
    //  }

    function nextPage() {
        history.push(`/employee_manage_reimbursement/${emp_id}`);
    }
    function previousPage() {
        history.push(`/employee_manage_employement/${emp_id}`);
    }


    //Modal custom styles
    const customStyles = {
        content: {
           marginTop: "50px",
           top: '50%', width: '60%',
           left: '50%',
           right: 'auto',
           bottom: 'auto',
           marginRight: '-50%',
           transform: 'translate(-50%, -50%)',
           height:'60%',
        },
     };

     
     /* ----- get data------ */
    const [data, setData] = useState([])
    
    const getAllData = async () => {
        const inf_b = {company_id: Apiconnect.get_company_id(), employee_id: empId}
        await Apiconnect.postData(`leave_manage/employee_pre_emp_list`, inf_b).then(res => {
            let _xtract = Apiconnect.decrypt_obj(res.data.data);
            setData(_xtract)
        })
    }
    useEffect(() => {getAllData()}, [])
    console.log(data)

/* Previous employee details */

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [editId, setEditId] = useState(0)
    let empId = useParams().id
    /* ----------- */
    const [info, setInfo] = useState({
        company_id: Apiconnect.get_company_id(),
        employee_id: empId,
        organisaction_name: "",
        emp_designation: "",
        salary_drawn: "",
        from_date:format(new Date(), 'yyyy-MM-dd'),
        to_date:format(new Date(), 'yyyy-MM-dd'),
    })
    const {organisaction_name, emp_designation, salary_drawn, from_date, to_date} = info

    const handleChange = (e) => {
        setInfo({...info, [e.target.name]:e.target.value})
    }

    const handleClick = (e) => {    
        e.preventDefault()
        info.from_date = format(startDate, 'yyyy-MM-dd');
        info.to_date = format(endDate, 'yyyy-MM-dd');
        const inf_a = {
            company_id: Apiconnect.get_company_id(),
            employee_id: empId,
            organisaction_name: info.organisaction_name,
            emp_designation: info.emp_designation,
            salary_drawn: info.salary_drawn,
            from_date: info.from_date,
            to_date: info.to_date
        }
        Apiconnect.postData("leave_manage/employee_pre_emp_create", inf_a).then(res => {
            getAllData()
            alert.success("data added")
        })
    }

    const handleDelete = async (id) => {
        await Apiconnect.postData(`leave_manage/employee_pre_emp_delete`, {empprev_pk_id: id}).then(res => 
            {
                getAllData()
                alert.success("Previous employement has deleted")
            })
    }

    const applyTemplate = () => { 
        setModalIsOpen(true)
     }


    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                <div>
                    
            </div>
                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h4 className="card-title">Previous Employment <span style={{ fontSize: '15px', color: 'blue' }}>Stage 4 to 6</span></h4>
                            </div>
                        </div>
                        <div className="iq-card-body">
                            <div className="bs-example">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <Link className="nav-link " data-toggle="tab" to={`/employee_manage/${emp_id}`} >Personal</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link " data-toggle="tab" to={`/employee_manage_info/${emp_id}`} >Other Details</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" data-toggle="tab" to={`/employee_manage_employement/${emp_id}`} >Employement Details</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link active" data-toggle="tab" to={`/PreviousEmployment/${emp_id}`} >Previous Employment</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" data-toggle="tab" to={`/employee_manage_reimbursement/${emp_id}`} >Reimbursement Info</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link " data-toggle="tab" to={`/employee_manage_qualification/${emp_id}`} >Educational Qualification</Link>
                                    </li>
                                </ul>
                            </div>
                            <form onSubmit={e => onSubmit(e)}>
                              <div className="row">
                                  <div className='bbox m-3 p-3' style={{width: '100%'}}>
                                      <div className="row" style={{justifyContent: "space-between"}}>
                                          <div className="col-md-12">
                                              <div className="form-group">
                                                  <label>Name of the Company/ School/ College</label>
                                                  <input type='text' className="form-control" name='organisaction_name' value={organisaction_name} onChange={handleChange} />
                                              </div>
                                          </div>
                                          <div className="col-md-3">
                                              <div className="form-group">
                                                  <label>Period of Service From</label>
                                                      <DatePicker
                                                          selected={startDate}
                                                          onChange={e => setStartDate(e)}
                                                          placeholderText="from date" 
                                                          name="from_date"
                                                          className="form-control"
                                                          dateFormat='yyyy-MM-dd'
                                                          required=""
                                                          showMonthDropdown
                                                          showYearDropdown
                                                          scrollableYearDropdown
                                                          scrollableMonthDropdown
                                                          yearDropdownItemNumber={100}
                                                          dropdownMode="select"
                                                          />
                                              </div>
                                          </div>
                                          <div className="col-md-3">
                                              <div className="form-group">
                                                  <label>Period of Service To</label>
                                                      <DatePicker
                                                          selected={endDate}
                                                          onChange={e => setEndDate(e)}
                                                          placeholderText="To Date" 
                                                          minDate={startDate}
                                                          name="to_date"
                                                          className="form-control"
                                                          dateFormat='yyyy-MM-dd'
                                                          required=""
                                                          showMonthDropdown
                                                          showYearDropdown
                                                          scrollableYearDropdown
                                                          scrollableMonthDropdown
                                                          yearDropdownItemNumber={100}
                                                          dropdownMode="select"
                                                          />
                                              </div>
                                          </div>
                                          <div className="col-md-3">
                                              <div className="form-group">
                                                  <label>Designation</label>
                                                  <input type='text' className="form-control" name='emp_designation' value={emp_designation} onChange={handleChange}/>
                                              </div>
                                          </div>
                                          <div className="col-md-3">
                                              <div className="form-group">
                                                  <label>Salary Drawn</label>
                                                  <input type='text' className="form-control" name='salary_drawn' value={salary_drawn} onChange={handleChange}/>
                                              </div>
                                          </div>
                                          <div className='col-md-12'>
                                            <button type="button"  className="btn btn-success" onClick={handleClick}>{editId ? "Update" : "Add"}</button>
                                          </div>
                                      </div>    
                                  </div>
                              </div>
                                        <div className="iq-card-body">
                                            <table className="table table-striped table-hover">
                                                <thead className="">
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Organisation</th>
                                                        <th scope="col">From</th>
                                                        <th scope="col">To</th>
                                                        <th scope="col">Designation</th>
                                                        <th scope="col">Salary Drawn</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data.map((value, key) => {
                                                            let s = value.empprev_date_from.slice(0, 10)
                                                            let e = value.empprev_date_to.slice(0, 10)

                                                            return(
                                                            <tr>
                                                                <th>{key+1}</th>
                                                                <th>{value.empprev_organisaction_name}</th>
                                                                <th>{s}</th>
                                                                <th>{e}</th>
                                                                <th>{value.empprev_designation}</th>
                                                                <th>{value.empprev_status}</th>
                                                                <th>
                                                                    <button type="button" className="btn btn-danger mr-2" onClick={() => {handleDelete(value.empprev_pk_id)}}>Delete</button>
                                                                </th>
                                                            </tr>
                                                        )})
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <button type="submit" name="id" value={btn_id} className="btn btn-primary mr-2"> {btn_id ? 'Update' : 'Submit'} </button>
                                        {/* <button type="button" className="btn iq-bg-danger" onClick={ refreshPage }> cancle </button>  */}
                                        <button type="button" className="btn btn-info mr-2" onClick={previousPage}> Previous </button>
                                        <button type="button" className="btn btn-success mr-2" onClick={nextPage}> Next </button>
                                        <Link style={{ float: 'right' }} className="btn btn-secondary" to="/employee_manage_list" >Back to List</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )



}
export default PreviousEmployment