import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { useAlert } from "react-alert";
import Apiconnect from "../../services/Apiconnect.js";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import Modal from "react-modal";
import "../pages/pages.css";
import "../pages/admin.css";
import Pagination from "react-js-pagination";
import Loadder from "./Loadder";
import { color } from "@mui/system";
import { colors } from "@material-ui/core";
import { useLocation } from "react-router-dom";


const Leave_policy_manages_cat = (props) => {
  // const { selcat_id } = useParams();
  const location = useLocation();
  const selcat_id = location.pathname.split("/")[2];

  const alert = useAlert();
  let history = useHistory();
  const recordsPerPage = 100;

  const localuser = JSON.parse(localStorage.getItem("user"));
  let user_id = Apiconnect.decrypt(localuser.id);
  let user_typ = Apiconnect.decrypt(localuser.emp_typ);
  const AuthStr = localuser.accessToken;
  useEffect(() => {
    getLeavePolicyCategoryList(recordsPerPage, "0", "");
  }, []);
  const [loader, setLoader] = useState(false);

  const customStyles = {
    content: {
      top: "15%",
      width: "75%",
      left: "21%",
      //    right: 'auto',
      //    bottom: 'auto',
      //    marginRight: '-50%',
      //    transform: 'translate(-37%, -50%)',
      //    backgroundColor:"#E0E0E0",
      //    overflow:"scroll !important",
      //    height: "80%",
    },
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const default_data = {
    selcat_id: selcat_id,
    company_id: Apiconnect.get_company_id(),
    leavecategory_name1: "",
    leavecategory_cyclesett: 0,
    leavecategory_maxcredit1: 0,
    leavecategory_creditspercycle:0,
    leavecategory_prorated: "Yes",
    leavecategory_minimum_service: 0,
    leavecategory_applicable_eligibility: 0,
    leavecategory_exception_settings: "Yes",
    leavecategory_cedit_settings: 0,
    leavecategory_lapse_setting: "Lapse Setting",
    leavecategory_carry_forward: 0,
    leavecategory_minworking_days:0,
    leavecategory_app_gender: 'Both',
    // leavecategory_count_minmax:'',
    leavecategory_app_settings:"No",
    leavecategory_ocassions_setting: 0,
    leavecategory_max_accumulation: 0,
    leavecategory_leave_emp_app: 0,
    sel_id_edit: "",
    leavecategory_lcf_setting: 0,
    leavecategory_setting_val: 0,
    leavecategory_ocassions_setting_min: 0,
    leavecategory_ocassions_setting_max: 0,
    leavecategory_count_min: 0,
    leavecategory_count_max: 0,
    lpcategory_category_id: 0,
    leavecategory_withoutcredit:'No'
  };
  const [data_lpcate, setUser] = useState(default_data);
  const {
    leavecategory_count_min=0,
    leavecategory_count_max=0,
    leavecategory_ocassions_setting_min=0,
    leavecategory_ocassions_setting_max=0,
    leavecategory_setting_val=0,
    leavecategory_lcf_setting=0,
    op_selcset1,
    op_selcset2,
    leavecategory_creditspercycle=0,
    // sel_id_edit,
    leavecategory_leave_emp_app,
    leavecategory_max_accumulation=0,
    leavecategory_ocassions_setting,
    leavecategory_app_settings='No',
    leavecategory_app_gender='Both',
    leavecategory_minworking_days=0,
    leavecategory_carry_forward,
    leavecategory_lapse_setting = "Lapse Setting",
    leavecategory_cedit_settings,
    leavecategory_exception_settings = "Yes",
    leavecategory_applicable_eligibility=0,
    leavecategory_minimum_service=0,
    leavecategory_prorated='Yes',
    leavecategory_name1,
    leavecategory_cyclesett=0,
    leavecategory_maxcredit1=0,
    lpcategory_category_id=0,
    leavecategory_withoutcredit="No",
  } = data_lpcate;

  const onInputChange = (e) => {
    setUser({
      ...data_lpcate,
      [e.target.name]: e.target.value,
      company_id: Apiconnect.get_company_id(),
      selcat_id: selcat_id,
    });
  };

  const onInputChange_cate = (e) => {
    const idx = e.target.selectedIndex;
    const option = e.target.querySelectorAll('option')[idx];
    const policy_cat_code = option.getAttribute('attr-leave_cat_code');

    setUser({
      ...data_lpcate,
      [e.target.name]: e.target.value,
      leavecategory_name1:policy_cat_code,
      company_id: Apiconnect.get_company_id(),
      selcat_id: selcat_id,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    if (typeof data_lpcate.leavecategory_exception_settings == "undefined") {
      data_lpcate.leavecategory_exception_settings = "Yes";
    }
    if (typeof data_lpcate.leavecategory_lapse_setting == "undefined") {
      data_lpcate.leavecategory_lapse_setting = "Lapse Setting";
    }

    // console.log(data_lpcate);
    // return false;
    
    if (data_lpcate.sel_id_edit == "") {
      Apiconnect.postData(
        `leave_manage/leave_policy_category_create`,
        data_lpcate
      ).then((response) => {
        alert.success(response.data.message);
      });
    } else {
      // console.log(data_lpcate);
      Apiconnect.postData(
        `leave_manage/leave_policy_category_update`,
        data_lpcate
      ).then((response) => {
        //console.log(response);
        alert.success(response.data.message);
      });
    }
    setLoader(false);
    setModalIsOpen(false);
    setUser(default_data);
    getLeavePolicyCategoryList(recordsPerPage, "0", "");
  };

  function popup_addedit1() {
    setUser(default_data);
    setModalIsOpen(true);
  }

  const [List_leavepolicycategory, setleavepolicycate] = useState([]);

  const getLeavePolicyCategoryList = (limit, offset, search) => {
    setLoader(true);
    const data = {
      limit: limit,
      offset: offset,
      search: search,
      company_id: Apiconnect.get_company_id(),
      category_policy_id: selcat_id,
    };
    Apiconnect.postData( `leave_manage/get_leave_policy_category_list`, data ).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data); 
      setleavepolicycate(_xtract.reverse());
      setLoader(false);
    });
  };

  function leavepolicycategory_edit(sellgc_id) {
    const data_post1 = { lpcategory_pk_id: sellgc_id };
    Apiconnect.postData( `leave_manage/get_leave_policy_category_details`, data_post1).then((response) => {
      let _xtract = Apiconnect.decrypt_obj(response.data.data);
      console.log(_xtract);
      if (_xtract.length > 0) {
        setUser({ 
          sel_id_edit: sellgc_id,
          lpcategory_category_id:_xtract[0].lpcategory_category_id,
          leavecategory_name1: _xtract[0].lpcategory_name,
          leavecategory_cyclesett: _xtract[0].lpcategory_cycle_setting,
          leavecategory_maxcredit1: _xtract[0].lpcategory_maxcredit,
          leavecategory_creditspercycle: _xtract[0].lpcategory_creditspercycle,
          leavecategory_prorated: _xtract[0].lpcategory_pro_rated,
          leavecategory_minimum_service:
            _xtract[0].lpcategory_minimumserviceyear,
          leavecategory_applicable_eligibility:
            _xtract[0].lpcategory_applicable_eligibility,
          leavecategory_exception_settings:
            _xtract[0].lpcategory_exception_setting,
          leavecategory_cedit_settings: _xtract[0].lpcategory_empcreditsetting,
          leavecategory_lapse_setting: _xtract[0].lpcategory_lapse_setting,
          leavecategory_carry_forward: _xtract[0].lpcategory_carry_forward,
          leavecategory_minworking_days: _xtract[0].lpcategory_min_working_day,
          leavecategory_app_gender: _xtract[0].lpcategory_gender,
          // leavecategory_count_minmax:_xtract[0].lpcategory_minmax_setting,
          leavecategory_app_settings: _xtract[0].lpcategory_app_setting,
          leavecategory_ocassions_setting:
            _xtract[0].lpcategory_ocassions_setting,
          leavecategory_max_accumulation:
            _xtract[0].lpcategory_max_accumulation,
          leavecategory_lcf_setting: _xtract[0].lpcategory_lcf_setting,
          leavecategory_setting_val: _xtract[0].lpcategory_setting_val,
          leavecategory_ocassions_setting_min:
            _xtract[0].lpcategory_ocassions_setting_min,
          leavecategory_ocassions_setting_max:
            _xtract[0].lpcategory_ocassions_setting_max,
          leavecategory_count_min: String(_xtract[0].leavecategory_count_min),
          leavecategory_count_max: String(_xtract[0].leavecategory_count_max),
          leavecategory_withoutcredit:String(_xtract[0].leavecategory_withoutcredit),
        });
      }
      setModalIsOpen(true);
    });
  }

  const handleChange = (e) => {
    this.setState((prevState) =>
      e.target.validity.valid ? { inputValue: e.target.value } : prevState
    );
  };

  const handleRadio1 = (e) => {
    let value = e.target.value;
    setUser({ leavecategory_exception_settings: value });
  };
  /* ---------------------leave category add */
  const [all, setAll] = useState([]);
  let company_id = Apiconnect.get_company_id();
  const getAllData = async () => { 
    await Apiconnect.postData(`leave/leave_category_getall/${company_id}`).then(res => {
      setAll(res.data.data)
    }); 
  };
  useEffect(() => {
    getAllData();
  }, []);


  const onInputChange_onlynumber= (e) => {
    const re_var = /^[0-9\b]+$/;
    //if (e.target.value === '' || re_var.test(e.target.value)) {
      setUser({
        ...data_lpcate,
        [e.target.name]: e.target.value,
        company_id: Apiconnect.get_company_id(),
        selcat_id: selcat_id,
      });
    //}
  };
// console.log('modalIsOpen',modalIsOpen)
  return (
    <>
      {/* =============================================================== */}
      <div>
        <Modal
          isOpen={modalIsOpen}
          style={customStyles}
          onRequestClose={() => setModalIsOpen(false)}
          scrollable={true}
        >
          <div class="row">
            <div class="col-md-12">
              <h2> Manage Category </h2>
            </div>
          </div>
          <div className="iq-card cls_scrooly">
            <div className="iq-card-body">
              <form onSubmit={(e) => onSubmit(e)}>
                <div
                  className="row"
                  style={{
                    display: "block",
                    width: "100%",
                    padding: "0px",
                    height: "50%",
                    overflow: "scrool",
                  }}
                >
                  <div className="col-sm-12 col-lg-12">
                    <div className="col-sm-12 col-lg-12" style={{border:"1px solid #a3a3a3", marginTop:10, marginBottom:10,padding:0}}>
                      <h3 style={{background:'palegoldenrod',padding:10, fontSize:20}}>Credit Settings</h3>
                      <div style={{padding:10}}>
                        <div class="row">
                          <div className="form-group col-md-8">
                            <label>Select Leave Category</label>
                            <select
                              className="form-control"
                              name="lpcategory_category_id"
                              value={lpcategory_category_id}
                              onChange={(e) => onInputChange_cate(e)}
                              required
                            >
                              <option value="" selected>
                                Leave Category
                              </option>
                              {all.map((item, key) => {
                                return (
                                  <option key={key} attr-leave_cat_code={item.leave_cat_code} value={item.leave_cat_id}>
                                    {item.leave_cat_title}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div className="form-group col-md-4">
                            <label>Leave Without Credit</label>
                            <table className="">
                              <tr>
                                <td>Yes</td> <td>&nbsp;</td>
                                <td>
                                  <input
                                    type="radio"
                                    autocomplete="off"
                                    className="form-control1 cls_radio1"
                                    name="leavecategory_withoutcredit"
                                    onChange={(e) => onInputChange(e)}
                                    value="Yes"
                                    checked={"Yes" === leavecategory_withoutcredit}
                                  />
                                </td> <td>&nbsp;</td>
                                <td>No</td> <td>&nbsp;</td>
                                <td>
                                  <input
                                    type="radio"
                                    autocomplete="off"
                                    className="form-control1 cls_radio1"
                                    name="leavecategory_withoutcredit"
                                    onChange={(e) => onInputChange(e)}
                                    value="No"
                                    checked={"No" === leavecategory_withoutcredit}
                                  />
                                </td>
                              </tr>
                            </table>
                          </div>

                          <div style={{display:'none'}}>
                            <label>Name</label>
                            <input
                              type="text"
                              autocomplete="off"
                              className="form-control"
                              name="leavecategory_name1"
                              onChange={(e) => onInputChange(e)}
                              placeholder="Name"
                              value={leavecategory_name1} 
                            />
                          </div> 
                        </div>
                        

                        <div class="row"> 
                          <div class="form-group col-md-6"  style={{"display":(leavecategory_withoutcredit=='Yes')?'none':''}}>
                            <label>Max Credit Per Cycle (Yearly)</label>
                            <span style={{ fontSize: 12 }}>
                              (Total number of leaves across all frequencies of the cycle)
                            </span>
                            <input
                              type="text"
                              autocomplete="off"
                              className="form-control"
                              name="leavecategory_maxcredit1"
                              onChange={(e) => onInputChange(e)}
                              placeholder="Max Credit Per Leave Cycle"
                              value={leavecategory_maxcredit1}
                              min="1"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label>Max Accumulation Allowed Leave : Lifetime Accumulation</label>
                            <span style={{ fontSize: 12 }}>
                              (Any number over this will automatically collapsed)
                            </span>
                            <input
                              type="text"
                              autocomplete="off"
                              className="form-control"
                              name="leavecategory_max_accumulation"
                              onChange={(e) => onInputChange(e)}
                              placeholder="Max accumulation allowed"
                              value={leavecategory_max_accumulation}
                            />
                          </div>
                        </div>

                        <div class="row"></div>

                        <div class="row">
                          <div class="form-group col-md-6"  style={{"display":(leavecategory_withoutcredit=='Yes')?'none':''}}>
                            <label>Credits frequency </label>
                            <span style={{ fontSize: 11 }}> 
                              (Credit frequency monthly or annually )
                            </span>
                            <select
                              className="form-control"
                              name="leavecategory_cyclesett"
                              value={leavecategory_cyclesett}
                              onChange={(e) => onInputChange(e)}
                              required={(leavecategory_withoutcredit=='Yes')?false:true}
                            >
                              <option value="">--Select--</option>
                              <option value="Annual">Annual</option>
                              <option value="Monthly">Monthly</option>
                            </select>
                          </div>
                          
                          <div class="form-group col-md-6" style={{"display":(leavecategory_withoutcredit=='Yes')?'none':''}}>
                            <label>Credits per frequency</label>
                            <span style={{ fontSize: 12 }}>
                              (The number of leave credits under this category per
                              frequency)
                            </span>
                            <input
                              type="text"
                              autocomplete="off"
                              className="form-control"
                              name="leavecategory_creditspercycle"
                              onChange={(e) => onInputChange_onlynumber(e)}
                              placeholder="Credits per cycle"
                              value={leavecategory_creditspercycle} 
                            />
                          </div>
                        </div>
                      
                                
                        <div class="row">
                          <div class="form-group col-md-4" style={{"display":(leavecategory_withoutcredit=='Yes')?'none':''}}>
                            <label>Pro-rated Credit  </label>
                            <table className="">
                              <tr>
                                <td>Yes</td>
                                <td>&nbsp;</td>
                                <td>
                                  <input
                                    type="radio"
                                    autocomplete="off"
                                    className="form-control1 cls_radio1"
                                    name="leavecategory_prorated"
                                    onChange={(e) => onInputChange(e)}
                                    value="Yes"
                                    checked={"Yes" === leavecategory_prorated}
                                  />
                                </td>
                                <td>&nbsp;</td>
                                <td>No</td>
                                <td>&nbsp;</td>
                                <td>
                                  <input
                                    type="radio"
                                    autocomplete="off"
                                    className="form-control1 cls_radio1"
                                    name="leavecategory_prorated"
                                    onChange={(e) => onInputChange(e)}
                                    value="No"
                                    checked={"No" === leavecategory_prorated}
                                  />
                                </td>
                              </tr>
                            </table>
                          </div>
                          
                          <div class="form-group col-md-8">
                            <label>
                              Minimum employment period to credit leave
                              (Days)
                            </label>
                            <span style={{ fontSize: 12 }}>
                              (The Minimum Service in days to be credit for this
                              leave category)
                            </span>
                            <input
                              type="text"
                              autocomplete="off"
                              className="form-control"
                              name="leavecategory_minimum_service"
                              onChange={(e) => onInputChange(e)}
                              placeholder="Number of days"
                              value={leavecategory_minimum_service}
                            />
                          </div>
                          <div class="form-group col-md-4" style={{display:'none'}}>
                            <label>
                              Exception Setting <span className="cls_red">*</span>{" "}
                            </label>
                            <span style={{ fontSize: 12 }}>
                              (Full carry forward is allowed, no lapse in first
                              year)
                            </span>
                            <select
                              className="form-control"
                              name="leavecategory_exception_settings"
                              value={leavecategory_exception_settings}
                              onChange={(e) => onInputChange(e)}
                              required
                            >
                              <option value="">--Select--</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>

                        </div>
                      </div>
                    </div>  
                    <div className="col-sm-12 col-lg-12" style={{border:"1px solid #a3a3a3", marginTop:10, marginBottom:10,padding:0}}>
                      <h3 style={{background:'palegoldenrod',padding:10, fontSize:20}}>Lapse & Carry Settings</h3>
                      <div style={{padding:10}}>
                        <div class="row">
                          <div class="form-group col-md-4">
                            <label>
                              Lapse & Carry forward Setting{" "}
                              <span className="cls_red">*</span>{" "}
                            </label>
                            <select
                              className="form-control"
                              name="leavecategory_lapse_setting"
                              value={leavecategory_lapse_setting}
                              onChange={(e) => onInputChange(e)}
                              required
                            >
                              <option value="">--Select--</option>
                              <option value="Lapse Setting">Lapse Setting</option>
                              <option value="Carry Forward Setting">
                                Carry Forward Setting
                              </option>
                            </select>
                          </div>

                          <div class="form-group col-md-4">
                            <label>Number In % or No </label>
                            <select
                              className="form-control"
                              name="leavecategory_lcf_setting"
                              value={leavecategory_lcf_setting}
                              onChange={(e) => onInputChange(e)}
                              required
                            >
                              <option value="">--Select--</option>
                              <option value="Percentage">Percentage</option>
                              <option value="Number">Number</option>
                            </select>
                          </div>
                          <div class="form-group col-md-4">
                            <label>Number Of Leaves</label>
                            <input
                              type="text"
                              autocomplete="off"
                              className="form-control"
                              name="leavecategory_setting_val"
                              onChange={(e) => onInputChange(e)}
                              placeholder="Number Of Leaves"
                              value={leavecategory_setting_val}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-12" style={{border:"1px solid #a3a3a3", marginTop:10, marginBottom:10,padding:0}}>
                      <h3 style={{background:'palegoldenrod',padding:10, fontSize:20}}>Leave Appy Page Settings</h3>
                      <div style={{padding:10}}>           
                        <div class="row">
                          <div class="form-group col-md-6">
                            <label>Minimum employment period to Apply this leave</label>
                            <span style={{ fontSize: 12 }}>
                              (Min days of service in days before applying)
                            </span>
                            <input
                              type="text"
                              autocomplete="off"
                              className="form-control"
                              name="leavecategory_applicable_eligibility"
                              onChange={(e) => onInputChange(e)}
                              placeholder="Number of days"
                              value={leavecategory_applicable_eligibility}
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label>
                              Minimum days notice befre apply for this leave
                            </label>
                            <span style={{ fontSize: 12 }}>
                              ( Minimum days notice b/w leave application and leave
                              start or effect)
                            </span>
                            <input
                              type="text"
                              autocomplete="off"
                              className="form-control"
                              name="leavecategory_minworking_days"
                              onChange={(e) => onInputChange(e)}
                              placeholder="Minimum working days"
                              value={leavecategory_minworking_days}
                              
                            />
                          </div>
                        </div>

                        <div class="row">
                          <div class="form-group col-md-6">
                            <label>Minimum number of days</label>
                            <span style={{ fontSize: 12 }}>
                              (Minimum number of days leaves can be apply in single leave application)
                            </span>
                            <input
                              type="text"
                              autocomplete="off"
                              className="form-control"
                              name="leavecategory_count_min"
                              onChange={(e) => onInputChange(e)}
                              placeholder="Min count settings"
                              value={leavecategory_count_min}
                            />
                          </div>

                          <div class="form-group col-md-6">
                            <label> Maximum number of days</label>
                            <span style={{ fontSize: 12 }}>
                              (Maximum number of days leaves can be apply in single leave application)
                            </span>
                            <input
                              type="text"
                              autocomplete="off"
                              className="form-control"
                              name="leavecategory_count_max"
                              onChange={(e) => onInputChange(e)}
                              placeholder="Max count settings"
                              value={leavecategory_count_max}
                            />
                          </div>

                          
                        </div>

                        <div class="row">
                          <div class="form-group col-md-4">
                            <label>Min Ocassions Setting</label>
                            <input
                              type="text"
                              autocomplete="off"
                              className="form-control"
                              name="leavecategory_ocassions_setting_min"
                              onChange={(e) => onInputChange(e)}
                              placeholder="Min Ocassions Setting"
                              value={leavecategory_ocassions_setting_min}
                            />
                          </div>
                          <div class="form-group col-md-4">
                            <label>Max Ocassions Setting</label>
                            <input
                              type="text"
                              autocomplete="off"
                              className="form-control"
                              name="leavecategory_ocassions_setting_max"
                              onChange={(e) => onInputChange(e)}
                              placeholder="Max Ocassions Setting"
                              value={leavecategory_ocassions_setting_max}
                            />
                          </div>
                          <div class="form-group col-md-4">
                            <label>Applicable Gender</label>
                            <table className="">
                              <tr>
                                <td>Male</td>
                                <td>&nbsp;</td>
                                <td>
                                  <input
                                    type="radio"
                                    autocomplete="off"
                                    className="form-control1 cls_radio1 m-t-10"
                                    name="leavecategory_app_gender"
                                    onChange={(e) => onInputChange(e)}
                                    value="Male"
                                    checked={"Male" === leavecategory_app_gender}
                                  />
                                </td>
                                <td>&nbsp;</td>
                                <td>Female</td>
                                <td>&nbsp;</td>
                                <td>
                                  <input
                                    type="radio"
                                    autocomplete="off"
                                    className="form-control1 cls_radio1 m-t-10"
                                    name="leavecategory_app_gender"
                                    onChange={(e) => onInputChange(e)}
                                    value="Female"
                                    checked={"Female" === leavecategory_app_gender}
                                  />
                                </td>
                                <td>&nbsp;</td>
                                <td>Both</td>
                                <td>&nbsp;</td>
                                <td>
                                  <input
                                    type="radio"
                                    autocomplete="off"
                                    className="form-control1 cls_radio1 m-t-10"
                                    name="leavecategory_app_gender"
                                    onChange={(e) => onInputChange(e)}
                                    value="Both"
                                    checked={"Both" === leavecategory_app_gender}
                                  />
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-4">
                              <label>Apply Leave Permissions</label>
                              <span style={{ fontSize: 12 }}> Only Selected Employee types can apply for this leave. </span> 
                              <select
                                className="form-control"
                                name="leavecategory_app_settings"
                                value={leavecategory_app_settings}
                                onChange={(e) => onInputChange(e)}
                                required
                              >
                                <option value="">--Select--</option>
                                <option value="No">All Employees</option>
                                <option value="Yes">Only Admin</option>
                              </select>
                            </div>        
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12" align="center">
                        <button type="submit" class="btn btn-info">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="text_center1">
              <button className="btn btn-danger pull-right" onClick={() => setModalIsOpen(false)}>close</button>
            </div>
            
          </div>
        </Modal>
      </div>
      {/* =========================END_MODAL====================================== */}

      <div id="content-page" className="content-page">
        <div className="container-fluid">
          <div className="row">
 
            <div className="col-sm-12">
              <Link to="/LeavePolicyList"><button className="btn btn-primary pull-right">Back</button></Link> 

              <button
                type="button"
                className="btn btn-success pull-right mr-3"
                onClick={() => popup_addedit1(0)}
              >
                Add
              </button>
            </div>
          </div>
          <div className="row" style={{marginTop:10}}>
            <div className="col-sm-12 col-lg-12 col-md-12">
              <div className="iq-card">
                <div className="iq-card-header d-flex justify-content-between">
                  <div style={{ float: "left" }} className="iq-header-title">
                    <h4 className="card-title">Manage Leave Category</h4>
                  </div>
                </div>
                

                <div className="row m-t-10">
                  <div className="col-sm-12 col-lg-12">
                    <div className="iq-card">
                      <div className="row">
                        <div className="col-sm-12 col-lg-12">&nbsp;</div>
                        <div className="col-sm-12 col-lg-12">
                          <div className="col-sm-12 col-lg-12">
                            <table className="table table-striped table-hover">
                              <thead className="t-c">
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Name</th> 
                                  <th scope="col">
                                    Max Credit Per Leave Cycle
                                  </th> 
                                  <th scope="col">Credits per cycle</th>
                                  <th scope="col">Leave Without Credit</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody className="">
                                {loader ? (
                                  <Loadder />
                                ) : (
                                  List_leavepolicycategory.map(
                                    (val_lgcat, key) => {
                                      return (
                                        <tr key={key}>
                                          <th className="t-c" scope="row"> {key + 1} </th>
                                          <td className="t-c"> {(val_lgcat.leave_cat_title!=null)?val_lgcat.leave_cat_title:val_lgcat.lpcategory_name}  </td>
                                          <td className="t-c">
                                            {val_lgcat.lpcategory_maxcredit} 
                                          </td>
                                          <td className="t-c">
                                            {val_lgcat.lpcategory_creditspercycle} 
                                          </td>
                                          <td className="t-c">
                                            {val_lgcat.leavecategory_withoutcredit} 
                                          </td>
                                          <td className="t-c">
                                             
                                            <button
                                              onClick={() => {
                                                leavepolicycategory_edit(
                                                  val_lgcat.lpcategory_pk_id
                                                );
                                              }}
                                              className="btn btn-warning mr-2"
                                            >
                                              <i className="fa fa-pencil" ria-hidden="true" ></i>
                                            </button>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-12">&nbsp;</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Leave_policy_manages_cat;
