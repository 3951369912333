import React, { useState, useEffect,Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import Apiconnect from "../services/Apiconnect.js";

export default class Emplyee_leave_list_table extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      list_categorys:[],
      employee_gender:'',
      sel_user_id:0,
      totalLeaveCredited:0,
      totalLeaveTaken:0,

    }; 

    
  } 
  
  getUserDetails = async (company_id,sel_user_id,is_admin,sel_shortleave_type) => { 
    var data_post={
      company_id:company_id,
      employee_id:(sel_user_id!='')?sel_user_id:0,
      is_admin:is_admin,
      sel_shortleave_type:sel_shortleave_type,
    }; 

    // console.log('== 3');
    // console.log(data_post);

    var lists_categoryss = new Promise( (resolve, reject) => { 
      //console.log('newuserId',sel_user_id);
      Apiconnect.postData(`leave_manage/get_employee_policy_category_list`,data_post).then(async (response) => {
       await this.setState({list_categorys:((typeof response.data.data!='undefined')?Apiconnect.decrypt_obj(response.data.data):0)});

        let totalLeaveCredited =0;
        let totalLeaveTaken =0;
        // console.log(this.state.list_categorys);
        if(this.state.list_categorys!=0){
          this.state.list_categorys.map((val_cat,key)=>{
            
            totalLeaveCredited = (parseFloat (totalLeaveCredited) + parseFloat(val_cat.cal_total_credit)).toFixed(2);
            totalLeaveTaken = (parseFloat (totalLeaveTaken) + parseFloat(val_cat.cal_total_apply)).toFixed(2);
          
          });
        }
        this.setState({totalLeaveCredited:totalLeaveCredited,totalLeaveTaken:totalLeaveTaken});

      });
      
      

    });

  
  }

  async componentDidMount() {
    var company_id=this.props.company_id;
    var sel_user_id=this.props.sel_emp_id;
    var sel_shortleave_type=this.props.sel_shortleave_type;
    
    let is_adm = this.props.is_adm;
    let employee_gend = this.props.employee_gender;
    this.setState({employee_gender:employee_gend});
    await this.getUserDetails(company_id,sel_user_id,is_adm,sel_shortleave_type);
    
  }
 
  async componentWillReceiveProps(props) {
    const { sel_emp_id, company_id,is_adm,employee_gender,refresh,sel_shortleave_type } = this.props;
    if (props.refresh !== refresh) {
      await this.getUserDetails(company_id,sel_emp_id,is_adm,sel_shortleave_type);
    }
  }

  
  render () {   
    
    let lists_categorys1=((this.state.list_categorys.length>0)?this.state.list_categorys:[]);
    
   
    
     
      // this.setState({totalLeaveTaken:cal_total_apply});
    

    

    function roundOff(value,round) {
      return (parseInt(value * (10 ** (round + 1))) - parseInt(value * (10 ** round)) * 10) > 4 ? (((parseFloat(parseInt((value + parseFloat(1 / (10 ** round))) * (10 ** round))))) / (10 ** round)) : (parseFloat(parseInt(value * (10 ** round))) / ( 10 ** round));
    }
    return ( 
      
      <div>


        {/*================strat_right_div==================*/}
        <div className="col-md-12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                  <h4 className="card-title">Leave summary</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="databox bg-white radius-bordered">
                    <div className="databox-left bg-themeprimary">
                      <div className="databox-piechart">
                        <i className="font40 fa fa-calendar"></i>
                      </div>
                    </div>
                    <div className="databox-right">
                      <span className="databox-number themeprimary">
                        {this.state.totalLeaveCredited}
                      </span>
                      <div className="databox-text darkgray">
                        Total Leave Credited
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="databox bg-white radius-bordered">
                    <div className="databox-left bg-themesecondary">
                      <div className="databox-piechart">
                        <i className="font40 fa fa-calendar"></i>
                      </div>
                    </div>
                    <div className="databox-right">
                      <span className="databox-number themesecondary">
                        {this.state.totalLeaveTaken}
                      </span>
                      <div className="databox-text darkgray">
                        Total Leave Taken
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row"> 
                <div
                  className="col-md-12"
                  style={{ textAlign: "center", marginBottom: "20px" }}
                >
                  <table
                    style={{ width: "100%", border: "1px solid #a3a3a3" }}
                  >
                    <thead>
                    <tr
                      style={{
                        border: "1px solid #a3a3a3",
                        backgroundColor: "#51445f",
                        color: "white",
                      }}
                    >
                      <th
                        style={{
                          border: "1px solid #a3a3a3",
                          width: "10%",
                        }}
                      >
                        #
                      </th>
                      <th
                        style={{
                          border: "1px solid #a3a3a3",
                          width: "30%",
                        }}
                      >
                        Category
                      </th>
                      
                      <th
                        style={{
                          border: "1px solid #a3a3a3",
                          width: "30%",
                        }}
                      >
                        Credited
                      </th>
                      <th
                        style={{
                          border: "1px solid #a3a3a3",
                          width: "30%",
                        }}
                      >
                        Applied
                      </th>
                      <th
                        style={{
                          border: "1px solid #a3a3a3",
                          width: "30%",
                        }}
                      >
                        Available
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    { 
                    
                    lists_categorys1.length>0
                    ?(
                      lists_categorys1.map((val_lpcat2, key_cat2) => {  
                       
                        let cal_total_apply=val_lpcat2.cal_total_apply;
                        let cal_total_credit=val_lpcat2.cal_total_credit;
                        
                        let res_cal_tot1= parseFloat(cal_total_credit) - parseFloat(cal_total_apply);
 
                        if(res_cal_tot1<0){
                          res_cal_tot1=0;
                        }

                        if(cal_total_apply<0){
                          cal_total_apply=0;
                        }

                        if(this.state.employee_gender==val_lpcat2.lpcategory_gender || val_lpcat2.lpcategory_gender=='Both'){ 
                          return (
                            <tr key={key_cat2}>
                              <td style={{ border: "1px solid #a3a3a3",padding:"5px" }}>{key_cat2+1}</td>
                              <td style={{ border: "1px solid #a3a3a3",padding:"5px" }}>
                                {val_lpcat2.leave_cat_code}
                              </td>
                              <td style={{ border: "1px solid #a3a3a3",padding:"5px",backgroundColor: "#fbd352" }}>
                                {roundOff(cal_total_credit,2)}
                              </td>
                              <td style={{ border: "1px solid #a3a3a3",padding:"5px",backgroundColor: "#fb6e52" }}>
                                {cal_total_apply}
                              </td>
                              <td style={{ border: "1px solid #a3a3a3",padding:"5px",backgroundColor: "#a0d468" }}>
                                {res_cal_tot1.toFixed(2)}  
                              </td>
                            </tr>
                          ); 
                        }

                        
                      })
                    ):
                    <tr>
                      <th colSpan={4}>No Data Found.. </th>
                    </tr>
                    }
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
        {/*================end_right_div==================*/} 
 

      </div> 
    );
  }
}
